const property = [{ name: "furnished", label: "Furnished" }];
const condominiumbool = [...property, { name: "finished", label: "Finished" }];
const exchange = [{ name: "exchange_available", label: "Exchange available" }];

export const CheckboxName = {
  "apartment-for-sell": property,
  "apartment-for-rent": property,
  "villa-for-sell": property,
  "villa-for-rent": property,
  "real-estate-for-sell": property,
  "commercial-for-sell": property,
  "commercial-for-rent": property,
  "commercial-building-for-sell": property,
  "commercial-building-for-rent": property,
  "condominium-for-sell": condominiumbool,
  "condominium-for-rent": condominiumbool,
  "service-room-for-rent": property,
  "flatmates-room-for-rent": property,
  bags: exchange,
  clothing: exchange,
  jewelry: exchange,
  shoes: exchange,
};

const residential = [
  { name: "A/c & Heating", label: "A/c & Heating" },
  { name: "Balcony", label: "Balcony" },
  { name: "Built-in Closet", label: "Built-in Closet" },
  { name: "Built-in Kitchen", label: "Built-in Kitchen" },
  { name: "Maid Room", label: "Maid Room" },
  { name: "Parking Space", label: "Parking Space" },
  { name: "Private Garden", label: "Private Garden" },
  { name: "Private Jacuzzi", label: "Private Jacuzzi" },
  { name: "Security", label: "Security" },
  { name: "Shared Spa", label: "Shared Spa" },
  { name: "Study Room", label: "Study Room" },
  { name: "View of Landmark", label: "View of Landmark" },
  { name: "Water Tank", label: "Water Tank" },
];

const apartment = [
  ...residential,
  { name: "Shared Gym", label: "Shared Gym" },
  { name: "Shared Pool", label: "Shared Pool" },
];

const villa = [
  ...residential,
  { name: "Private Gym", label: "Private Gym" },
  { name: "Private Pool", label: "Private Pool" },
];

const realestate = [
  ...apartment,
  { name: "Private Gym", label: "Private Gym" },
  { name: "Private Pool", label: "Private Pool" },
];

const condominium = [
  { name: "Balcony", label: "Balcony" },
  { name: "Built-in Closet ", label: "Built-in Closet " },
  { name: "Built-in Kitchen", label: "Built-in Kitchen" },
  { name: "Parking Space", label: "Parking Space" },
  { name: "Security", label: "Security" },
  { name: "View of Landmark", label: "View of Landmark" },
];

const commercial = [
  { name: "Conference Room", label: "Conference Room" },
  { name: "Generator", label: "Generator" },
  { name: "Lift", label: "Lift" },
  { name: "Parking Space", label: "Parking Space" },
  { name: "Restaurant in Building", label: "Restaurant in Building" },
  { name: "View of Landmark", label: "View of Landmark" },
];

const room = [
  { name: "Attached Bathroom", label: "Attached Bathroom" },
  { name: "Balcony", label: "Balcony" },
  { name: "Free Parking", label: "Free Parking" },
  { name: "Near to Main Road", label: "Near to Main Road" },
  { name: "Shared Bathroom", label: "Shared Bathroom" },
  { name: "Shared Electric", label: "Shared Electric" },
  { name: "Shared Water", label: "Shared Water" },
  { name: "Shared Wifi", label: "Shared Wifi" },
];

const serviceroom = [
  ...room,
  { name: "Pets Allowed", label: "Pets Allowed" },
  { name: "Private Garden", label: "Private Garden" },
  { name: "Security", label: "Security" },
  { name: "Shared Kitchen", label: "Shared Kitchen" },
];

const flatmates = [
  ...room,
  { name: "Cleaning Included", label: "Cleaning Included" },
  { name: "Gym", label: "Gym" },
  { name: "Washer and Dryer", label: "Washer and Dryer" },
];

const building = [
  { name: "A/c & Heating", label: "A/c & Heating" },
  { name: "Garden Area", label: "Garden Area" },
  { name: "Parking Space", label: "Parking Space" },
  { name: "Swimming Pool", label: "Swimming Pool" },
  { name: "View of Landmark", label: "View of Landmark" },
];

const buildingrent = [
  ...building,
  { name: "Conference Room", label: "Conference Room" },
  { name: "Gym", label: "Gym" },
  { name: "Security", label: "Security" },
];

export const Amenities = {
  "apartment-for-sell": apartment,
  "apartment-for-rent": apartment,
  "villa-for-sell": villa,
  "villa-for-rent": villa,
  "real-estate-for-sell": realestate,
  "commercial-for-sell": commercial,
  "commercial-for-rent": commercial,
  "commercial-building-for-sell": building,
  "commercial-building-for-rent": buildingrent,
  "condominium-for-sell": condominium,
  "condominium-for-rent": condominium,
  "service-room-for-rent": serviceroom,
  "flatmates-room-for-rent": flatmates,
};
