import { api } from "./config";
import queryString from "query-string";

export const getRecommendProduct = async (isAuth) => {
  if (!isAuth) return;
  const response = await api.get(`api/recommendation/product/`);
  return response.data;
};

export const getRecommendProductInfinite = async ({ queryKey, pageParam }) => {
  let page = 1;

  if (pageParam !== undefined) {
    // const val = queryString.parse(pageParam);
    const param = pageParam.split("?")[1];
    const val = queryString.parse(param);

    page = val["page"];
    // const values = pageParam.indexOf("page=");
    // page = pageParam.charAt(parseInt(values) + 5);
  }

  const response = await api.get(
    `api/recommendation/product/?page=${page}`
  );
  return response.data;
};
