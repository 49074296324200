import cheveronUp from "@iconify/icons-eva/arrowhead-down-outline";
import cheveronDown from "@iconify/icons-eva/arrowhead-up-outline";
import { Icon } from "@iconify/react";
import { Box, Button, MenuItem, Stack, Typography } from "@mui/material";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useGetLocationList } from "../hooks/general_hooks";
import { set_location } from "../redux/actions/location";
import CustomizedBox from "./Feedback/CustomizedBox";
import FilterConfig from "./filter_config";
import FilterOption from "./filter_options";
import FilterInput from "./Inputs/FilterInput";
import CustomizedTextField from "./TextField/TextField";

NavItem.propTypes = {
  handleChange: PropTypes.func,
};

function NavItem({ choice, handleChange, filterOption }) {
  const { t } = useTranslation();
  const label = filterOption ? filterOption[choice].label : null;

  return (
    <>
      <Box sx={{ display: "block" }}>{t(label)}</Box>
      <CustomizedTextField
        select
        key={choice}
        required
        name={choice}
        onChange={handleChange}
        size="small"
        SelectProps={{ native: true }}
      >
        <option value=""> {t("All")} </option>
        {filterOption[choice].children.map((item) => (
          <option key={item.title} value={item.title}>
            {t(item.title)}
          </option>
        ))}
      </CustomizedTextField>
    </>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

function NavSection({ url, setValues, location, set_location, ...other }) {
  const { t } = useTranslation();
  const selectedUrl = url;

  const selctedFilterOptions = useMemo(
    () => selectedFilterConfig(selectedUrl),
    [selectedUrl]
  );

  const handleChange = (event) => {
    setSelectedLocation((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));

    setValues((prevValues) => ({
      ...prevValues,

      [event.target.name]: event.target.value,
    }));

    if (event.target.name && event.target.value !== "all") {
      if (event.target.name === "location__subcity__city") {
        const zone = locationData.find(
          (item) => item.id === Number(event.target.value)
        ).subcity;

        setZoneData(zone);
      }

      if (event.target.name === "location__subcity") {
        const woreda = zoneData.find(
          (item) => item.id === Number(event.target.value)
        ).wereda;

        setWoredaData(woreda);
      }
    } else {
      if (event.target.name === "location__subcity__city") {
        setZoneData([]);
        setWoredaData([]);
      }
      if (event.target.name === "location__subcity") {
        setWoredaData([]);
      }
    }
  };

  const handleClearLocation = (event) => {
    setValues((prevValues) => ({
      ...prevValues,
      location__subcity: "",
      location__subcity__city: "",
      location: "",
    }));
    setSelectedLocation((prevValues) => ({
      ...prevValues,
      location__subcity: "all",
      location__subcity__city: "all",
      location: "all",
    }));
    setZoneData([]);
    setWoredaData([]);
  };

  const handleClearMoreFilter = (event) => {
    setValues((prevValues) => ({
      location: selectedLocation.location ? selectedLocation.location : "",
      location__subcity: selectedLocation.location__subcity
        ? selectedLocation.location__subcity
        : "",
      location__subcity__city: selectedLocation.location__subcity__city
        ? selectedLocation.location__subcity__city
        : "",
      price__gte: selectedLocation.price__gte,
      price__lte: selectedLocation.price__lte,
    }));

    setSelectedLocation((prevValues) => ({
      location: selectedLocation.location ? selectedLocation.location : "all",
      location__subcity: selectedLocation.location__subcity
        ? selectedLocation.location__subcity
        : "all",
      location__subcity__city: selectedLocation.location__subcity__city
        ? selectedLocation.location__subcity__city
        : "all",
      price__gte: selectedLocation.price__gte,
      price__lte: selectedLocation.price__lte,
    }));
  };

  const [advanced, setAdvanced] = useState(false);
  const [locationData, setLocationData] = React.useState([]);
  const [zoneData, setZoneData] = React.useState([]);
  const [woredaData, setWoredaData] = React.useState([]);

  const [selectedLocation, setSelectedLocation] = useState({
    location__subcity: "all",
    location__subcity__city: "all",
    location: "all",
    price__gte: "",
    price__lte: "",
  });

  const { data } = useGetLocationList();

  useEffect(() => {
    setSelectedLocation((selecLoc) => ({
      ...selecLoc,
      location__subcity__city: location.id ? location.id : "all",
    }));
  }, [location]);

  useEffect(() => {
    if (data) {
      setLocationData(data);
    }
  }, [data]);

  useEffect(() => {
    if (location.id !== 0) {
      if (data) {
        const zone = data.find((item) => item.id === location.id).subcity;

        setZoneData(zone);
      }
    }
  }, [location, data]);

  const handlePriceChange = useCallback(
    (event) => {
      setSelectedLocation((prevValues) => ({
        ...prevValues,
        [event.target.name]: event.target.value,
      }));

      setValues((prevValues) => ({
        ...prevValues,

        [event.target.name]: event.target.value,
      }));
    },
    [setValues]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(handlePriceChange, 200),
    [handlePriceChange]
  );

  return (
    <form>
      <Box {...other}>
        <CustomizedBox>
          <Box mx={1}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1.7,
              }}
            >
              <Typography
                sx={{
                  display: "inline",
                }}
                variant="subtitle2"
              >
                {t("Select Location")}
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                fontSize="13px"
                component="span"
                size="small"
                onClick={handleClearLocation}
              >
                {t("Clear")}
              </Typography>
            </Box>
            <Stack direction="column" spacing={1.5}>
              <CustomizedTextField
                onChange={handleChange}
                name="location__subcity__city"
                placeholder={t("Region/city")}
                label={t("Region/city")}
                value={selectedLocation.location__subcity__city}
                size="small"
                select
              >
                <MenuItem value="all">{t("All")}</MenuItem>
                {locationData &&
                  locationData.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {t(option.name)}
                    </MenuItem>
                  ))}
              </CustomizedTextField>

              <CustomizedTextField
                onChange={handleChange}
                name="location__subcity"
                placeholder="Zone"
                label={t("Zone/subcity")}
                select
                size="small"
                value={selectedLocation.location__subcity}
              >
                <MenuItem value="all">{t("All")}</MenuItem>
                {zoneData &&
                  zoneData.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {t(option.name)}
                    </MenuItem>
                  ))}
              </CustomizedTextField>

              <CustomizedTextField
                onChange={handleChange}
                name="location"
                placeholder={t("Wereda")}
                label={t("Wereda")}
                select
                value={selectedLocation.location}
                size="small"
              >
                <MenuItem value="all">{t("All")}</MenuItem>
                {woredaData &&
                  woredaData.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {t(option.name)}
                    </MenuItem>
                  ))}
              </CustomizedTextField>
            </Stack>
          </Box>
        </CustomizedBox>
        <CustomizedBox>
          <Box mx={1}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1.7,
              }}
            >
              <Typography
                sx={{
                  display: "inline",
                }}
                variant="subtitle2"
              >
                {t("Pricing range")}
              </Typography>
            </Box>
            <Box>
              <Stack direction="row" spacing={2}>
                <CustomizedTextField
                  type="number"
                  onChange={debouncedChangeHandler}
                  name="price__gte"
                  placeholder={t("Min")}
                  color="secondary"
                  size="small"
                />

                <CustomizedTextField
                  type="number"
                  onChange={debouncedChangeHandler}
                  color="secondary"
                  size="small"
                  name="price__lte"
                  placeholder={t("Max")}
                />
              </Stack>
            </Box>
          </Box>
        </CustomizedBox>

        <CustomizedBox>
          <Box mx={1}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1.7,
              }}
            >
              <Typography
                sx={{
                  display: "inline",
                }}
                variant="subtitle2"
              >
                {t("More Filters")}
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                fontSize="13px"
                component="span"
                size="small"
                onClick={handleClearMoreFilter}
              >
                {t("Clear")}
              </Typography>
            </Box>
          </Box>
          {selctedFilterOptions !== null &&
            selctedFilterOptions.basic &&
            selctedFilterOptions.basic.map((choice, index) => (
              <FilterInput
                key={`key-${index}`}
                choice={choice}
                handleChange={handleChange}
                filterOption={FilterOption}
                selected={selectedLocation}
              />
            ))}
          {selctedFilterOptions !== null &&
            selctedFilterOptions.advanced &&
            selctedFilterOptions.advanced.length > 0 && (
              <Box sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={() => setAdvanced(!advanced)}
                  endIcon={
                    <Box
                      component={Icon}
                      icon={!advanced ? cheveronUp : cheveronDown}
                      sx={{ width: 15, height: 15 }}
                    />
                  }
                  sx={{
                    bgcolor: "secondary.main",
                    marginY: "12px",
                  }}
                >
                  {t("Advanced Options")}
                </Button>
              </Box>
            )}
          {advanced &&
            selctedFilterOptions !== null &&
            selctedFilterOptions.advanced &&
            selctedFilterOptions.advanced.map((choice, index) => (
              <FilterInput
                key={`key-${index}`}
                choice={choice}
                handleChange={handleChange}
                filterOption={FilterOption}
                selected={selectedLocation}
              />
            ))}
        </CustomizedBox>
      </Box>
    </form>
  );
}

function selectedFilterConfig(slug) {
  if (slug in FilterConfig) {
    return FilterConfig[slug];
  } else return null;
}

const mapStateToProps = (state) => ({
  location: state.location.location,
});
export default connect(mapStateToProps, { set_location })(NavSection);
