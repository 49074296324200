import doneAllFill from "@iconify/icons-eva/done-all-fill";
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link as RouterLink } from "react-router-dom";
import Scrollbar from "../../Scrollbar";
import NoResultFound from "../NoResultFound";
import NotificationItem from "./NotificationItem";
import { useTranslation } from "react-i18next";

export default function CustomNotificationsList({
  notifications,
  set_notifications,
  getList,
  onMarkAsRead,
  onMarkAllAsRead,
  onRemoveNotification,
}) {
  const { t } = useTranslation();
  const { isLoading, hasNextPage, fetchNextPage, data } = getList();

  useEffect(() => {
    if (data) {
      let nf = [];
      let allnf = [];
      data.pages.forEach((element) => {
        element.results.forEach((item) => {
          if (item && item.target && item.target.target_type === "Message")
            allnf.push(item);
          else {
            nf.push(item);
            allnf.push(item);
          }
        });
      });
      set_notifications({ ...notifications, nf_list: allnf });
    }
  }, [data, set_notifications]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 1.5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">{t("Notifications")}</Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {t("unreadMessage", { count: notifications.count })}
          </Typography>
        </Box>

        {notifications.count > 0 && (
          <Tooltip title=" Mark all as read">
            <IconButton color="primary" onClick={onMarkAllAsRead}>
              <Icon icon={doneAllFill} width={20} height={20} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
        <div
          id="notifiScrollableDiv"
          style={{
            maxHeight: "70vh",
            minHeight: "50vh",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <InfiniteScroll
            dataLength={4} //This is important field to render the next data
            next={() => fetchNextPage()}
            hasMore={hasNextPage}
            loader={
              <div className="loader">
                {[1, 2, 3].map(() => (
                  <NotificationItem isLoading={true} />
                ))}
              </div>
            }
            scrollableTarget="notifiScrollableDiv"
          >
            <Divider />
            {!isLoading && notifications.nf_list.length === 0 && (
              <NoResultFound title="No new notifications!" />
            )}
            <List disablePadding sx={{ py: 1 }}>
              {isLoading
                ? [1, 2, 3, 4].map(() => <NotificationItem isLoading={true} />)
                : notifications.nf_list.map((item) => (
                    <NotificationItem
                      onMarkAsRead={onMarkAsRead}
                      onRemoveNotification={onRemoveNotification}
                      isLoading={isLoading}
                      notification={item}
                    />
                  ))}
            </List>
          </InfiniteScroll>
          <Box textAlign={"center"}>
            <Divider />
            <Button
              sx={{ margin: "auto", my: 1, display: "inline-flex" }}
              disableRipple
              component={RouterLink}
              to="notifications"
            >
              {t("View All")}
            </Button>
          </Box>
        </div>
      </Scrollbar>
    </>
  );
}
