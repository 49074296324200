import { Box, Divider, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export function Copyright({ sx }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "primary.dark",
        padding: "13px 0",
        ...sx,
      }}
    >
      <Container>
        <Stack direction="row" justifyContent="space-between">
          <Box sx={{ display: "flex" }}>
            <Typography variant="body2">
              {t("Copyright")}
              {" © "}
              <Link
                color="inherit"
                component={RouterLink}
                to="/"
                sx={{
                  "&:hover": {
                    color: (theme) => theme.palette.secondary.main,
                  },
                }}
              >
                {t("Familysooq")}
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
              {t("All Right Reserved")}&nbsp;
            </Typography>
            <Divider orientation="vertical" sx={{ bgcolor: "#fff", mx: 1 }} />{" "}
            <Typography variant="body2">
              {t("powered by")}
              <Link
                target="_blank"
                color="inherit"
                rel="noopener"
                href="https://toptech.et/"
                sx={{
                  ml: 0.5,
                  "&:hover": {
                    color: (theme) => theme.palette.secondary.main,
                  },
                }}
              >
                {t("Toptech")}
              </Link>
            </Typography>
          </Box>

          <Stack
            justifyContent="space-evenly"
            direction="row"
            sx={{ color: "#fff", fontSize: 12 }}
          >
            <Link
              target="_blank"
              rel="noopener"
              href="/terms"
              sx={{
                color: "#fff",
                "&:hover": {
                  color: (theme) => theme.palette.secondary.main,
                },
              }}
              underline="none"
            >
              {t("Terms and Conditions")}
            </Link>

            <Divider orientation="vertical" sx={{ bgcolor: "#fff", mx: 1 }} />
            <Link
              target="_blank"
              rel="noopener"
              href="/privacy_policy"
              sx={{
                color: "#fff",

                "&:hover": {
                  color: (theme) => theme.palette.secondary.main,
                },
              }}
              underline="none"
            >
              {t("Privacy Policy")}
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
