import { Box } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import Appbar from "../components/Appbar/Appbar";
import { Footer } from "../components/Footer";
import ErrorFallbackPage from "../pages/ErrorFallbackPage";

export default function NavbarLayout() {
  return (
    <>
      <Box sx={{ minHeight: "100vh" }}>
        <Appbar />
        <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
          <Outlet />
        </ErrorBoundary>
      </Box>
      <Footer />
    </>
  );
}
