import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useShowPhoneNumber } from "../../hooks/Product";
import withLoginRequired from "./withLoginRequired";

const WithAuthFavIcon = withLoginRequired(IconButton);
const WithAuthFavBtn = withLoginRequired(Button);

export default function ShowPhoneNumber({
  phoneNumber,
  id,
  button,
  txt,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const { mutate } = useShowPhoneNumber(id);
  const { t } = useTranslation();

  const handleClick = (e) => {
    setOpen(true);
    if (!open) mutate(id);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: props.fullWidth ? "100%" : "autoj",
      }}
    >
      {!button ? (
        <>
          <WithAuthFavIcon
            onClick={(e) => handleClick(e)}
            variant="contained"
            {...props}
          >
            <Box
              sx={{
                display: "flex",
                alingItems: "center",
                color: "primary.main",
              }}
            >
              <LocalPhoneIcon fontSize="medium" />
              {!open && <Typography fontSize="13px">{t("Show")}</Typography>}
            </Box>
          </WithAuthFavIcon>

          {open && <Typography fontSize="12px">{phoneNumber}</Typography>}
        </>
      ) : (
        <WithAuthFavBtn
          startIcon={<LocalPhoneIcon />}
          onClick={(e) => handleClick(e)}
          {...props}
        >
          {open ? phoneNumber : txt ? t(txt) : t("Show Phone Number")}
        </WithAuthFavBtn>
      )}
    </Box>
  );
}
