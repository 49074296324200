import { useQuery, useInfiniteQuery } from "react-query";
import { getRecommendProduct, getRecommendProductInfinite } from "../apis";

export const useProductRecommendation = (isAuth) => {
  return useQuery(
    ["recommendation_products", isAuth],
    () => getRecommendProduct(isAuth),
    { refetchOnWindowFocus: false }
  );
};

export const useRecommendProductInfinite = () => {
  return useInfiniteQuery(
    ["getLatestProductInfinte"],
    getRecommendProductInfinite,
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next === null ? undefined : lastPage.next;
      },
    }
  );
};
