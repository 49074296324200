import {
  useInfiniteQuery, useMutation,
  useQuery, useQueryClient
} from "react-query";
import { user_dashboard } from "../apis";
import {
  get_my_ads,
  get_my_ads_count, get_my_companies, get_point_balance_history,
  get_user_favourite_ad,
  get_user_general_profile,
  get_user_info,
  get_user_notification_setting,
  post_user_genral_profile,
  post_user_notification_setting
} from "../apis/profile";


export const useUserDashboard = (payload) => {
  return useQuery("user_dashboard", () => user_dashboard(payload));
};

export const useGetMyAdsCount = (searchQuery, companyId,) => {
  return useQuery(["get_my_ads_count",searchQuery, companyId] ,() => get_my_ads_count(searchQuery, companyId));
};

// export const useGetMyAds = (query) => {
//   return useInfiniteQuery(["my_ads", query], get_my_ads, {
//     getNextPageParam: (lastPage, pages) => {
//       return lastPage.next === null ? undefined : lastPage.next;
//     },
//     // enabled: false,
//     refetchOnWindowFocus: false,
//   });
// };

export const useGetMyAds = (page, searchQuery, companyId, status) => {
  return useQuery(
    ["my_ads", page, searchQuery, companyId, status],
    () => get_my_ads(page, searchQuery, companyId, status),
    {
      keepPreviousData: true,
    }
  );
};

// export const useGetPointBalanceHistory = (payload) => {
//   return useQuery(["point_balance_history", payload], () =>
//     get_point_balance_history(payload)
//   );
// };

export const useGetPointBalanceHistory = (payload) => {
  return useInfiniteQuery(
    ["point_balance_history", payload],
    get_point_balance_history,
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next === null ? undefined : lastPage.next;
      },
    }
  );
};

export const useGetUserGeneralProfile = () => {
  return useQuery(["user_general_profile"], get_user_general_profile);
};
export const useGetMyCompanies = (page) => {
  return useQuery(["my_companies", page], () => get_my_companies(page));
};

// get chat favourite ads
export const useGetFavouriteAds = (query) => {
  return useInfiniteQuery(["get_favourite_ads", query], get_user_favourite_ad, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next === null ? undefined : lastPage.next;
    },
    // enabled: false,
    refetchOnWindowFocus: false,
  });
};

export const usePostUserGeneralProfile = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => post_user_genral_profile(payload), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries("user_general_profile");
    },
  });
};

export const useGetUserNotificationSetting = (payload) => {
  return useQuery("user_general_notification_setting", () =>
    get_user_notification_setting(payload)
  );
};

export const usePostUserNotificationSetting = () => {
  const queryClient = useQueryClient();
  return useMutation((payload) => post_user_notification_setting(payload), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries("user_general_notification_setting");
    },
  });
};

export const useGetUserInfo = (payload) => {
  return useQuery("use_get_user_info", () => get_user_info(payload));
};
