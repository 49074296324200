import SearchIcon from "@mui/icons-material/Search";
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import CustomAutoComplete from "../Inputs/AutoComplete";
import useSearchBar from "./SearchBarHook";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 4px",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    width: "720px",
    minHeight: "53px",
    borderRadius: "8px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.15)",
    "&:focus": {
      border: "1px solid",
      borderColor: theme.palette.secondary.main,
    },
  },
  autocomplete: { marginLeft: theme.spacing(1) },
  iconButton: { padding: 10 },
  divider: { height: 28, margin: 4 },
  SearchBtn: {
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
      marginRight: "0px",
    },
    padding: "8px 35px",
    marginRight: "2px",
    whiteSpace: "nowrap",
    backgroundColor: "#FB8500",
    color: " #fff",
    textTransform: "none",
    borderRadius: "27px",
    "&:hover": {
      backgroundColor: "#FB8500",
      color: " #fff",
    },
  },
}));

export default function SearchBar3(props) {
  const classes = useStyles();

  const {
    adSearchQuery,
    handleCatagoryChange,
    onTextChange,
    onSearch,
    searchAutoCompleteIsLoading,
    searchAutoCompleteData,
  } = useSearchBar(props);

  useEffect(() => {
    handleCatagoryChange({
      slug: props.slug,
    });
  }, [props.slug]);

  return (
    <Container
      component="form"
      className={classes.root}
      style={{ maxWidth: 830 }}
    >
      <SearchIcon sx={{ m: 1 }} color="secondary" />
      <CustomAutoComplete
        text={adSearchQuery}
        onTextChange={onTextChange}
        onSearch={onSearch}
        isLoading={searchAutoCompleteIsLoading}
        options={searchAutoCompleteData}
        className={classes.autocomplete}
        placeholder="What are you looking for"
      />
    </Container>
  );
}
