import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { connect } from "react-redux";
import { set_Favorities } from "../../apis";
import { useGetFavorite } from "../../hooks/favorite";
import withLoginRequired from "../Feedback/withLoginRequired";

Favorite.propTypes = {
  AddButton: PropTypes.bool,
  css: PropTypes.object,
};

const SaveFav = withLoginRequired(Button);
const SaveFavIcon = withLoginRequired(IconButton);

function Favorite({ id, css, AddButton, sx, isAuthenticated }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [favorite, setUnfavorite] = useState(false);

  const mutation = useMutation(set_Favorities, {
    onSuccess: () => {
      queryClient.invalidateQueries("Favorites");
      queryClient.invalidateQueries("get_favourite_ads");
    },
  });

  const { data } = useGetFavorite(isAuthenticated);

  const handleClick = () => {
    mutation.mutate({ product: id });
  };
  useEffect(() => {
    queryClient.invalidateQueries("Favorites");
    queryClient.invalidateQueries("get_favourite_ads");
  }, [id]);

  useEffect(() => {
    if (data) {
      let check = true;
      for (let i = 0; i < data.length; i++) {
        if (data[i] === id) {
          setUnfavorite(true);
          check = false;
          break;
        }
      }

      if (check) setUnfavorite(false);
    }
  }, [data, id]);

  return (
    <>
      {AddButton ? (
        <SaveFav
          startIcon={
            <Box
              component={favorite ? FavoriteIcon : FavoriteBorder}
              sx={css}
            />
          }
          onClick={handleClick}
          fullWidth
          variant="outlined"
          color="error"
        >
          {favorite ? t("Remove") : t("Save")}
        </SaveFav>
      ) : (
        <SaveFavIcon
          onClick={handleClick}
          size="small"
          sx={{
            boxShadow: "rgba(4, 17, 29, 0.25) 0px 0px 2px 0px",
            bgcolor: "background.paper",
            border: "1px solid",
            borderColor: "divider",
            ...sx,
          }}
        >
          <Tooltip title={favorite ? t("Saved") : t("Save")} placement="right">
            <Box
              component={favorite ? FavoriteIcon : FavoriteBorder}
              sx={{ fontSize: "16px", ...css }}
            />
          </Tooltip>
        </SaveFavIcon>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Favorite);
