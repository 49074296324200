import { Box, Link, Stack, SvgIcon } from "@mui/material";
import { default as AppNavBar } from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import { alpha, styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";
import React, { memo } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ReactComponent as logoSvg } from "../../config/logo/fs1.svg";
import { LanguageSelects } from "../Button";
// import Location from "../Location/location";
import { SearchBar2, SearchBarResponsive } from "../SearchBar/";
import PostAdButton from "./PostAdButton";
import UserMenus from "./UserMenus";
import CenterContainer from "../Container/CenterContainer";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    textDecoration: "none",
    lineHeight: 0,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchButton: {
    border: 0,
    borderRadius: 113,
    display: "none",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  button: {
    fontSize: "14px",
    color: "#fff",

    margin: theme.spacing(0, 2),
    textDecoration: "none",
    transition: "all 0.3s ease",
    textTransform: "none",
    "&:hover": {
      color: " #fff",
    },
  },
}));

function AppBar({ search }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const RootStyle = styled(AppNavBar)(({ theme }) => ({
    boxShadow: "none",
    borderBottom: "1px solid",
    borderColor: theme.palette.divider,
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor: theme.palette.background.paper,
  }));

  return (
    <React.Fragment>
      <RootStyle position="static">
        <Toolbar
          sx={{
            display: "felx",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CenterContainer>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: "100%", alignItems: "center" }}
            >
              {search ? (
                <>
                  <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                    <Link
                      component={RouterLink}
                      to={"/"}
                      className={classes.title}
                    >
                      <SvgIcon
                        sx={{
                          fontSize: "98px",
                          height: "35px",
                          width: "140px",
                          color: "primary.light",
                        }}
                        component={logoSvg}
                        inheritViewBox
                      />
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "none", md: "flex", lg: "flex" },
                      maxWidth: "50%",
                      minWidth: "47%",
                    }}
                  >
                    <SearchBar2 />
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ display: { xs: "flex", md: "none" } }}>
                      <SearchBarResponsive />
                    </Box>
                    <LanguageSelects />
                    <UserMenus />
                    <PostAdButton navigate={navigate} />
                  </Box>
                </>
              ) : (
                <>
                  <Link
                    component={RouterLink}
                    to={"/"}
                    className={classes.title}
                  >
                    <SvgIcon
                      sx={{
                        fontSize: "98px",
                        height: "35px",
                        color: "primary.light",
                        width: "140px",
                      }}
                      component={logoSvg}
                      inheritViewBox
                    />
                  </Link>
                  {/* <Location /> */}
                  <div className={classes.grow} />
                  <Hidden smDown>
                    <div className={classes.grow} />
                    <LanguageSelects />
                  </Hidden>
                  <UserMenus />
                  <Hidden smDown>
                    <PostAdButton
                      navigate={navigate}
                      sx={{ boxShadow: "none" }}
                    />
                  </Hidden>
                </>
              )}
            </Stack>
          </CenterContainer>
        </Toolbar>
      </RootStyle>
    </React.Fragment>
  );
}

export default memo(AppBar);
