export const dimension = {
  width: 800,
  height: 600,
  minWidth: 600,
  minHeight: 400,
};

function getFileName() {
  let d = new Date();
  let dformat = `${d.getFullYear()}-${d.getMonth()}-${d.getDay()}-${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
  return "Familysooq_" + dformat + ".jpeg";
}

export function Compressor(file) {
  return new Promise(async (resolve, reject) => {
    let img = new Image();
    img.src = URL.createObjectURL(file);
    img.onerror = reject;
    img.onload = async (e) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = Math.min(e.target.width, dimension.width);
      canvas.height = e.target.height * (canvas.width / e.target.width);

      ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

      let quality = 1;
      const compressedImageBlob = await new Promise((resolve) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            }
          },
          "image/jpeg",
          quality
        );
      });

      let fileName = getFileName();
      const compressedImage = new File([compressedImageBlob], fileName, {
        type: "image/jpeg",
      });
      resolve(compressedImage);
    };
  });
}
