import { ReactComponent as classifieds } from "../image/icon/classifieds.svg";
import { ReactComponent as electronics } from "../image/icon/electronics.svg";
import { ReactComponent as fashion } from "../image/icon/fashion.svg";
import { ReactComponent as jobs } from "../image/icon/jobs.svg";
import { ReactComponent as property_for_rent } from "../image/icon/property_for_rent.svg";
import { ReactComponent as property_for_sell } from "../image/icon/property_for_sell.svg";
import { ReactComponent as services } from "../image/icon/services.svg";
import { ReactComponent as vehicle_for_rent } from "../image/icon/vehicle_for_rent.svg";
import { ReactComponent as vehicle_for_sell } from "../image/icon/vehicle_for_sell.svg";

const MainCategoryLists = [
  {
    id: 1,
    depth: 1,
    slug: "vehicle-for-sell",
    name: "Vehicle For Sell",
    icon: vehicle_for_sell,
  },
  {
    id: 2,
    depth: 1,
    slug: "vehicle-for-rent",
    name: "Vehicle For Rent",
    icon: vehicle_for_rent,
  },
  {
    id: 3,
    depth: 1,
    slug: "property-for-sell",
    name: "Property For Sell",
    icon: property_for_sell,
  },
  {
    id: 4,
    depth: 1,
    slug: "property-for-rent",
    name: "Property For Rent",
    icon: property_for_rent,
  },
  {
    id: 5,
    depth: 1,
    slug: "classifieds",
    name: "Classifieds",
    icon: classifieds,
  },
  {
    id: 8,
    depth: 1,
    slug: "electronics",
    name: "Electronics",
    icon: electronics,
  },
  {
    id: 9,
    depth: 1,
    slug: "fashion",
    name: "Fashion",
    icon: fashion,
  },
  {
    id: 7,
    depth: 1,
    slug: "services",
    name: "Services",
    icon: services,
  },
  {
    id: 6,
    depth: 1,
    slug: "jobs",
    name: "Jobs",
    icon: jobs,
  },
];

export default MainCategoryLists;
