import { api } from "./config";

export const Feedback = async (data) => {
  const response = await api.post("/api/profile/feedback/", data);
  return response.data;
};

export const get_feedback = async (page, review) => {
  const response = await api.get(
    `/api/profile/feedback/?page=${page}&${review}`
  );
  return response.data;
};
export const get_feedbacks_non_authenticated = async (page, id) => {
  const response = await api.get(
    `/api/profile/feedback/user/?page=${page}&id=${id}`
  );
  return response.data;
};

export const get_review = async (page, id) => {
  const response = await api.get(
    `/api/profile/feedback/?page=${page}&id=${id}`
  );
  return response.data;
};