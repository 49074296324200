import { MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomizedTextField from "../TextField/TextField";

ProductsSort.propTypes = {
  options: PropTypes.array,
  onSort: PropTypes.func,
};

export default function ProductsSort({
  options,
  onSort,
  status,
  defaultValue,
  sx,
}) {
  const { t } = useTranslation();

  return (
    <CustomizedTextField
      select
      size="small"
      color="secondary"
      defaultValue={defaultValue}
      sx={{
        width: 170,
        fontSize: "14px",
        ...sx,
      }}
      value={status}
      onChange={(e) => onSort(e.target.value)}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {t(option.label)}
        </MenuItem>
      ))}
    </CustomizedTextField>
  );
}
