import SearchIcon from "@mui/icons-material/Search";
import { Button, Container, Hidden, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Catagory } from "../Catagory";
import CustomAutoComplete from "../Inputs/AutoComplete";
import useSearchBar from "./SearchBarHook";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "13px 4px",
    display: "flex",
    alignItems: "center",
    height: "3.4rem",
    borderRadius: "8px",
    background: theme.palette.background.paper,
    boxShadow: "0px 8px 20px rgba(0,0,0,0.06);",
  },
  autocomplete: { marginLeft: theme.spacing(2) },
  iconButton: { padding: 10 },
  divider: { height: 28, margin: 4 },
  SearchBtn: {
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
      marginRight: "0px",
    },
    padding: "8px 35px",
    marginRight: "2px",
    whiteSpace: "nowrap",
    backgroundColor: "#FB8500",
    color: " #fff",
    textTransform: "none",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#FB8500",
      color: " #fff",
    },
  },
}));

export default function SearchBar1(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    adSearchQuery,
    adSearchCategory,
    handleCatagoryChange,
    categoryList,
    categoryIsLoading,
    onTextChange,
    onSearch,
    searchAutoCompleteIsLoading,
    searchAutoCompleteData,
  } = useSearchBar(props);

  return (
    <Container
      component="form"
      className={classes.root}
      style={{ maxWidth: 650 }}
    >
      <Catagory
        category={adSearchCategory}
        categoryList={categoryList}
        loading={categoryIsLoading}
        handleCatagoryChange={handleCatagoryChange}
      />
      <CustomAutoComplete
        text={adSearchQuery}
        onTextChange={onTextChange}
        onSearch={onSearch}
        isLoading={searchAutoCompleteIsLoading}
        options={searchAutoCompleteData}
        className={classes.autocomplete}
        placeholder="What are you looking for"
      />
      <Hidden smDown>
        <Button className={classes.SearchBtn} onClick={onSearch}>
          {t("Search Now")}
        </Button>
      </Hidden>
      <Hidden smUp>
        <IconButton mr={1} color="secondary" onClick={onSearch}>
          <SearchIcon />
        </IconButton>
      </Hidden>
    </Container>
  );
}
