import { compareDesc, format, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date) {
  if (date === undefined || date == null) return "";
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateMonth(date) {
  if (date === undefined || date == null) return "";
  return format(new Date(date), "MMMM dd yyyy HH:mm");
}

export function fDateTime(date) {
  if (date === undefined || date == null) return "";
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fDateTimeSuffix(date) {
  if (date === undefined || date == null) return "";
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  if (date === undefined || date == null) return "";
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fCompare(date1, date2) {
  if (date1 === undefined || date1 === null) return "";
  if (date2 === undefined || date2 === null) return "";
  return compareDesc(date1, date2);
}

export function fToTime(date) {
  if (date === undefined || date === null) return "";
  return format(new Date(date), "hh:mm a");
}

export function fToMinutes(date) {
  if (date === undefined || date === null || isNaN(date) || !isFinite(date)) return "";
  let val = date;
  let res = Math.round(val / 60);
  val = Math.round(val % 60);
  return `${String(res)}:${val < 10 ? "0" : ""}${String(val)}`;

}

export function formatMinutes(minutes) {
  return minutes < 10 ? `0${minutes}` : minutes;
}

export function formatSeconds(seconds) {
  return seconds < 10 ? `0${seconds}` : seconds;
}
