const filter_config = {
  "car-for-sell": {
    basic: [
      {
        key: "seller_type",
        name: "seller_type",
      },
      {
        key: "fuel",
        name: "fuel",
      },
      {
        key: "transmission",
        name: "transmission",
      },
      {
        key: "engine_cc",
        name: "engine_cc",
      },
      {
        key: "color",
        name: "color",
      },
      {
        key: "car_condition",
        name: "condition",
      },
      {
        key: "licence_plate",
        name: "licence_plate",
      },
      {
        key: "steer_position",
        name: "steer_position",
      },
    ],
    advanced: [
      {
        key: "turbo",
        name: "turbo",
      },
      {
        key: "regional_spec",
        name: "regional_spec",
      },
      {
        key: "body_type",
        name: "body_type",
      },
      {
        key: "cylinder",
        name: "cylinder",
      },
    ],
  },
  "motorcycle-for-sell": {
    basic: [
      {
        key: "seller_type",
        name: "seller_type",
      },
      {
        key: "motor_engine_cc",
        name: "engine_cc",
      },
      {
        key: "condition",
        name: "condition",
      },
    ],
    advanced: [],
  },
  "3-wheeler-motors-for-sell": {
    basic: [
      {
        key: "seller_type",
        name: "seller_type",
      },
      {
        key: "wheeler_engine_cc",
        name: "engine_cc",
      },
      {
        key: "condition",
        name: "condition",
      },
    ],
    advanced: [],
  },
  "heavy-vehicles-for-sell": {
    basic: [
      {
        key: "seller_type",
        name: "seller_type",
      },
      {
        key: "fuel",
        name: "fuel",
      },
      {
        key: "transmission",
        name: "transmission",
      },
      {
        key: "turbo",
        name: "turbo",
      },
      {
        key: "heavy_engine_cc",
        name: "engine_cc",
      },
      {
        key: "condition",
        name: "condition",
      },
    ],
    advanced: [
      {
        key: "heavy_cylinder",
        name: "cylinder",
      },
      {
        key: "licence_plate",
        name: "licence_plate",
      },
    ],
  },
  "car-for-rent": {
    basic: [
      {
        key: "transmission",
        name: "transmission",
      },
      {
        key: "minimum_rent_duration",
        name: "minimum_rent_duration",
      },
      {
        key: "maximum_rent_duration",
        name: "maximum_rent_duration",
      },
      {
        key: "engine_cc",
        name: "engine_cc",
      },
    ],
    advanced: [
      {
        key: "provide_driver",
        name: "provide_driver",
      },
      {
        key: "off_road_driving",
        name: "off_road_driving",
      },
    ],
  },
  "wedding-car-for-rent": {
    basic: [
      {
        key: "transmission",
        name: "transmission",
      },
      {
        key: "minimum_rent_duration",
        name: "minimum_rent_duration",
      },
      {
        key: "maximum_rent_duration",
        name: "maximum_rent_duration",
      },
      {
        key: "engine_cc",
        name: "engine_cc",
      },
    ],
    advanced: [
      {
        key: "off_road_driving",
        name: "off_road_driving",
      },
      {
        key: "provide_driver",
        name: "provide_driver",
      },
    ],
  },
  "tourist-car-for-rent": {
    basic: [
      {
        key: "transmission",
        name: "transmission",
      },
      {
        key: "minimum_rent_duration",
        name: "minimum_rent_duration",
      },
      {
        key: "maximum_rent_duration",
        name: "maximum_rent_duration",
      },
      {
        key: "engine_cc",
        name: "engine_cc",
      },
    ],
    advanced: [
      {
        key: "provide_driver",
        name: "provide_driver",
      },
      {
        key: "off_road_driving",
        name: "off_road_driving",
      },
    ],
  },
  "heavy-vehicles-for-rent": {
    basic: [
      {
        key: "transmission",
        name: "transmission",
      },
      {
        key: "minimum_rent_duration",
        name: "minimum_rent_duration",
      },
      {
        key: "maximum_rent_duration",
        name: "maximum_rent_duration",
      },
      {
        key: "heavy_engine_cc",
        name: "engine_cc",
      },
    ],
    advanced: [
      {
        key: "provide_driver",
        name: "provide_driver",
      },
    ],
  },
  "apartment-for-sell": {
    basic: [
      {
        key: "bedroom",
        name: "bedroom",
      },
      {
        key: "bathroom",
        name: "bathroom",
      },
      {
        key: "seller_type",
        name: "seller_type",
      },
      {
        key: "floor",
        name: "floor",
      },
    ],
    advanced: [],
  },
  "apartment-for-rent": {
    basic: [
      {
        key: "bedroom",
        name: "bedroom",
      },
      {
        key: "bathroom",
        name: "bathroom",
      },
      {
        key: "rent_is_paid",
        name: "rent_is_paid",
      },
      {
        key: "renter_type",
        name: "renter_type",
      },
      {
        key: "floor",
        name: "floor",
      },
    ],
    advanced: [],
  },
  "villa-for-sell": {
    basic: [
      {
        key: "bedroom",
        name: "bedroom",
      },
      {
        key: "bathroom",
        name: "bathroom",
      },
      {
        key: "seller_type",
        name: "seller_type",
      },
      {
        key: "villa_type",
        name: "villa_type",
      },
    ],
    advanced: [],
  },
  "villa-for-rent": {
    basic: [
      {
        key: "bedroom",
        name: "bedroom",
      },
      {
        key: "bathroom",
        name: "bathroom",
      },
      {
        key: "rent_is_paid",
        name: "rent_is_paid",
      },
      {
        key: "renter_type",
        name: "renter_type",
      },
      {
        key: "villa_type",
        name: "villa_type",
      },
    ],
    advanced: [],
  },
  "real-estate-for-sell": {
    basic: [
      {
        key: "bedroom",
        name: "bedroom",
      },
      {
        key: "bathroom",
        name: "bathroom",
      },
      {
        key: "seller_type",
        name: "seller_type",
      },
      {
        key: "real_estate_type",
        name: "real_estate_type",
      },
      {
        key: "floor",
        name: "floor",
      },
      {
        key: "villa_type",
        name: "villa_type",
      },
    ],
    advanced: [],
  },
  "commercial-for-sell": {
    basic: [
      {
        key: "seller_type",
        name: "seller_type",
      },
      {
        key: "floor",
        name: "floor",
      },
      {
        key: "commercial_type",
        name: "commercial_type",
      },
    ],
    advanced: [],
  },
  "commercial-for-rent": {
    basic: [
      {
        key: "floor",
        name: "floor",
      },
      {
        key: "commercial_type",
        name: "commercial_type",
      },
      {
        key: "rent_is_paid",
        name: "rent_is_paid",
      },
      {
        key: "renter_type",
        name: "renter_type",
      },
    ],
    advanced: [],
  },
  "commercial-building-for-sell": {
    basic: [
      {
        key: "seller_type",
        name: "seller_type",
      },
      {
        key: "number_of_floor",
        name: "number_of_floor",
      },
    ],
    advanced: [],
  },
  "commercial-building-for-rent": {
    basic: [
      {
        key: "number_of_floor",
        name: "number_of_floor",
      },
      {
        key: "rent_is_paid",
        name: "rent_is_paid",
      },
      {
        key: "renter_type",
        name: "renter_type",
      },
    ],
    advanced: [],
  },
  "condominium-for-sell": {
    basic: [
      {
        key: "seller_type",
        name: "seller_type",
      },
      {
        key: "condominium_bedroom",
        name: "bedroom",
      },
      {
        key: "floor",
        name: "floor",
      },
    ],
    advanced: [],
  },
  "condominium-for-rent": {
    basic: [
      {
        key: "bedroom",
        name: "bedroom",
      },
      {
        key: "floor",
        name: "floor",
      },
      {
        key: "rent_is_paid",
        name: "rent_is_paid",
      },
      {
        key: "renter_type",
        name: "renter_type",
      },
    ],
    advanced: [],
  },
  "service-room-for-rent": {
    basic: [
      {
        key: "room",
        name: "room",
      },
      {
        key: "tenants_allowed",
        name: "tenants_allowed",
      },
      {
        key: "rent_is_paid",
        name: "rent_is_paid",
      },
      {
        key: "renter_type",
        name: "renter_type",
      },
    ],
    advanced: [],
  },
  "flatmates-room-for-rent": {
    basic: [
      {
        key: "room",
        name: "room",
      },
      {
        key: "tenants_allowed",
        name: "tenants_allowed",
      },
      {
        key: "rent_is_paid",
        name: "rent_is_paid",
      },
      {
        key: "renter_type",
        name: "renter_type",
      },
      {
        key: "flatmate_type",
        name: "flatmate_type",
      },
    ],
    advanced: [
      {
        key: "room_type",
        name: "room_type",
      },
    ],
  },
  classifieds: {
    basic: [
      {
        key: "condition",
        name: "condition",
      },
      {
        key: "usage",
        name: "usage",
      },
    ],
    advanced: [],
  },
  electronics: {
    basic: [
      {
        key: "condition",
        name: "condition",
      },
      {
        key: "usage",
        name: "usage",
      },
    ],
    advanced: [],
  },
  computer: {
    basic: [
      {
        key: "computer_type",
        name: "computer_type",
      },
      {
        key: "hard_drive",
        name: "hard_drive",
      },
      {
        key: "ram",
        name: "ram",
      },
      {
        key: "condition",
        name: "condition",
      },
      {
        key: "usage",
        name: "usage",
      },
      {
        key: "processor_type",
        name: "processor_type",
      },
      {
        key: "color",
        name: "color",
      },
    ],
    advanced: [
      {
        key: "display_size",
        name: "display_size",
      },
      {
        key: "storage_type",
        name: "storage_type",
      },
      {
        key: "processor_speed",
        name: "processor_speed",
      },
    ],
  },
  "mobile-phone": {
    basic: [
      {
        key: "memory",
        name: "storage",
      },
      {
        key: "mobile_ram",
        name: "ram",
      },
      {
        key: "color",
        name: "color",
      },
      {
        key: "condition",
        name: "condition",
      },
      {
        key: "usage",
        name: "usage",
      },
    ],
    advanced: [],
  },
  tablet: {
    basic: [
      {
        key: "memory",
        name: "storage",
      },
      {
        key: "mobile_ram",
        name: "ram",
      },
      {
        key: "color",
        name: "color",
      },
      {
        key: "condition",
        name: "condition",
      },
      {
        key: "usage",
        name: "usage",
      },
    ],
    advanced: [],
  },
  television: {
    basic: [
      {
        key: "television_brand",
        name: "brand",
      },
      {
        key: "screen_size",
        name: "screen_size",
      },
      {
        key: "condition",
        name: "condition",
      },
      {
        key: "usage",
        name: "usage",
      },
    ],
    advanced: [],
  },
  refrigerator: {
    basic: [
      {
        key: "refrigerator_and_washing_machine_brand",
        name: "brand",
      },
      {
        key: "condition",
        name: "condition",
      },
      {
        key: "usage",
        name: "usage",
      },
    ],
    advanced: [],
  },
  "washing-machine": {
    basic: [
      {
        key: "refrigerator_and_washing_machine_brand",
        name: "brand",
      },
      {
        key: "condition",
        name: "condition",
      },
      {
        key: "usage",
        name: "usage",
      },
    ],
    advanced: [],
  },
  job: {
    basic: [
      {
        key: "job_gender",
        name: "gender",
      },
      {
        key: "employment_type",
        name: "employment_type",
      },
      {
        key: "career_level",
        name: "career_level",
      },
      {
        key: "experience",
        name: "experience",
      },
      {
        key: "education",
        name: "education",
      },
    ],
    advanced: [],
  },
  employee: {
    basic: [
      {
        key: "gender",
        name: "gender",
      },
      {
        key: "employment_type",
        name: "employment_type",
      },
      {
        key: "career_level",
        name: "career_level",
      },
      {
        key: "experience",
        name: "experience",
      },
      {
        key: "education",
        name: "education",
      },
    ],
    advanced: [],
  },
  services: {
    basic: [],
    advanced: [],
  },
  fashion: {
    basic: [
      {
        key: "fashion_condition",
        name: "condition",
      },
      {
        key: "fashion_gender",
        name: "gender",
      },
    ],
    advanced: [],
  },
  bags: {
    basic: [
      {
        key: "fashion_condition",
        name: "condition",
      },
      {
        key: "fashion_gender",
        name: "gender",
      },
      {
        key: "bag_brand",
        name: "brand",
      },
      {
        key: "fashion_color",
        name: "color",
      },
      {
        key: "exchange_available",
        name: "exchange_available",
      },
    ],
    advanced: [],
  },
  clothing: {
    basic: [
      {
        key: "fashion_condition",
        name: "condition",
      },
      {
        key: "fashion_gender",
        name: "gender",
      },
      {
        key: "clothe_brand",
        name: "brand",
      },
      {
        key: "occasion",
        name: "occasion",
      },
      {
        key: "fashion_color",
        name: "color",
      },
      {
        key: "clothe_size",
        name: "size",
      },
      {
        key: "exchange_available",
        name: "exchange_available",
      },
    ],
    advanced: [],
  },
  jewelry: {
    basic: [
      {
        key: "fashion_condition",
        name: "condition",
      },
      {
        key: "fashion_gender",
        name: "gender",
      },
      {
        key: "stone",
        name: "stone",
      },
      {
        key: "exchange_available",
        name: "exchange_available",
      },
    ],
    advanced: [],
  },
  watches: {
    basic: [
      {
        key: "fashion_condition",
        name: "condition",
      },
      {
        key: "fashion_gender",
        name: "gender",
      },
      {
        key: "watche_brand",
        name: "brand",
      },
      {
        key: "dial_display",
        name: "dial_display",
      },
      {
        key: "movement_type",
        name: "movement_type",
      },
    ],
    advanced: [],
  },
  shoes: {
    basic: [
      {
        key: "fashion_condition",
        name: "condition",
      },
      {
        key: "fashion_gender",
        name: "gender",
      },
      {
        key: "shoes_brand",
        name: "brand",
      },
      {
        key: "occasion",
        name: "occasion",
      },
      {
        key: "fashion_color",
        name: "color",
      },
      {
        key: "shoes_size",
        name: "size",
      },
      {
        key: "exchange_available",
        name: "exchange_available",
      },
    ],
    advanced: [],
  },
};
export default filter_config;
