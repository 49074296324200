import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import AdCard from "../../components/Feedback/AdCard";
import ProductLongCardList from "../../components/products1/ProductLongCardList";
import noresult from "../../config/noData.svg";

export default function SearchResults({
  data,
  isLoading,
  hasNextPage,
  fetchNextPage,
  noResult,
  filter_category,
  isSuccess,
  dataLength,
}) {
  const { t } = useTranslation();

  return (
    <>
      {noResult && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box width={150}>
            <img
              src={noresult}
              alt=""
              width="100%"
              height="100%"
              fill="red"
              style={{ color: "red" }}
            />
          </Box>
          <Typography variant="h4" color="text.secondary">
            {t("Sorry! no matching result found!")}
          </Typography>
        </Box>
      )}
      <InfiniteScroll
        pageStart={0}
        next={() => fetchNextPage()}
        hasMore={hasNextPage}
        loader={[1, 2, 3, 4].map((ind) => (
          <div className="loader" key={"ad-loader-" + ind}>
            <AdCard isLoading={true} />
          </div>
        ))}
        dataLength={dataLength}
      >
        {data &&
          data.pages.map((page, index) => (
            <Box key={"ad-s-" + index}>
              <ProductLongCardList
                isLoading={isLoading}
                isSuccess={isSuccess}
                products={page}
                filter_category={filter_category}
              />
            </Box>
          ))}
      </InfiniteScroll>
      {isLoading
        ? [1, 2, 3].map((item, index) => (
            <div style={{ width: "100%", overflow: "visible" }} key={index}>
              <AdCard isLoading={isLoading} />
            </div>
          ))
        : null}
    </>
  );
}
