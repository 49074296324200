import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import MoreHorizoIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;

export default function ListItemMore({
  id,
  onMarkAsRead,
  onRemoveNotification,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event && event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ zIndex: 1000 }}>
      <IconButton
        edge="end"
        aria-label="more"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className="notification-item"
        color="primary"
        sx={{
          margin: 0,
          padding: 0,
          color: "transparent",
          "&:hover": { backgroundColor: "Background.default" },
        }}
      >
        <MoreHorizoIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{ "aria-labelledby": "long-button" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { maxHeight: ITEM_HEIGHT * 4.5 },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            onMarkAsRead(id);
          }}
        >
          <ListItemIcon>
            <CheckIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" fontSize="small">
            {t("Mark as read")}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onRemoveNotification(id);
          }}
        >
          <ListItemIcon>
            <ClearIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" fontSize="small">
            {t("Remove this notification")}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
