import queryString from "query-string";
import { api } from "./config";
export const RelatedProduct = async ( productId) => {
  const response = await api.get(
    `api/main/product/similar/list/?product=${productId}`
  );
  return response.data;
};

export const getSimilarProductInfinite = async ({ queryKey, pageParam }) => {
  let page = 1;

  if (pageParam !== undefined) {
    // const val = queryString.parse(pageParam);
    const param = pageParam.split("?")[1];
    const val = queryString.parse(param);

    page = val["page"];
    // const values = pageParam.indexOf("page=");
    // page = pageParam.charAt(parseInt(values) + 5);
  }

  const response = await api.get(
    `api/main/product/similar/list/?product=${queryKey[1]}&page=${page}`
  );
  return response.data;
};
