export const Options = {
  condition: [
    { label: "New", value: "New" },
    { label: "Used", value: "Used" },
  ],
  seller_type: [
    { label: "Broker", value: "Broker" },
    { label: "Owner", value: "Owner" },
  ],
  provide_driver: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
    { label: "Optional", value: "Optional" },
  ],
  turbo: [
    { label: "Available", value: "Available" },
    { label: "Not Available", value: "Not Available" },
  ],
  off_road_driving: [
    { label: "Allowed", value: "Allowed" },
    { label: "Not Allowed", value: "Not Allowed" },
  ],
  licence_plate: [
    { label: "Registered", value: "Registered" },
    { label: "Not Registered", value: "Not Registered" },
  ],
  car_condition: [
    { label: "New (0-4000 KM)", value: "New (0-4000 KM)" },
    { label: "Used", value: "Used" },
  ],
  cylinder: [
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "8", value: "8" },
    { label: "10", value: "10" },
    { label: "12", value: "12" },
  ],
  steer_position: [
    { label: "Original left", value: "Original left" },
    { label: "Original right", value: "Original right" },
  ],
  price_per: [
    { label: "hour", value: "hour" },
    { label: "day", value: "day" },
    { label: "week", value: "week" },
    { label: "month", value: "month" },
    { label: "year", value: "year" },
  ],
  fuel: [
    { label: "Benzene", value: "Benzene" },
    { label: "Diesel", value: "Diesel" },
    { label: "Electric", value: "Electric" },
    { label: "Hybrid", value: "Hybrid" },
  ],
  transmission: [
    { label: "Automatic", value: "Automatic" },
    { label: "Manual", value: "Manual" },
    { label: "Semi Auto", value: "Semi Auto" },
  ],

  heavy_cylinder: [
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "8", value: "8" },
    { label: "10", value: "10" },
    { label: "12", value: "12" },
  ],
  color: [
    { label: "Beige", value: "Beige", color: "#F5F5DC" },
    { label: "Black", value: "Black", color: "#000000" },
    { label: "Blue", value: "Blue", color: "#0000FF" },
    { label: "Blue Black", value: "Blue Black", color: "#00157F" },
    { label: "Bronze", value: "Bronze", color: "#CD7F32" },
    { label: "Brown", value: "Brown", color: "#a5682a" },
    { label: "Burgundy", value: "Burgundy", color: "#8c0052" },
    { label: "Dark Gray", value: "Dark Gray", color: "#A9A9A9" },
    { label: "Gold", value: "Gold", color: "#A88723" },
    { label: "Gray", value: "Gray", color: "#808080" },
    { label: "Green", value: "Green", color: "#329932" },
    { label: "Light Brown", value: "Light Brown", color: "#C4A484" },
    { label: "Light Gray", value: "Light Gray", color: "#D3D3D3" },
    { label: "Mustard", value: "Mustard", color: "#ffdb58" },
    { label: "Navy Blue", value: "Navy Blue", color: "#000080" },
    { label: "Orange", value: "Orange", color: "#FFA500" },
    { label: "Peach", value: "Peach", color: "#FFE5B4" },
    { label: "Pink", value: "Pink", color: "#FFC0CB" },
    { label: "Purple", value: "Purple", color: "#800080" },
    { label: "Red", value: "Red", color: "#FF0000" },
    { label: "Silver", value: "Silver", color: "#C0C0C0" },
    { label: "Teal", value: "Teal", color: "#008080" },
    { label: "Violet", value: "Violet", color: "#8F00FF" },
    { label: "White", value: "White", color: "#FFFFFF" },
    { label: "Yellow", value: "Yellow", color: "#FFFF00" },
    { label: "Other", value: "Other", color: "Other" },
  ],
  regional_spec: [
    { label: "American Specs", value: "American Specs" },
    { label: "European Specs", value: "European Specs" },
    { label: "GCC Specs", value: "GCC Specs" },
    { label: "Japanese Specs", value: "Japanese Specs" },
    { label: "Other", value: "Other" },
  ],
  usage: [
    { label: "0-1 month", value: "0-1 month" },
    { label: "1-6 months", value: "1-6 months" },
    { label: "6-12 months", value: "6-12 months" },
    { label: "1-2 years", value: "1-2 years" },
    { label: "2-5 years", value: "2-5 years" },
    { label: "5-10 years", value: "5-10 years" },
    { label: "10+ years", value: "10+ years" },
  ],
  body_type: [
    { label: "Coupe", value: "Coupe" },
    { label: "Crossover", value: "Crossover" },
    { label: "Hard Top Convertaible", value: "Hard Top Convertaible" },
    { label: "Hatchback", value: "Hatchback" },
    { label: "Pick Up Truck", value: "Pick Up Truck" },
    { label: "Sedan", value: "Sedan" },
    { label: "Soft Top Convirtible", value: "Soft Top Convirtible" },
    { label: "Sports Car", value: "Sports Car" },
    { label: "Suv", value: "Suv" },
    { label: "Utility Truck", value: "Utility Truck" },
    { label: "Van", value: "Van" },
    { label: "Wagon", value: "Wagon" },
    { label: "Other", value: "Other" },
  ],
  minimum_rent_duration: [
    { label: "1 day", value: "1 day" },
    { label: "2 days", value: "2 days" },
    { label: "3 days", value: "3 days" },
    { label: "4 days", value: "4 days" },
    { label: "5 days", value: "5 days" },
    { label: "6 days", value: "6 days" },
    { label: "1 week", value: "1 week" },
    { label: "2 weeks", value: "2 weeks" },
    { label: "3 weeks", value: "3 weeks" },
    { label: "1 month", value: "1 month" },
    { label: "3 months", value: "3 months" },
    { label: "6 months", value: "6 months" },
    { label: "1 year", value: "1 year" },
  ],
  maximum_rent_duration: [
    { label: "1 week", value: "1 week" },
    { label: "2 weeks", value: "2 weeks" },
    { label: "3 weeks", value: "3 weeks" },
    { label: "1 month", value: "1 month" },
    { label: "3 months", value: "3 months" },
    { label: "6 months", value: "6 months" },
    { label: "9 months", value: "9 months" },
    { label: "1 year", value: "1 year" },
    { label: "2 years", value: "2 years" },
    { label: "5 years", value: "5 years" },
  ],
  engine_cc: [
    { label: "0.6L", value: "0.6L" },
    { label: "0.8L", value: "0.8L" },
    { label: "1.0L", value: "1.0L" },
    { label: "1.1L", value: "1.1L" },
    { label: "1.2L", value: "1.2L" },
    { label: "1.3L", value: "1.3L" },
    { label: "1.4L", value: "1.4L" },
    { label: "1.5L", value: "1.5L" },
    { label: "1.6L", value: "1.6L" },
    { label: "1.7L", value: "1.7L" },
    { label: "1.8L", value: "1.8L" },
    { label: "2.0L", value: "2.0L" },
    { label: "2.2L", value: "2.2L" },
    { label: "2.3L", value: "2.3L" },
    { label: "2.5L", value: "2.5L" },
    { label: "2.6L", value: "2.6L" },
    { label: "2.8L", value: "2.8L" },
    { label: "3.0L", value: "3.0L" },
    { label: "3.2L", value: "3.2L" },
    { label: "3.3L", value: "3.3L" },
    { label: "3.5L", value: "3.5L" },
    { label: "3.7L", value: "3.7L" },
    { label: "3.8L", value: "3.8L" },
    { label: "3.9L", value: "3.9L" },
    { label: "4.1L", value: "4.1L" },
    { label: "4.2L", value: "4.2L" },
    { label: "4.3L", value: "4.3L" },
    { label: "4.4L", value: "4.4L" },
    { label: "4.8L", value: "4.8L" },
    { label: "4.9L", value: "4.9L" },
    { label: "5.0L", value: "5.0L" },
    { label: "5.2L", value: "5.2L" },
    { label: "5.7L", value: "5.7L" },
    { label: "5.8L", value: "5.8L" },
    { label: "5.9L", value: "5.9L" },
    { label: "6.0L", value: "6.0L" },
    { label: "6.2L", value: "6.2L" },
    { label: "6.6L", value: "6.6L" },
    { label: "6.9L", value: "6.9L" },
    { label: "7.0L", value: "7.0L" },
    { label: "7.4L", value: "7.4L" },
    { label: "7.5L", value: "7.5L" },
    { label: "7.6L", value: "7.6L" },
    { label: "7.8L", value: "7.8L" },
    { label: "8.1L", value: "8.1L" },
    { label: "8.2L", value: "8.2L" },
    { label: "8.3L", value: "8.3L" },
    { label: "8.6L", value: "8.6L" },
    { label: "9.0L", value: "9.0L" },
    { label: "9.1L", value: "9.1L" },
    { label: "9.3L", value: "9.3L" },
    { label: "10.0L", value: "10.0L" },
    { label: "10.4L", value: "10.4L" },
    { label: "10.5L", value: "10.5L" },
    { label: "11.0L", value: "11.0L" },
    { label: "11.6L", value: "11.6L" },
    { label: "12.0L", value: "12.0L" },
    { label: "12.2L", value: "12.2L" },
    { label: "13.9L", value: "13.9L" },
    { label: "14.0L", value: "14.0L" },
    { label: "14.2L", value: "14.2L" },
    { label: "14.6L", value: "14.6L" },
    { label: "14.8L", value: "14.8L" },
    { label: "15.2L", value: "15.2L" },
    { label: "18.0L", value: "18.0L" },
    { label: "18.8L", value: "18.8L" },
    { label: "19.0L+", value: "19.0L+" },
  ],
  heavy_engine_cc: [
    { label: "4.1L", value: "4.1L" },
    { label: "4.2L", value: "4.2L" },
    { label: "4.3L", value: "4.3L" },
    { label: "4.4L", value: "4.4L" },
    { label: "4.8L", value: "4.8L" },
    { label: "4.9L", value: "4.9L" },
    { label: "5.0L", value: "5.0L" },
    { label: "5.2L", value: "5.2L" },
    { label: "5.7L", value: "5.7L" },
    { label: "5.8L", value: "5.8L" },
    { label: "5.9L", value: "5.9L" },
    { label: "6.0L", value: "6.0L" },
    { label: "6.2L", value: "6.2L" },
    { label: "6.6L", value: "6.6L" },
    { label: "6.9L", value: "6.9L" },
    { label: "7.0L", value: "7.0L" },
    { label: "7.4L", value: "7.4L" },
    { label: "7.5L", value: "7.5L" },
    { label: "7.6L", value: "7.6L" },
    { label: "7.8L", value: "7.8L" },
    { label: "8.1L", value: "8.1L" },
    { label: "8.2L", value: "8.2L" },
    { label: "8.3L", value: "8.3L" },
    { label: "8.6L", value: "8.6L" },
    { label: "9.0L", value: "9.0L" },
    { label: "9.1L", value: "9.1L" },
    { label: "9.3L", value: "9.3L" },
    { label: "10.0L", value: "10.0L" },
    { label: "10.4L", value: "10.4L" },
    { label: "10.5L", value: "10.5L" },
    { label: "11.0L", value: "11.0L" },
    { label: "11.6L", value: "11.6L" },
    { label: "12.0L", value: "12.0L" },
    { label: "12.2L", value: "12.2L" },
    { label: "13.9L", value: "13.9L" },
    { label: "14.0L", value: "14.0L" },
    { label: "14.2L", value: "14.2L" },
    { label: "14.6L", value: "14.6L" },
    { label: "14.8L", value: "14.8L" },
    { label: "15.2L", value: "15.2L" },
    { label: "18.0L", value: "18.0L" },
    { label: "18.8L", value: "18.8L" },
    { label: "19.0L+", value: "19.0L+" },
  ],
  motor_engine_cc: [
    { label: "10-20L", value: "10-20L" },
    { label: "21-30L", value: "21-30L" },
    { label: "31-40L", value: "31-40L" },
    { label: "41-50L", value: "41-50L" },
    { label: "51-60L", value: "51-60L" },
    { label: "61-70L", value: "61-70L" },
    { label: "71-80L", value: "71-80L" },
    { label: "81-90L", value: "81-90L" },
    { label: "91-100L", value: "91-100L" },
    { label: "101-110L", value: "101-110L" },
    { label: "111-120L", value: "111-120L" },
    { label: "121-130L", value: "121-130L" },
    { label: "131-140L", value: "131-140L" },
    { label: "141-150L", value: "141-150L" },
    { label: "151-160L", value: "151-160L" },
    { label: "161-170L", value: "161-170L" },
    { label: "171-180L", value: "171-180L" },
    { label: "181-190L", value: "181-190L" },
    { label: "191-200L", value: "191-200L" },
    { label: "201-210L", value: "201-210L" },
    { label: "211-220L", value: "211-220L" },
    { label: "221-230L", value: "221-230L" },
    { label: "231-240L", value: "231-240L" },
    { label: "241-250L", value: "241-250L" },
    { label: "251-260L", value: "251-260L" },
    { label: "261-270L", value: "261-270L" },
    { label: "271-280L", value: "271-280L" },
    { label: "281-290L", value: "281-290L" },
    { label: "291-300L", value: "291-300L" },
    { label: "300L+", value: "300L+" },
  ],
  wheeler_engine_cc: [
    { label: "180-200L", value: "180-200L" },
    { label: "201-220L", value: "201-220L" },
    { label: "221-240L", value: "221-240L" },
    { label: "241-260L", value: "241-260L" },
    { label: "261-280L", value: "261-280L" },
    { label: "281-300L", value: "281-300L" },
    { label: "301-320L", value: "301-320L" },
    { label: "321-340L", value: "321-340L" },
    { label: "341-360L", value: "341-360L" },
    { label: "361-380L", value: "361-380L" },
    { label: "380-400L", value: "380-400L" },
    { label: "401-420L", value: "401-420L" },
    { label: "421-440L", value: "421-440L" },
    { label: "441-460L", value: "441-460L" },
    { label: "461-480L", value: "461-480L" },
    { label: "481-500L", value: "481-500L" },
    { label: "500L+", value: "500L+" },
  ],

  real_estate_type: [
    { label: "Apartment", value: "Apartment" },
    { label: "Villa", value: "Villa" },
  ],
  price_per_for_property: [{ label: "Square meter", value: "Square meter" }],
  flatmate_type: [
    { label: "Apartment", value: "Apartment" },
    { label: "Condominium", value: "Condominium" },
    { label: "Villa", value: "Villa" },
  ],
  villa_type: [
    { label: "Ground", value: "Ground" },
    { label: "G+1", value: "G+1" },
    { label: "G+2", value: "G+2" },
    { label: "G+3", value: "G+3" },
    { label: "G+4", value: "G+4" },
    { label: "G+5", value: "G+5" },
  ],
  room: [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10+", value: "10+" },
  ],
  room_type: [
    { label: "Bed space", value: "Bed space" },
    { label: "Private room", value: "Private room" },
    { label: "Room with partition", value: "Room with partition" },
  ],
  rent_is_paid: [
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Half Yearly", value: "Half Yearly" },
    { label: "Annually", value: "Annually" },
  ],
  bedroom: [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10+", value: "10+" },
  ],
  condominium_bedroom: [
    { label: "studio", value: "studio" },
    { label: "1 bedroom", value: "1 bedroom" },
    { label: "2 bedrooms", value: "2 bedrooms" },
    { label: "3 bedrooms", value: "3 bedrooms" },
  ],
  bathroom: [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10+", value: "10+" },
  ],
  tenants_allowed: [
    { label: "Males", value: "Males" },
    { label: "Females", value: "Females" },
    { label: "Couples", value: "Couples" },
    { label: "Families", value: "Families" },
    { label: "Don't mind", value: "Don't mind" },
  ],
  commercial_type: [
    { label: "Commercial Villa", value: "Commercial Villa" },
    { label: "Factory", value: "Factory" },
    { label: "Office", value: "Office" },
    { label: "Shop", value: "Shop" },
    { label: "Warehouse", value: "Warehouse" },
  ],
  number_of_floor: [
    { label: "G+1", value: "G+1" },
    { label: "G+2", value: "G+2" },
    { label: "G+3", value: "G+3" },
    { label: "G+4", value: "G+4" },
    { label: "G+5", value: "G+5" },
    { label: "G+6", value: "G+6" },
    { label: "G+7", value: "G+7" },
    { label: "G+8", value: "G+8" },
    { label: "G+9", value: "G+9" },
    { label: "G+10", value: "G+10" },
    { label: "G+11", value: "G+11" },
    { label: "G+12", value: "G+12" },
    { label: "G+13", value: "G+13" },
    { label: "G+14", value: "G+14" },
    { label: "G+15", value: "G+15" },
    { label: "G+16", value: "G+16" },
    { label: "G+17", value: "G+17" },
    { label: "G+18", value: "G+18" },
    { label: "G+19", value: "G+19" },
    { label: "G+20", value: "G+20" },
    { label: "G+20+", value: "G+20+" },
  ],
  floor: [
    { label: "Ground", value: "Ground" },
    { label: "1st", value: "1st" },
    { label: "2nd", value: "2nd" },
    { label: "3rd", value: "3rd" },
    { label: "4th", value: "4th" },
    { label: "5th", value: "5th" },
    { label: "6th", value: "6th" },
    { label: "7th", value: "7th" },
    { label: "8th", value: "8th" },
    { label: "9th", value: "9th" },
    { label: "10th", value: "10th" },
    { label: "11th", value: "11th" },
    { label: "12th", value: "12th" },
    { label: "13th", value: "13th" },
    { label: "14th", value: "14th" },
    { label: "15th", value: "15th" },
    { label: "16th", value: "16th" },
    { label: "17th", value: "17th" },
    { label: "18th", value: "18th" },
    { label: "19th", value: "19th" },
    { label: "20th", value: "20th" },
    { label: "20+", value: "20+" },
  ],

  storage_type: [
    { label: "HDD", value: "HDD" },
    { label: "SSD", value: "SSD" },
    { label: "SSHD (Hybrid)", value: "SSHD (Hybrid)" },
  ],
  computer_type: [
    { label: "Desktop Computer", value: "Desktop Computer" },
    { label: "Laptop Computer", value: "Laptop Computer" },
    { label: "Notebook", value: "Notebook" },
    { label: "Server", value: "Server" },
    { label: "Tablet", value: "Tablet" },
  ],
  processor_speed: [
    { label: "Less than 500 MHz", value: "Less than 500 MHz" },
    { label: "500 MHz - 999 MHz", value: "500 MHz - 999 MHz" },
    { label: "1 GHz - 1.49 GHz", value: "1 GHz - 1.49 GHz" },
    { label: "1.5 GHz - 1.99 GHz", value: "1.5 GHz - 1.99 GHz" },
    { label: "2 GHz - 2.49 GHz", value: "2 GHz - 2.49 GHz" },
    { label: "2.5 GHz - 2.99 GHz", value: "2.5 GHz - 2.99 GHz" },
    { label: "3 GHz - 4 GHz", value: "3 GHz - 4 GHz" },
    { label: "4+ GHz", value: "4+ GHz" },
  ],
  ram: [
    { label: "512MB", value: "512MB" },
    { label: "1GB", value: "1GB" },
    { label: "2GB", value: "2GB" },
    { label: "4GB", value: "4GB" },
    { label: "6GB", value: "6GB" },
    { label: "8GB", value: "8GB" },
    { label: "12GB", value: "12GB" },
    { label: "16GB", value: "16GB" },
    { label: "24GB", value: "24GB" },
    { label: "32GB", value: "32GB" },
    { label: "Other", value: "Other" },
  ],
  hard_drive: [
    { label: "16GB", value: "16GB" },
    { label: "32GB", value: "32GB" },
    { label: "64GB", value: "64GB" },
    { label: "128GB", value: "128GB" },
    { label: "256GB", value: "256GB" },
    { label: "380GB", value: "380GB" },
    { label: "500GB", value: "500GB" },
    { label: "512GB", value: "512GB" },
    { label: "750GB", value: "750GB" },
    { label: "1TB", value: "1TB" },
    { label: "2TB", value: "2TB" },
    { label: "3TB", value: "3TB" },
    { label: "Other", value: "Other" },
  ],
  mobile_ram: [
    { label: "256MB", value: "256MB" },
    { label: "500MB", value: "500MB" },
    { label: "1GB", value: "1GB" },
    { label: "1.5GB", value: "1.5GB" },
    { label: "2GB", value: "2GB" },
    { label: "3GB", value: "3GB" },
    { label: "4GB", value: "4GB" },
    { label: "6GB", value: "6GB" },
    { label: "8GB", value: "8GB" },
    { label: "12GB", value: "12GB" },
    { label: "Other", value: "Other" },
  ],
  memory: [
    { label: "2GB", value: "2GB" },
    { label: "4GB", value: "4GB" },
    { label: "6GB", value: "6GB" },
    { label: "8GB", value: "8GB" },
    { label: "16GB", value: "16GB" },
    { label: "32GB", value: "32GB" },
    { label: "64GB", value: "64GB" },
    { label: "128GB", value: "128GB" },
    { label: "256GB", value: "256GB" },
    { label: "512GB", value: "512GB" },
    { label: "Other", value: "Other" },
  ],
  processor_type: [
    { label: "AMD", value: "AMD" },
    { label: "AMD A4", value: "AMD A4" },
    { label: "AMD A6", value: "AMD A6" },
    { label: "AMD A8", value: "AMD A8" },
    { label: "AMD A10", value: "AMD A10" },
    { label: "AMD Ryzen", value: "AMD Ryzen" },
    { label: "Apple M1", value: "Apple M1" },
    { label: "Intel", value: "Intel" },
    { label: "Intel Atom", value: "Intel Atom" },
    { label: "Intel Celeron", value: "Intel Celeron" },
    { label: "Intel Core 2 Duo", value: "Intel Core 2 Duo" },
    { label: "Intel Core 2 Quad", value: "Intel Core 2 Quad" },
    { label: "Intel Core i3", value: "Intel Core i3" },
    { label: "Intel Core i5", value: "Intel Core i5" },
    { label: "Intel Core i7", value: "Intel Core i7" },
    { label: "Intel Core i9", value: "Intel Core i9" },
    { label: "Intel Core M", value: "Intel Core M" },
    { label: "Intel Pentium", value: "Intel Pentium" },
    { label: "Intel Xeon", value: "Intel Xeon" },
    { label: "Nvidia", value: "Nvidia" },
  ],
  display_size: [
    { label: '10.1"', value: '10.1"' },
    { label: '11"', value: '11"' },
    { label: '11.6"', value: '11.6"' },
    { label: '12"', value: '12"' },
    { label: '12.3"', value: '12.3"' },
    { label: '13"', value: '13"' },
    { label: '13.3"', value: '13.3"' },
    { label: '14"', value: '14"' },
    { label: '15"', value: '15"' },
    { label: '15.6"', value: '15.6"' },
    { label: '16"', value: '16"' },
    { label: '17"', value: '17"' },
    { label: '17.3"', value: '17.3"' },
    { label: '19"', value: '19"' },
    { label: '20.1"', value: '20.1"' },
    { label: '21"', value: '21"' },
    { label: 'More than 21"', value: 'More than 21"' },
  ],
  television_brand: [
    { label: "BENQ", value: "BENQ" },
    { label: "Braun", value: "Braun" },
    { label: "Casio", value: "Casio" },
    { label: "Daewoo", value: "Daewoo" },
    { label: "Dell", value: "Dell" },
    { label: "Elekta", value: "Elekta" },
    { label: "Haier", value: "Haier" },
    { label: "Hisense", value: "Hisense" },
    { label: "Hitachi", value: "Hitachi" },
    { label: "Infinix", value: "Infinix" },
    { label: "JVC", value: "JVC" },
    { label: "LG", value: "LG" },
    { label: "Magnavox", value: "Magnavox" },
    { label: "Memorex", value: "Memorex" },
    { label: "Mitsubishi", value: "Mitsubishi" },
    { label: "Napro", value: "Napro" },
    { label: "NEC", value: "NEC" },
    { label: "Nikai", value: "Nikai" },
    { label: "Orbit", value: "Orbit" },
    { label: "Panasonic", value: "Panasonic" },
    { label: "Phillips", value: "Phillips" },
    { label: "Pioneer", value: "Pioneer" },
    { label: "Polaroid", value: "Polaroid" },
    { label: "RCA", value: "RCA" },
    { label: "Samsung", value: "Samsung" },
    { label: "Sanyo", value: "Sanyo" },
    { label: "Sharp", value: "Sharp" },
    { label: "Sony", value: "Sony" },
    { label: "Syinix", value: "Syinix" },
    { label: "Toshiba", value: "Toshiba" },
    { label: "ViewSonic", value: "ViewSonic" },
    { label: "Vizio", value: "Vizio" },
    { label: "Other Brand", value: "Other Brand" },
  ],
  screen_size: [
    { label: '14"', value: '14"' },
    { label: '21"', value: '21"' },
    { label: '24"', value: '24"' },
    { label: '28"', value: '28"' },
    { label: '29"', value: '29"' },
    { label: '32"', value: '32"' },
    { label: '40"', value: '40"' },
    { label: '42"', value: '42"' },
    { label: '43"', value: '43"' },
    { label: '48"', value: '48"' },
    { label: '50"', value: '50"' },
    { label: '55"', value: '55"' },
    { label: '65"', value: '65"' },
    { label: '75"', value: '75"' },
    { label: '80"', value: '80"' },
    { label: '85"', value: '85"' },
    { label: '90"+', value: '90"+' },
    { label: "Other", value: "Other" },
  ],
  r_and_w_brand: [
    { label: "Admiral", value: "Admiral" },
    { label: "Aftron", value: "Aftron" },
    { label: "Bompani", value: "Bompani" },
    { label: "Bosch", value: "Bosch" },
    { label: "Braun", value: "Braun" },
    { label: "Char-Broil", value: "Char-Broil" },
    { label: "Coleman", value: "Coleman" },
    { label: "Daewoo", value: "Daewoo" },
    { label: "Dyson", value: "Dyson" },
    { label: "Electrolux", value: "Electrolux" },
    { label: "Elekta", value: "Elekta" },
    { label: "Fisher & Paykel", value: "Fisher & Paykel" },
    { label: "Frigidaire", value: "Frigidaire" },
    { label: "GE", value: "GE" },
    { label: "Haier", value: "Haier" },
    { label: "Hitachi", value: "Hitachi" },
    { label: "Hoover", value: "Hoover" },
    { label: "Hotpoint-Ariston", value: "Hotpoint-Ariston" },
    { label: "Indesit", value: "Indesit" },
    { label: "Kenmore", value: "Kenmore" },
    { label: "Kenwood", value: "Kenwood" },
    { label: "Kitchen-Aid", value: "Kitchen-Aid" },
    { label: "La Germania", value: "La Germania" },
    { label: "Lavazza", value: "Lavazza" },
    { label: "LG", value: "LG" },
    { label: "Maytag", value: "Maytag" },
    { label: "Miele", value: "Miele" },
    { label: "Moulinex", value: "Moulinex" },
    { label: "Nespresso", value: "Nespresso" },
    { label: "Nikai", value: "Nikai" },
    { label: "Optima", value: "Optima" },
    { label: "Panasonic", value: "Panasonic" },
    { label: "Philips", value: "Philips" },
    { label: "Samsung", value: "Samsung" },
    { label: "Sanyo", value: "Sanyo" },
    { label: "Siemens", value: "Siemens" },
    { label: "Toshiba", value: "Toshiba" },
    { label: "Weber", value: "Weber" },
    { label: "Westpoint", value: "Westpoint" },
    { label: "Whirlpool", value: "Whirlpool" },
    { label: "Zanussi", value: "Zanussi" },
    { label: "Other", value: "Other" },
  ],

  fashion_gender: [
    { label: "Baby Boy", value: "Baby Boy" },
    { label: "Baby Girl", value: "Baby Girl" },
    { label: "Boy’s", value: "Boy’s" },
    { label: "Girl’s", value: "Girl’s" },
    { label: "Men’s", value: "Men’s" },
    { label: "Women’s", value: "Women’s" },
    { label: "Unisex", value: "Unisex" },
  ],
  dial_display: [
    { label: "Analog", value: "Analog" },
    { label: "Digital", value: "Digital" },
  ],
  movement_type: [
    { label: "Automatical", value: "Automatical" },
    { label: "Mechanical", value: "Mechanical" },
    { label: "Quartz", value: "Quartz" },
  ],
  fashion_condition: [
    { label: "New With defects", value: "New With defects" },
    { label: "New with tag/box", value: "New with tag/box" },
    { label: "New without tag/box", value: "New without tag/box" },
    { label: "Used", value: "Used" },
  ],
  occasion: [
    { label: "Business wear", value: "Business wear" },
    { label: "Casual wear", value: "Casual wear" },
    { label: "Formal wear", value: "Formal wear" },
    { label: "Sports", value: "Sports" },
    { label: "Traditional wear", value: "Traditional wear" },
  ],
  stone: [
    { label: "Aquamarine", value: "Aquamarine" },
    { label: "Diamond", value: "Diamond" },
    { label: "Emerald", value: "Emerald" },
    { label: "Moissanite", value: "Moissanite" },
    { label: "Opal", value: "Opal" },
    { label: "Pearl", value: "Pearl" },
    { label: "Ruby", value: "Ruby" },
    { label: "Sapphire", value: "Sapphire" },
    { label: "Topaz", value: "Topaz" },
    { label: "Tourmaline", value: "Tourmaline" },
    { label: "Other", value: "Other" },
  ],
  bag_brand: [
    { label: "Adidas", value: "Adidas" },
    { label: "Aldo", value: "Aldo" },
    { label: "Anne Klein", value: "Anne Klein" },
    { label: "Aptonia", value: "Aptonia" },
    { label: "Bella", value: "Bella" },
    { label: "Burberry", value: "Burberry" },
    { label: "Calvin Klein", value: "Calvin Klein" },
    { label: "Christian Dior", value: "Christian Dior" },
    { label: "Chanel", value: "Chanel" },
    { label: "Charles & Keith", value: "Charles & Keith" },
    { label: "Dakine", value: "Dakine" },
    { label: "Dolce & Gabbana", value: "Dolce & Gabbana" },
    { label: "Elham", value: "Elham" },
    { label: "Fendi", value: "Fendi" },
    { label: "Gucci", value: "Gucci" },
    { label: "Guess", value: "Guess" },
    { label: "Hobo", value: "Hobo" },
    { label: "Levis", value: "Levis" },
    { label: "Louis Vuitton", value: "Louis Vuitton" },
    { label: "Mango", value: "Mango" },
    { label: "Michael Kors", value: "Michael Kors" },
    { label: "New Look", value: "New Look" },
    { label: "Nike", value: "Nike" },
    { label: "Prada", value: "Prada" },
    { label: "River Island", value: "River Island" },
    { label: "Samsonite", value: "Samsonite" },
    { label: "Susen", value: "Susen" },
    { label: "Other", value: "Other" },
  ],
  clothe_brand: [
    { label: "4F", value: "4F" },
    { label: "Adidas", value: "Adidas" },
    { label: "Alexander McQueen", value: "Alexander McQueen" },
    { label: "Armani", value: "Armani" },
    { label: "Burberry", value: "Burberry" },
    { label: "Canali", value: "Canali" },
    { label: "CAT", value: "CAT" },
    { label: "Chanel", value: "Chanel" },
    { label: "Clarks", value: "Clarks" },
    { label: "Converse", value: "Converse" },
    { label: "Diadora", value: "Diadora" },
    { label: "Dior", value: "Dior" },
    { label: "Gucci", value: "Gucci" },
    { label: "H & M", value: "H & M" },
    { label: "Hugo Boss", value: "Hugo Boss" },
    { label: "J.Crew", value: "J.Crew" },
    { label: "Jack & Jones", value: "Jack & Jones" },
    { label: "Kanna", value: "Kanna" },
    { label: "Kenneth Cole", value: "Kenneth Cole" },
    { label: "Kent & Curwen", value: "Kent & Curwen" },
    { label: "Lacoste", value: "Lacoste" },
    { label: "Levi’s", value: "Levi’s" },
    { label: "Majestic", value: "Majestic" },
    { label: "Marc O’Polo", value: "Marc O’Polo" },
    { label: "Nike", value: "Nike" },
    { label: "Pandora", value: "Pandora" },
    { label: "Peacocks", value: "Peacocks" },
    { label: "Peanuts", value: "Peanuts" },
    { label: "Peavey", value: "Peavey" },
    { label: "Prada", value: "Prada" },
    { label: "Puma", value: "Puma" },
    { label: "Queen", value: "Queen" },
    { label: "Reebok", value: "Reebok" },
    { label: "Under Armour", value: "Under Armour" },
    { label: "Vans", value: "Vans" },
    { label: "Venti", value: "Venti" },
    { label: "Victoria", value: "Victoria" },
    { label: "Wally Walker", value: "Wally Walker" },
    { label: "Zara", value: "Zara" },
    { label: "Other", value: "Other" },
  ],
  watche_brand: [
    { label: "A.Lange & Söhne", value: "A.Lange & Söhne" },
    { label: "Alpina", value: "Alpina" },
    { label: "Arnold & Son", value: "Arnold & Son" },
    { label: "Armani", value: "Armani" },
    { label: "Audemars Piguet", value: "Audemars Piguet" },
    { label: "Bamford", value: "Bamford" },
    { label: "Baume & Mercier", value: "Baume & Mercier" },
    { label: "Bell & Ross", value: "Bell & Ross" },
    { label: "Blancpain", value: "Blancpain" },
    { label: "Bovet Fleurier", value: "Bovet Fleurier" },
    { label: "Breguet", value: "Breguet" },
    { label: "Breitling", value: "Breitling" },
    { label: "Bremont", value: "Bremont" },
    { label: "Bulgari", value: "Bulgari" },
    { label: "Bulova", value: "Bulova" },
    { label: "Cartier", value: "Cartier" },
    { label: "Casio", value: "Casio" },
    { label: "Chopard", value: "Chopard" },
    { label: "F.P.Journe", value: "F.P.Journe" },
    { label: "Fossil", value: "Fossil" },
    { label: "Frederique Constant", value: "Frederique Constant" },
    { label: "Girard-Perregaux", value: "Girard-Perregaux" },
    { label: "Gucci", value: "Gucci" },
    { label: "Hamilton", value: "Hamilton" },
    { label: "Hublot", value: "Hublot" },
    { label: "Iwc Schaffhausen", value: "Iwc Schaffhausen" },
    { label: "Jaeger-Lecoultre", value: "Jaeger-Lecoultre" },
    { label: "Jaquet Droz", value: "Jaquet Droz" },
    { label: "Junghans", value: "Junghans" },
    { label: "Laurent Ferrier", value: "Laurent Ferrier" },
    { label: "Liv Watches", value: "Liv Watches" },
    { label: "Longines", value: "Longines" },
    { label: "Louis Vuitton", value: "Louis Vuitton" },
    { label: "Maurice De Mauriac", value: "Maurice De Mauriac" },
    { label: "Maurice Lacroix", value: "Maurice Lacroix" },
    { label: "Meistersinger", value: "Meistersinger" },
    { label: "Montblanc", value: "Montblanc" },
    { label: "Nomos Glashütte", value: "Nomos Glashütte" },
    { label: "Nordgreen", value: "Nordgreen" },
    { label: "Omega", value: "Omega" },
    { label: "Oris", value: "Oris" },
    { label: "Panerai", value: "Panerai" },
    { label: "Parmigiani Fleurier", value: "Parmigiani Fleurier" },
    { label: "Patek Philippe", value: "Patek Philippe" },
    { label: "Piaget", value: "Piaget" },
    { label: "Polo", value: "Polo" },
    { label: "Rado", value: "Rado" },
    { label: "Roger Dubuis", value: "Roger Dubuis" },
    { label: "Rolex", value: "Rolex" },
    { label: "Ressence", value: "Ressence" },
    { label: "Seiko", value: "Seiko" },
    { label: "Tag Heuer", value: "Tag Heuer" },
    { label: "Tiffany & Co.", value: "Tiffany & Co." },
    { label: "Tissot", value: "Tissot" },
    { label: "Tudor", value: "Tudor" },
    { label: "Ulysse Nardin", value: "Ulysse Nardin" },
    { label: "Vacheron Constantin", value: "Vacheron Constantin" },
    { label: "Van Cleef & Arpels", value: "Van Cleef & Arpels" },
    { label: "Verdure", value: "Verdure" },
    { label: "Vincero", value: "Vincero" },
    { label: "Weiss", value: "Weiss" },
    { label: "Zenith", value: "Zenith" },
    { label: "Other", value: "Other" },
  ],
  shoes_brand: [
    { label: "Adidas", value: "Adidas" },
    { label: "Anbessa Chama", value: "Anbessa Chama" },
    { label: "Bogs", value: "Bogs" },
    { label: "Burberry", value: "Burberry" },
    { label: "By Hudson", value: "By Hudson" },
    { label: "Calvin Klein", value: "Calvin Klein" },
    { label: "CAT", value: "CAT" },
    { label: "Clarks", value: "Clarks" },
    { label: "Columbia", value: "Columbia" },
    { label: "Converse", value: "Converse" },
    { label: "Crocs", value: "Crocs" },
    { label: "Fila", value: "Fila" },
    { label: "Johnson and Murphy", value: "Johnson and Murphy" },
    { label: "Jordan", value: "Jordan" },
    { label: "Kangaroo Chama", value: "Kangaroo Chama" },
    { label: "kENNETH COLE", value: "kENNETH COLE" },
    { label: "Lacoste", value: "Lacoste" },
    { label: "New Balance", value: "New Balance" },
    { label: "Nike", value: "Nike" },
    { label: "Peacock Chama", value: "Peacock Chama" },
    { label: "Puma", value: "Puma" },
    { label: "Rebook", value: "Rebook" },
    { label: "Skechers", value: "Skechers" },
    { label: "Tana Chama", value: "Tana Chama" },
    { label: "Testoni", value: "Testoni" },
    { label: "Tikur Abay Chama", value: "Tikur Abay Chama" },
    { label: "Timberland", value: "Timberland" },
    { label: "TOMMY HILFIGER", value: "TOMMY HILFIGER" },
    { label: "Under Armour", value: "Under Armour" },
    { label: "Vans", value: "Vans" },
    { label: "Other", value: "Other" },
  ],

  gender: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ],
  job_gender: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Both", value: "Both" },
  ],
  career_level: [
    { label: "Expert", value: "Expert" },
    { label: "Intermediate", value: "Intermediate" },
    { label: "Junior", value: "Junior" },
    { label: "Senior", value: "Senior" },
  ],
  employment_type: [
    { label: "Contract", value: "Contract" },
    { label: "Freelance", value: "Freelance" },
    { label: "Full-time", value: "Full-time" },
    { label: "Internship", value: "Internship" },
    { label: "Part-time", value: "Part-time" },
    { label: "Temporary", value: "Temporary" },
  ],
  experience: [
    { label: "0 Year", value: "0 Year" },
    { label: "1 Year", value: "1 Year" },
    { label: "2 Years", value: "2 Years" },
    { label: "3 Years", value: "3 Years" },
    { label: "4 Years", value: "4 Years" },
    { label: "5 Years", value: "5 Years" },
    { label: "5+ Years", value: "5+ Years" },
  ],
  education: [
    { label: "Bachelors Degree", value: "Bachelors Degree" },
    { label: "High-School/Secondary", value: "High-School/Secondary" },
    { label: "Masters Degree", value: "Masters Degree" },
    { label: "N/A", value: "N/A" },
    { label: "PhD", value: "PhD" },
  ],
};

export const Industry = [
  "Accounting",
  "Airlines Aviation",
  "Architecture Interior Design",
  "Art Entertainment",
  "Automotive",
  "Banking Finance",
  "Beauty",
  "Business Development",
  "Business Supplies Equipment",
  "Construction",
  "Consulting",
  "Customer Service",
  "Education",
  "Engineering",
  "Environmental Services",
  "Event Management",
  "Executive",
  "Fashion",
  "Food Beverages",
  "Government / Administration",
  "Graphic Design",
  "HR Recruitment",
  "Hospitality Restaurants",
  "Import Export",
  "Industrial Manufacturing",
  "Information Technology",
  "Insurance",
  "Internet",
  "Legal Services",
  "Logistics Distribution",
  "Marketing Advertising",
  "Media",
  "Medical Healthcare",
  "Oil, Gas Energy",
  "Online Media",
  "Pharmaceuticals",
  "Public Relations",
  "Real Estate",
  "Research Development",
  "Retail Consumer Goods",
  "Safety Security",
  "Sales",
  "Secretarial",
  "Sports Fitness",
  "Telecommunications",
  "Transportation",
  "Travel Tourism",
  "Veterinary Animals",
  "Warehousing",
  "Wholesale",
];
