// product sorting parameters

export const MAX_WIDTH = 980;
export const NORMAL_CARD_WIDTH = 230;
export const SORT_OPTIONS = [
  { value: "recommendation", label: "Recommendations" },
  { value: "featured", label: "Default" },
  { value: "newest", label: "Newest Ads" },
  { value: "oldest", label: "Oldest Ads" },
  { value: "low_to_high", label: "Lowest Price" },
  { value: "high_to_low", label: "Highest Price" },
  { value: "a_to_z", label: "Title Ascending" },
  { value: "z_to_a", label: "Title Descending" },
];
export const AD_SORT_OPTIONS = [
  { value: "featured", label: "Default" },
  { value: "newest", label: "Newest Ads" },
  { value: "oldest", label: "Oldest Ads" },
  { value: "low_to_high", label: "Lowest Price" },
  { value: "high_to_low", label: "Highest Price" },
  { value: "a_to_z", label: "Title Ascending" },
  { value: "z_to_a", label: "Title Descending" },
];
