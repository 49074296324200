import queryString from "query-string";
import { api } from "./config";
export const ProductFeaturd = async (category, page) => {
  const response = await api.get(
    `api/main/product/home/list/?category=${category}&page=${page}&featured=True`
  );
  return response.data;
};

export const ProductHomeFeatured = async () => {
  const response = await api.get(
    `api/main/product/home/featured/list/`
  );
  return response.data;
};

export const getFeaturedProductInfinite = async ({ queryKey, pageParam }) => {
  let page = 1;

  if (pageParam !== undefined) {
    // const val = queryString.parse(pageParam);
    const param = pageParam.split("?")[1];
    const val = queryString.parse(param);

    page = val["page"];
    // const values = pageParam.indexOf("page=");
    // page = pageParam.charAt(parseInt(values) + 5);
  }

  const response = await api.get(
    `api/main/product/home/list/?category=${queryKey[2]}&page=${page}&featured=True`
  );
  return response.data;
};
