import { useQuery } from "react-query";

import { get_categories, get_thirdlevel_catagories } from "../apis";

export const useCatagory = () => {
  return useQuery("category", () => get_categories());
};

export const useThirdLevelCatagories = () => {
  return useQuery("category", () => get_thirdlevel_catagories());
};
