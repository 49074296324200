import ArrowRight from "@iconify/icons-eva/arrow-ios-forward-outline";
import { Icon } from "@iconify/react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useTranslation } from "react-i18next";

function Breadcrumb(props) {
  const { t } = useTranslation();
  const { setCompanies, path, goBack, update, user } = props;

  return (
    <Breadcrumbs
      separator={
        <Box
          component={Icon}
          icon={ArrowRight}
          sx={{ width: 16, height: 16 }}
        />
      }
      aria-label="breadcrumb"
    >
      {path.map(
        (item, index) =>
          index < path.length - 1 && (
            <Link
              key={item}
              underline="hover"
              color="blue"
              sx={{ cursor: "pointer" }}
              onClick={() => goBack(item)}
            >
              {t(item)}
            </Link>
          )
      )}
      <Typography color="text.primary">
        {path.length > 1 || !user.companies || update ? (
          t(path[path.length - 1])
        ) : (
          <Button
            color="secondary"
            startIcon={<ArrowBackIosIcon fontSize="small" />}
            onClick={() => setCompanies({})}
          >
            {t("Back")}
          </Button>
        )}
      </Typography>
    </Breadcrumbs>
  );
}

export default memo(Breadcrumb);
