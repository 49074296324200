import emailIcon from "@iconify/icons-eva/email-outline";
import phoneIcon from "@iconify/icons-eva/phone-outline";
import locationIcon from "@iconify/icons-eva/pin-outline";
import { Icon } from "@iconify/react";
import { Box, Button, Grid, IconButton, Stack, SvgIcon } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import mainCategoryList from "../../config/categories/MainCategoryLists";
import ic_app_store from "../../config/ic_app_store.svg";
import ic_google_play from "../../config/ic_google_play.svg";
import huawei_appgallery from "../../config/huawei-appgallery.svg";
import { ReactComponent as logoSvg } from "../../config/logo/fs1.svg";
import Label from "../Label";
import { Copyright } from "./Copyright";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    margin: "0px",
  },

  listitem: {
    "&.MuiListItem-gutters": {
      padding: "0px",
    },
  },
  listitemIcon: {
    "&.MuiListItemIcon-root": {
      minWidth: "25px",
    },
  },
}));

const Contacts = [
  { text: "Kera, Addis Ababa, Ethiopia", icon: locationIcon },
  { text: "+25111 562-0062", icon: phoneIcon },
  { text: "info@familysooq.com", icon: emailIcon },
];

const COMPANY = [
  {
    text: "About Us",
    link: "/about-us",
  },
  {
    text: "Contact Us",
    link: "/contact-us",
  },
  {
    text: "FAQs",
    link: "/support/faqs",
  },
  {
    text: "Saftey Tips",
    link: "/saftey-tips",
  },
  {
    text: "Create the best Ad",
    link: "/make-good-ad",
  },
];

const Social = [
  {
    title: "Facebook",
    icon: "/static/icons/social/facebook.svg",
    url: "https://www.facebook.com/FamilySooq",
  },
  // {
  //   title: "Twitter",
  //   icon: "/static/icons/social/twitter.svg",
  //   url: "#",
  // },
  // {
  //   title: "Youtube",
  //   icon: "/static/icons/social/youtube.svg",
  //   url: "#",
  // },
  // {
  //   title: "Instagram",
  //   icon: "/static/icons/social/instagram.svg",
  //   url: "#",
  // },
  {
    title: "telegram3",
    icon: "/static/icons/social/telegram3.svg",
    url: "https://t.me/familysooq",
  },
  {
    title: "linkedin2",
    icon: "/static/icons/social/linkedin2.svg",
    url: "https://www.linkedin.com/company/81755424/",
  },
];

export function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer>
      <Box
        sx={{
          padding: (theme) => theme.spacing(3, 2, 3, 2),
          mt: (theme) => theme.spacing(8),
          bgcolor: "background.paper",
        }}
      >
        <Container>
          <Grid container direction="row" justify="center">
            <Grid item xs={6} sm={2} lg={3}>
              <Typography variant="h6" mb={1} color="secondary">
                {t("Company")}
              </Typography>
              <List dense={true} disablePadding={true}>
                {COMPANY.map((item, ind) => (
                  <Link
                    component={RouterLink}
                    to={{ pathname: item.link }}
                    key={ind}
                    sx={{
                      "&:hover": {
                        color: (theme) => theme.palette.secondary.main,
                      },
                    }}
                    underline="none"
                  >
                    <ListItemText
                      primary={t(item.text)}
                      primaryTypographyProps={{
                        fontSize: "14px ",
                        color: "text.secondary",
                        fontWeight: 200,
                      }}
                      sx={{ p: 0.3, color: "text.secondary" }}
                    />
                  </Link>
                ))}
              </List>
            </Grid>
            <Grid item xs={6} sm={2} lg={3}>
              <Typography variant="h6" mb={1} color="secondary">
                {t("Category")}
              </Typography>
              <List dense={true} disablePadding={true}>
                {mainCategoryList.map((item, ind) => (
                  <Link
                    component={RouterLink}
                    to={{ pathname: `/category/${item.slug}` }}
                    key={ind}
                    sx={{
                      "&:hover": {
                        color: (theme) => theme.palette.secondary.main,
                      },
                    }}
                    underline="none"
                  >
                    <ListItemText
                      primary={t(item.name)}
                      primaryTypographyProps={{
                        fontSize: "14px ",
                        color: "text.secondary",
                        fontWeight: 200,
                      }}
                      sx={{ p: 0.3, color: "text.secondary" }}
                    />
                  </Link>
                ))}
              </List>
            </Grid>
            <Grid item xs={5} md={6} lg={3}>
              <Typography variant="h6" mb={1} color="secondary">
                {t("Contact Us")}
              </Typography>
              <List>
                {Contacts.map((obj, ind) => (
                  <ListItem
                    className={classes.listitem}
                    key={ind}
                    sx={{ mb: 2, fontSize: "10px" }}
                  >
                    <ListItemIcon className={classes.listitemIcon}>
                      <Box
                        component={Icon}
                        icon={obj.icon}
                        sx={{
                          width: 20,
                          height: 20,
                          color: (theme) => theme.palette.secondary.main,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        ind === 0
                          ? obj.text
                              .split(", ")
                              .map((x, i) => (i > 0 ? ", " : "") + t(x))
                          : obj.text
                      }
                      primaryTypographyProps={{
                        fontSize: "14px ",
                        color: "text.secondary",
                        fontWeight: 200,
                      }}
                      sx={{}}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} sm={3} lg={3}>
              <Box>
                <Typography mb={2} color="secondary" variant="h6">
                  {t("Download the app now")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    "& > :not(style)": {
                      marginBottom: "16px",
                    },
                  }}
                >
                  <Button
                    target="_blank"
                    rel="noopener"
                    href="https://play.google.com/store/apps/details?id=com.familysooq.m.twa"
                    startIcon={
                      <Box
                        component="img"
                        sx={{ width: "28px", height: "28px" }}
                        src={ic_google_play}
                      />
                    }
                    sx={{
                      color: "rgb(255, 255, 255)",
                      padding: "5px 12px",
                      border: "1px solid rgba(0, 0, 0, 0.24)",
                      background:
                        "linear-gradient(rgb(22, 28, 36) 0%, rgb(0, 0, 0) 100%)",
                      width: "180px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Trans i18nKey="google_download">
                        <Typography variant="caption">GET IT ON</Typography>
                        <Typography varinat="h6">Google Play</Typography>
                      </Trans>
                    </Box>
                  </Button>

                  <Button
                    target="_blank"
                    rel="noopener"
                    href="https://apps.apple.com/us/app/familysooq/id1663036320"
                    startIcon={
                      <Box
                        component="img"
                        sx={{ width: "28px", height: "28px" }}
                        src={ic_app_store}
                      />
                    }
                    sx={{
                      color: "rgb(255, 255, 255)",
                      padding: "5px 12px",
                      border: "1px solid rgba(0, 0, 0, 0.24)",
                      background:
                        "linear-gradient(rgb(22, 28, 36) 0%, rgb(0, 0, 0) 100%)",
                      width: "180px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Trans i18nKey="apple_download">
                        <Typography variant="caption">Download From</Typography>
                        <Typography varinat="h6">Apple Store</Typography>
                      </Trans>
                    </Box>
                  </Button>

                  <Button
                    target="_blank"
                    rel="noopener"
                    href="https://appgallery.huawei.com/app/C107638313"
                    startIcon={
                      <Box
                        component="img"
                        sx={{ width: "28px", height: "28px" }}
                        src={huawei_appgallery}
                      />
                    }
                    sx={{
                      color: "rgb(255, 255, 255)",
                      padding: "5px 12px",
                      border: "1px solid rgba(0, 0, 0, 0.24)",
                      background:
                        "linear-gradient(rgb(22, 28, 36) 0%, rgb(0, 0, 0) 100%)",
                      width: "180px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Trans i18nKey="app_gallery_download">
                        <Typography variant="caption">EXPLORE IT ON</Typography>
                        <Typography varinat="h6">AppGallery</Typography>
                      </Trans>
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="center"
        mt={1}
        p={2}
        sx={{ bgcolor: "background.default" }}
      >
        <Box display="flex" flexDirection="column" justifyContent="start">
          <RouterLink to="/">
            <Box display="flex" justifyContent="start">
              <SvgIcon
                sx={{
                  fontSize: "98px",
                  height: "35px",
                  width: "240px",
                  color: "primary.light",
                }}
                component={logoSvg}
                inheritViewBox
              />
            </Box>
          </RouterLink>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 1 }}
          >
            {Social.map((obj, ind) => (
              <IconButton
                key={"footer-" + ind}
                componenet={Link}
                target="_blank"
                href={obj.url}
                sx={{
                  bgcolor: "secondary.main",
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.secondary.main,
                  },
                }}
              >
                <img
                  src={obj.icon}
                  alt=""
                  style={{ width: "15px", height: "15px" }}
                />
              </IconButton>
            ))}
          </Stack>
        </Box>
      </Box>
      <Copyright sx={{ color: "#fff" }} />
    </footer>
  );
}
