import queryString from "query-string";

import { api } from "./config";

// api for searching ads with paginatiob
export const search_ad = async ({ pageParam = 0, queryKey }) => {
  let page;
  // check if it is the first time fetching
  if (pageParam !== 0) {
    const param = pageParam.split("?")[1];
    const values = queryString.parse(param);
    page = values.page;
  }
  // alert(JSON.stringify(queryKey[1].values));
  const fil = queryKey[1].fil !== undefined ? queryKey[1].fil : {};
  const params = {
    page: page,
    ...queryKey[1].values,
    ...fil,
  };
  const response = await api.get(`/api/search/products/`, {
    params: { ...params },
  });
  return response.data;
};

// search bar autocomplete
export const search_autocomplete = async (payload) => {
  const response = await api.get(`/api/search/auto-complete/`, {
    params: { ...payload },
  });
  return response.data;
};

// get catagories list (nested list)
