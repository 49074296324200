import { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import Facebook from "./components/Account/Facebook";
import Google from "./components/Account/Google";
import TelegramAuth from "./components/Account/TelegramAuth";
import VerifyCode from "./components/Account/VerifyCode";
import LogoOnlyLayout from "./layout/LogoOnly";
import NavbarLayoutWithCategory from "./layout/navabarWithCategory";
import NavbarLayout from "./layout/NavabarWithFooterLayout";
import NavbarWithoutSearch from "./layout/navabarWithoutSearch";
import Navbar from "./layout/Navbar";
import SearchLayout from "./layout/searchLayout";
import Loading from "./LoadingDots";
// import NotFound from "./pages/Page404";
import Payment from "./pages/Payment";
import Purchase from "./pages/Payment/Purchase";
import Search from "./pages/Searching";
const Home = lazy(() => import("./pages/Home"));
const Main = lazy(() => import("./pages/MainCatagory/MainCatagory"));
const Filter = lazy(() => import("./pages/MainCatagory/Filtering"));
const AdDetail = lazy(() => import("./pages/AdDetail"));
const UserProductList = lazy(() => import("./pages/Product/UserProducts"));
const RecentProduct = lazy(() => import("./pages/Product/RecentProduct"));
const SimilarProduct = lazy(() => import("./pages/Product/similarProduct"));
const RecommendProductPage = lazy(() =>
  import("./pages/Product/RecommendedProductPage")
);
const Terms = lazy(() => import("./pages/TermsCondition/Terms.js"));
const SafteyTips = lazy(() => import("./pages/SafteyTips"));
const About = lazy(() => import("./pages/AboutUs"));
const Contact = lazy(() => import("./pages/Contact"));
const DownloadApp = lazy(() => import("./pages/DownloadApp"));


const Faqs = lazy(() => import("./pages/Faqs"));
const GoodAd = lazy(() => import("./pages/HowMakeGoodAd"));
const Notifications = lazy(() => import("./pages/Notifications"));
const Profile = lazy(() => import("./pages/Account"));
const Chat = lazy(() => import("./pages/Chat"));
const AdActivate = lazy(() => import("./pages/AdActivate"));
const Post = lazy(() => import("./pages/ProductAd/Post"));
const Update = lazy(() => import("./pages/ProductAd/Update"));
const UpdateImage = lazy(() =>
  import("./components/ProductAd/Update/UpdateImage")
);
const Pricing = lazy(() => import("./components/Pricing/subscription"));
const Signup = lazy(() => import("./pages/Account/Auth/Signup"));
const Signin = lazy(() => import("./pages/Account/Auth/Signin"));
const Activate = lazy(() => import("./pages/Account/Auth/Activate"));
const MarketingInfo = lazy(() => import("./components/Account/MarketingInfo"));
const PhoneConfirm = lazy(() => import("./pages/Account/Auth/PhoneConfirm"));
const ResetPassword = lazy(() => import("./pages/Account/Auth/ResetPassword"));
const ResetPasswordConfirm = lazy(() =>
  import("./pages/Account/Auth/ResetPasswordConfirm")
);
const TwoStepVerification = lazy(() =>
  import("./pages/Account/Auth/TwoStepVerification")
);
const PrivacyPolicy = lazy(() =>
  import("./pages/TermsCondition/PrivacyPolicy")
);

function Router({ isAuthenticated, location }) {
  useEffect(() => {}, [location]);

  return useRoutes([
    {
      path: "/",
      element: <NavbarLayout />,
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: "/make-good-ad",
          element: (
            <Suspense fallback={<Loading />}>
              <GoodAd />
            </Suspense>
          ),
        },

        {
          path: "profile/*",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Profile />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/profile/dashboard" />
          ),
        },
      ],
    },
    {
      path: "/",
      element: <NavbarWithoutSearch />,
      children: [
        {
          path: "category/:slug",
          element: (
            <Suspense fallback={<Loading />}>
              <Main />
            </Suspense>
          ),
        },
        {
          path: "notifications",
          element: (
            <Suspense fallback={<Loading />}>
              <Notifications />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/",
      element: <NavbarLayoutWithCategory />,
      children: [
        {
          path: "category/:name/:sub_cat",
          element: (
            <Suspense fallback={<Loading />}>
              {" "}
              <Filter />
            </Suspense>
          ),
        },

        {
          path: "recent-products",
          element: (
            <Suspense fallback={<Loading />}>
              <RecentProduct />
            </Suspense>
          ),
        },
        {
          path: "similar-products/:id",
          element: (
            <Suspense fallback={<Loading />}>
              <SimilarProduct />
            </Suspense>
          ),
        },
        {
          path: "recommended-products",
          element: (
            <Suspense fallback={<Loading />}>
              <RecommendProductPage />
            </Suspense>
          ),
        },
        {
          path: "detail/:ad_id",
          element: (
            <Suspense fallback={<Loading />}>
              <AdDetail />
            </Suspense>
          ),
        },
        {
          path: "about-us",
          element: (
            <Suspense fallback={<Loading />}>
              <About />
            </Suspense>
          ),
        },
        {
          path: "contact-us",
          element: (
            <Suspense fallback={<Loading />}>
              <Contact />
            </Suspense>
          ),
        },
        
        
        {
          path: "product-list/:type/:user_id",
          element: (
            <Suspense fallback={<Loading />}>
              <UserProductList />
            </Suspense>
          ),
        },
      ],
    },

    {
      path: "/",
      element: <SearchLayout />,
      children: [
        {
          path: "search",
          element: (
            <Suspense fallback={<Loading />}>
              <Search />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/",
      element: <Navbar />,
      children: [
        {
          path: "chat/*",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Chat />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/chat" />
          ),
        },
        {
          path: "product/activate/",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <AdActivate />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/product/activate/" />
          ),
        },
      ],
    },

    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        {
          path: "post-review/:id",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Payment />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/post-review/:id" />
          ),
        },
        {
          path: "/purchase/:type/",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Purchase />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/purchase/type/" />
          ),
        },

        {
          path: "/packages/list",
          element: (
            <Suspense fallback={<Loading />}>
              <Pricing />
            </Suspense>
          ),
        },
        {
          path: "post-ad",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Post />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/post-ad" />
          ),
        },
        {
          path: "update-ad/:id",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <Update />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/update-ad/:id" />
          ),
        },
        {
          path: "update-image/:id",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <UpdateImage />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/update-image/:id" />
          ),
        },
        {
          path: "signin",
          element: (
            <Suspense fallback={<Loading />}>
              <Signin />
            </Suspense>
          ),
        },
        {
          path: "signup",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <Signup />
            </Suspense>
          ),
        },
        {
          path: "activate/:id",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <Activate />
            </Suspense>
          ),
        },
        {
          path: "two-step-verification/",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <TwoStepVerification />
            </Suspense>
          ),
        },
        {
          path: "app",
          element: (
            <Suspense fallback={<Loading />}>
              <DownloadApp />
            </Suspense>
          ),
        },
        {
          path: "marketing-info/:id",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <MarketingInfo />
            </Suspense>
          ),
        },
        {
          path: "phone/change/confirm/:id",
          element: isAuthenticated ? (
            <Suspense fallback={<Loading />}>
              <PhoneConfirm />
            </Suspense>
          ) : (
            <Navigate to="/signin?next=/phone/change/confirm/:id" />
          ),
        },
        {
          path: "password/reset",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <ResetPassword />
            </Suspense>
          ),
        },
        {
          path: "password/reset/confirm/:id",
          element: isAuthenticated ? (
            <Navigate to="/" />
          ) : (
            <Suspense fallback={<Loading />}>
              <ResetPasswordConfirm />
            </Suspense>
          ),
        },

        { path: "google", element: <Google /> },
        { path: "facebook", element: <Facebook /> },

        { path: "account/verify-code", element: <VerifyCode /> },

        {
          path: "privacy_policy",
          element: (
            <Suspense fallback={<Loading />}>
              <PrivacyPolicy />
            </Suspense>
          ),
        },
        {
          path: "terms",
          element: (
            <Suspense fallback={<Loading />}>
              <Terms />
            </Suspense>
          ),
        },
        {
          path: "saftey-tips",
          element: (
            <Suspense fallback={<Loading />}>
              <SafteyTips />
            </Suspense>
          ),
        },
        {
          path: "telegram/auth/:tg_chat_id/:tg_user_id/",
          element: <TelegramAuth />,
        },
        {
          path: "support/faqs",
          element: (
            <Suspense fallback={<Loading />}>
              <Faqs />
            </Suspense>
          ),
        },
      ],
    },
  ]);
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  location: state.location.location,
});

export default connect(mapStateToProps, {})(Router);
