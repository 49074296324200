import { Backdrop } from "@mui/material";

export default function LoadingDots() {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: "background.default",
      }}
      open={true}
    >
      <div className="snippet" data-title=".dot-stretching">
        <div className="stage">
          <div className="dot-stretching"></div>
        </div>
      </div>
    </Backdrop>
  );
}
