// ----------------------------------------------------------------------

export default function Stepper(theme) {
  return {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          // color: "#FB8500",
          fontSize: "30px",
          "&.Mui-completed": {
            color: "#54D62C",
          },
          "&.Mui-active": {
            color: "#FB8500",
          },
        },
      },
    },
  };
}
