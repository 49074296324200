import { Icon } from "@iconify/react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  SvgIcon,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ReactComponent as ad_list } from "../../config/image/icon/ad_list.svg";
import { ReactComponent as dashboard } from "../../config/image/icon/dashboard.svg";
import { ReactComponent as feedback } from "../../config/image/icon/feedback.svg";
import { ReactComponent as point } from "../../config/image/icon/point.svg";
import { logout } from "../../redux/actions/auth";
import { ColorModeContext } from "../../theme";
import withLoginRequired from "../Feedback/withLoginRequired";
import MenuPopover from "../MenuPopover";

const LARG_MENU_OPTIONS = [
  { label: "My Ads", linkTo: "/profile/myads", icon: ad_list },
  { label: "Dashboard", linkTo: "/profile/dashboard", icon: dashboard },
  { label: "Feedbacks", linkTo: "/profile/feedback", icon: feedback },
  { label: "FS Point", linkTo: "/profile/points", icon: point },
];

const MENU_OPTIONS = [
  {
    label: "Settings",
    linkTo: "/profile/setting/general",
    icon: "carbon:settings",
  },
];

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 22,
    height: 22,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const AccountWithLogin = withLoginRequired(IconButton);

const theme_mode_options = [
  { label: "Light Mode", value: "light" },
  { label: "Dark Mode", value: "dark" },
  { label: "Use system", value: "sys" },
];

function AccountPopover({ user, logout, value, isAuthenticated }) {
  const [anchorAccRef, setAccAnchorRef] = useState(null);
  const [open, setOpen] = useState(false);
  const { mode, setMode } = useContext(ColorModeContext);
  const [selected, setSelected] = useState(() => (mode === "light" ? 0 : 1));
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(true);
    setAccAnchorRef(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAccAnchorRef(null);
  };
  const handleLogout = (event) => {
    handleClose();
    logout();
    navigate("/");
  };

  const handleTheme = () => {
    setMode.toggleColorMode(theme_mode_options[mode === "light" ? 1 : 0].value);
  };

  return (
    <>
      <IconButton
        sx={{ ml: 1.5, p: 0 }}
        ref={anchorAccRef}
        // onClick={() => setOpen(true)}
        // onPreClick={handleOpen}
        onClick={handleOpen}
      >
        <Avatar src={user && user.picture} />
      </IconButton>
      {
        <MenuPopover
          open={open}
          onClose={handleClose}
          anchorEl={anchorAccRef && anchorAccRef}
          sx={{ width: 250 }}
        >
          <Box
            sx={{
              display: "flex",
              p: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              alignItems: "center",
            }}
          >
            <Avatar src={user && user.picture} sx={{ width: 55, height: 55 }} />
            <Box sx={{ pl: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
              {user ? (
                <Typography variant="subtitle1" noWrap>
                  {user && user.first_name + " " + user.last_name}
                </Typography>
              ) : (
                <>
                  <Link
                    color="primary"
                    sx={{
                      textDecoration: "none",
                      color: "primary",
                      "&:hover": {
                        color: "secondary.main",
                      },
                    }}
                    variant="subtitle1"
                    component={RouterLink}
                    to="/signin"
                  >
                    {t("Login")}
                  </Link>
                  {" " + t("or") + " "}
                  <Link
                    sx={{
                      textDecoration: "none",
                      color: "primary",
                      "&:hover": {
                        color: "secondary.main",
                      },
                    }}
                    component={RouterLink}
                    to="/signup"
                  >
                    {t("Register")}
                  </Link>
                </>
              )}
              <Typography
                variant="body2"
                sx={{ color: "text.secondary" }}
                noWrap
              >
                {user ? user.phone_number ?? user.email : ""}
              </Typography>
            </Box>
          </Box>
          <Divider />
          {isAuthenticated && (
            <Box
              sx={{
                p: 2,
                pb: 0,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
              }}
            >
              {LARG_MENU_OPTIONS.map((option) => (
                <Box
                  fontSize={12}
                  sx={{
                    height: 65,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "rev_grey.200",
                    borderRadius: ".5rem",
                    pb: 1,
                    textDecoration: "none",
                    color: "inherit",
                    "&:hover": {
                      bgcolor: "rev_grey.300",
                    },
                  }}
                  component={RouterLink}
                  to={option.linkTo}
                  key={option.linkTo}
                >
                  <SvgIcon
                    sx={{
                      fontSize: "98px",
                      height: "25px",
                      mb: 0.6,
                      mt: 1.2,
                    }}
                    component={option.icon}
                    color="primary"
                    inheritViewBox
                  />
                  <Typography comononet="span" fontSize="12px">
                    {t(option.label)}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
          <MenuList>
            {MENU_OPTIONS.map(
              (option) =>
                isAuthenticated && (
                  <MenuItem
                    key={option.label}
                    to={option.linkTo}
                    component={RouterLink}
                    onClick={handleClose}
                    sx={{ fontSize: "14px", py: 1.5, px: 2.5 }}
                  >
                    <ListItemIcon mr={0} sx={{ mr: 0, color: "grey.400" }}>
                      <Icon fontSize="18px" icon={option.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "14px",
                        color: "grey.200",
                      }}
                    >
                      {t(option.label)}
                    </ListItemText>
                  </MenuItem>
                )
            )}

            <MenuItem sx={{ typography: "body2", py: 1, px: 2.5 }} fullWidth>
              <ListItemIcon
                mr={0}
                sx={{ mr: 0, minWidth: "20px", color: "grey.400" }}
              >
                <Icon fontSize="18px" icon="fluent:dark-theme-20-filled" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: "14px",
                  color: "grey.200",
                }}
              >
                {t("Theme Mode")}
                <Typography
                  component="span"
                  sx={{
                    bgcolor: "rgba(239,71,67,.15)",
                    color: "red",
                    fontSize: "10px",
                    borderRadius: "4px",
                    p: ".25rem",
                    ml: 0.5,
                  }}
                >
                  {t("Beta")}
                </Typography>
              </ListItemText>
              <MaterialUISwitch
                checked={selected}
                onClick={handleTheme}
                size="small"
              />
            </MenuItem>
            {isAuthenticated && (
              <MenuItem
                sx={{ fontSize: "14px", py: 1, px: 2.5 }}
                onClick={handleLogout}
                component={Button}
                fullWidth
              >
                <ListItemIcon mr={0} sx={{ mr: 0, color: "grey.400" }}>
                  <Icon fontSize="18px" icon="fluent:arrow-exit-20-filled" />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "14px",
                    color: "grey.200",
                    textAlign: "left",
                  }}
                >
                  {t("Logout")}
                </ListItemText>
              </MenuItem>
            )}
          </MenuList>
        </MenuPopover>
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(AccountPopover);
