import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { close_login_dialog, load_user, login } from "../../redux/actions/auth";
import { isEmpty } from "../../utils/validation";
import AutoSocial from "./AuthSocial";
import { message } from "./Constants";
import { checkEmpty } from "./ValidateField";

function Login({ login, load_user, dCA, ...props }) {
  const { close_login_dialog, openLoginDialog } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({ email: "", password: "" });

  const handleClose = () => {
    close_login_dialog();
  };

  const handleBlure = (e) => {
    if (e.target.value === "")
      setErrors((prev) => ({ ...prev, [e.target.name]: message.VALUE_ERROR }));
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.name]: null }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = checkEmpty(e);
    setErrors((prev) => ({ ...prev, ...error, detail: null }));
    setSubmitted(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (submitted && isEmpty(errors)) {
      const response = await login(values);
      if (response.two_factor_auth) {
        close_login_dialog();
        navigate(`/two-step-verification`, { state: response });
      } else if (isEmpty(response)) {
        load_user();
        dCA && dCA();
      } else {
        if (response.otp) {
          close_login_dialog();
          navigate(`/activate/${Number(response.otp)}`, {
            state: values.email,
          });
        }
        if (
          response.password &&
          response.password[0] === "This field may not be blank."
        )
          response.password[0] = "Invalid password.";
        setErrors(response);
      }
    }
    setSubmitted(false);
  }, [submitted]);

  return (
    <Dialog open={openLoginDialog}>
      <Backdrop
        sx={{
          color: "#fff",
          position: "absolute",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={submitted}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>
        <Typography
          component="h1"
          variant="h4"
          sx={{
            textAlign: "center",
          }}
        >
          {t("Sign in with")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <AutoSocial />
        {errors.detail && <Alert severity="error">{t(errors.detail)}</Alert>}
        <form onSubmit={handleSubmit}>
          <Stack spacing={3} mb={3} mt={1}>
            <TextField
              fullWidth
              size="small"
              name="email"
              label={t("Enter email or phone")}
              onChange={handleChange}
              onBlur={handleBlure}
              error={Boolean(errors.email)}
              helperText={t(errors.email)}
            />

            <TextField
              fullWidth
              size="small"
              autoComplete="off"
              name="password"
              label={t("Password")}
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              onBlur={handleBlure}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((show) => !show)}
                      edge="end"
                    >
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.password)}
              helperText={t(errors.password)}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
          >
            {t("Login")}
          </LoadingButton>
        </form>
        <Stack direction="row" justifyContent="center" sx={{ my: 1 }}>
          <Link
            component="button"
            onClick={() => {
              close_login_dialog();
              navigate("/password/reset");
            }}
            variant="subtitle2"
            underline="none"
            color="secondary"
          >
            {t("Forgot password?")}
          </Link>
        </Stack>
        <Grid>
          <Divider />
        </Grid>
        <Grid container>
          <Grid item>
            <Box pt={3}>
              <Box component="div" display="inline">
                {t("Don't have an account?")}
              </Box>
              <Box pl={1} component="div" display="inline">
                <Link
                  component="button"
                  onClick={() => {
                    close_login_dialog();
                    navigate("/signup");
                  }}
                  variant="subtitle2"
                  color="secondary"
                  underline="none"
                >
                  {t("Signup")}
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("Cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  openLoginDialog: state.auth.openLoginDialog,
  dCA: state.auth.dCA,
});

export default connect(mapStateToProps, {
  login,
  load_user,
  close_login_dialog,
})(Login);
