import { Icon } from "@iconify/react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import queryString from "query-string";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import BasicCircularLoading from "./Feedback/BasicCircularLoading";
import MenuPopover from "./MenuPopover"; // , { ArrowStyle }

const useStyles = makeStyles((theme) => ({
  root: {
    width: "30%",
    textAlign: "center",
    borderRadius: "inherit",
  },
  catBtn: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    paddingLeft: theme.spacing(1.5),
    cursor: "pointer",
    textOverflow: "ellipsis",
  },
  desk: {
    width: "90%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mob: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  list: {
    overflowY: "auto",
    padding: 0,
    listStyle: "none",
    height: "100%",
    fontSize: "10px",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      color: "red",
      backgroundColor: "green",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      backgroundColor: "violet",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));

export function Catagory({ loading, categoryList, handleCatagoryChange }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [categoryName, setCategoryName] = useState(
    values.category ? values.category : "All Categories"
  );
  let name = categoryName;
  const [opens, setOpens] = React.useState(
    categoryList ? categoryList.map(() => false) : []
  );

  const handleClick1 = (index) => () => {
    let newopens = opens.map((item, ind) => (index === ind ? item : false));
    if (index !== -1) {
      newopens[index] = !newopens[index];
    } else {
      setAnchorEl(null);
      handleCatagoryChange({
        name: "All categories",
        slug: "all",
        url: "all",
        id: 0,
      });
      setCategoryName("All");
    }
    setOpens(newopens);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (prop) => {
    setAnchorEl(null);
    handleCatagoryChange(prop);
    setCategoryName(prop.name);
  };

  return (
    <Box className={classes.root} sx={{}}>
      <Box onClick={handleClick} className={classes.catBtn} sx={{}}>
        <Typography
          className={classes.desk}
          sx={{
            height: "100%",
            width: 150,
            fontSize: "14px",
            textAlign: "center",
            color: "primary.main",
          }}
        >
          {t(name.charAt(0).toUpperCase() + name.slice(1)).replace(/-/g, " ")}
        </Typography>

        <ExpandMore fontSize="small" sx={{ color: "primary.main" }} />
      </Box>
      {loading ? (
        <Box sx={{ height: 12 }}>
          <BasicCircularLoading />
        </Box>
      ) : (
        <MenuPopover
          getcontentanchorel={null}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ width: "280px" }}
        >
          <List
            sx={{ p: 2, width: "100%", maxWidth: 280 }}
            className={classes.list}
          >
            <ListItemButton
              sx={{ px: 1.3, py: 0.5 }}
              onClick={handleClick1(-1)}
            >
              <ListItemIcon>
                <Icon icon="carbon:search-locate" width="25px" height="15px" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">{t("All Categories")}</Typography>
              </ListItemText>
            </ListItemButton>
            {categoryList !== undefined
              ? categoryList.map((item, index) => (
                  <Fragment key={index}>
                    <ListItemButton
                      key={"li-" + index}
                      sx={{ px: 1.3, py: 0.5 }}
                      onClick={handleClick1(index)}
                    >
                      <ListItemIcon>
                        <img
                          alt=""
                          width="25px"
                          height="15px"
                          src={item.icon}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="body2">{t(item.name)}</Typography>
                      </ListItemText>
                      {opens[index] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={opens[index]} timeout="auto" unmountOnExit>
                      <List component="div">
                        <ListItemButton
                          sx={{ p: 0.3, pl: 4 }}
                          onClick={() => handleChange(item)}
                        >
                          <ListItemIcon>
                            <img
                              alt=""
                              width="25px"
                              height="15px"
                              src={item.icon}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography variant="body2">
                              {t(`All of ${item.name}`)}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                        {item.children &&
                          item.children.map((subitem, subInd) => (
                            <ListItemButton
                              sx={{ p: 0.3, pl: 4 }}
                              onClick={() => handleChange(subitem)}
                              key={"sub-li-" + subInd}
                            >
                              <ListItemIcon>
                                <img
                                  alt=""
                                  width="25px"
                                  height="15px"
                                  src={subitem.icon}
                                />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography variant="body2">
                                  {t(subitem.name)}
                                </Typography>
                              </ListItemText>
                            </ListItemButton>
                          ))}
                      </List>
                    </Collapse>
                  </Fragment>
                ))
              : null}
          </List>
        </MenuPopover>
      )}
    </Box>
  );
}
