import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAsh6DjHcLctp1LrCj1kpBnKPwvkosmfBI",
  authDomain: "fs-project-1-346910.firebaseapp.com",
  projectId: "fs-project-1-346910",
  storageBucket: "fs-project-1-346910.appspot.com",
  messagingSenderId: "455942813291",
  appId: "1:455942813291:web:53edcdf939b300d2f1e32e",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
