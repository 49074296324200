import { Box } from "@mui/material";

export default function CenterContainer({ children, sx }) {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 1200,
        margin: "0px auto",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
