import { SET_LOCATION, GET_LOCATION } from "../actions/types";

const initialState = {
  location: {
    id: 0,
    name: "Whole Ethiopia",
    count: 0,
  },
};

const location = function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LOCATION:
    case SET_LOCATION:
      return {
        ...state,
        location: payload,
      };
    default:
      return state;
  }
};

export default location;
