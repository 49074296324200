import { Icon } from "@iconify/react";
import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import NoLandscape from "../../../../config/images/landscape_no.png";
import OkLandscape from "../../../../config/images/landscape_yes.png";
import NoPortrait from "../../../../config/images/portrait_no.png";
import OkPortrait from "../../../../config/images/portrait_yes.png";

export default function TipOnScrollDialog() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button
        size="small"
        onClick={handleClickOpen()}
        sx={{ mb: 1, color: "text.secondary" }}
      >
        <Box display="flex" mr={0.5}>
          <Icon width={16} height={16} icon="ep:info-filled" />
        </Box>
        {t("Photo shooting tips")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ m: 0 }}
      >
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: 900, color: "#00000099" }}
            >
              {t("While taking pictures make sure that")}:
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "13px", lineHeight: 1.4, my: 1 }}
            >
              * {t("The object is in the middle of the screen.")} <br />*{" "}
              {t("There is no other objects near your target object.")}
              <br />*
              <Trans i18nKey="image.helperText3">
                You choose <b>Landscape</b> mode for wider objects and &nbsp;
                <b>Portrait</b> mode for longer objects.
              </Trans>
            </Typography>
            <Grid container>
              <Grid item sm={6} bgcolor="#d7a85290">
                <img src={OkLandscape} />
              </Grid>
              <Grid item sm={6} bgcolor="#dd77aa90">
                <img src={NoLandscape} />
              </Grid>
              <Grid item sm={6} bgcolor="#d7a85290">
                <img src={OkPortrait} />
              </Grid>
              <Grid item sm={6} bgcolor="#dd77aa90">
                <img src={NoPortrait} />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
