import { api } from "./config";

export const get_my_subscriptions = async (page) => {
  const { data } = await api.get(`/api/payment/subscription/?page=${page}`);
  return data;
};

export const get_subscriptions_list = async (slug) => {
  if(!slug) return ;
  const { data } = await api.get(`/api/payment/subscription/plan/?category=${slug}`);
  return data;
};
