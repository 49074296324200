export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";
export const FACEBOOK_AUTH_SUCCESS = "FACEBOOK_AUTH_SUCCESS";
export const FACEBOOK_AUTH_FAIL = "FACEBOOK_AUTH_FAIL";
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";
export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";
export const SET_LOCATION = "SET_LOCATION";
export const GET_LOCATION = "GET_LOCATION";
export const OPEN_LOGIN_DIALOG = "OPEN_LOGIN_DIALOG";
export const CLOSE_LOGIN_DIALOG = "CLOSE_LOGIN_DIALOG";
export const SET_DIALOG_CLOSING_ACTION = "SET_DIALOG_CLOSING_ACTION";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_CHAT_NOTIFICATIONS = "SET_CHAT_NOTIFICATIONS";
export const LOGOUT = "LOGOUT";
