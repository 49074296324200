import PropTypes from "prop-types";
import React from "react";
import ReactQuill from "react-quill";
import "./quill.css";

Editor.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  simple: PropTypes.bool,
  sx: PropTypes.object,
  placeholder: PropTypes.string,
};

function Editor({
  id,
  value,
  onChange,
  error,
  helperText,
  simple,
  placeholder,
  sx,
  ...other
}) {
  const quillRef = React.useRef(null);
  return (
    <div className="text-editor">
      <ReactQuill
        id={id}
        modules={Editor.modules}
        formats={Editor.formats}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        ref={quillRef}
      />
    </div>
  );
}

export default Editor;

Editor.modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ["bold", "italic", "underline", "strike"], // toggled buttons // custom button values

    [{ list: "ordered" }, { list: "bullet" }],

    [{ direction: "rtl" }, { align: [] }], // text direction

    // ["link", "image"],
    ["clean"], // remove formatting button
  ],

  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];
