import arrowRight from "@iconify/icons-eva/arrow-forward-outline";
import { Icon } from "@iconify/react";
import {
  Box,
  Divider,
  Link,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useThirdLevelCatagories } from "../../hooks/catagory";
import CenterContainer from "../Container/CenterContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItmes: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10.4),
    color: "white",
    position: "sticky",
    width: "100%",
    top: 0,
    zIndex: 100,
    overflowY: "hidden",
    flexWrap: "nowrap",
    overflowX: "auto",
    whiteSpace: "nowrap",
    borderRadius: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0.6),
      paddingRight: theme.spacing(0.6),
    },
  },
  tabContainer: {
    position: "relative",
    boxShadow: "none",
  },
  tabContainerSticky: {
    position: "fixed",
    top: 0,
    zIndex: 100,
  },
  popover: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    cursor: "pointer",
    textDecoration: "none",
  },
}));

export default function CategoryNav() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [cat, setCat] = useState({ category: "", subCategory: "" });
  const [childrenData, setChildrenData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const { isSuccess, data: CategoryLists } = useThirdLevelCatagories();

  const handleOpen = (event, item) => {
    setCat({ ...cat, category: item.slug });
    if (item.children) {
      setSubCategoryData(item.children);

      if (item.children[0].children) {
        setChildrenData(item.children[0].children);
        setSelectedName(item.children[0].name);
      } else {
        setChildrenData([]);
      }
    }
  };

  const handleClose = () => {};

  const handleHoverCategory = (childData, name, slug) => {
    setCat({ ...cat, subCategory: slug });
    setSelectedName(name);
    if (childData) {
      setChildrenData(childData);
    } else {
      setChildrenData([]);
    }
  };

  return (
    <Box
      sx={{
        borderBottom: "1px solid",
        borderColor: "divider",
        display: {xs:"none",md:"flex"},
        justifyContent: "center ",
        bgcolor: "background.paper",
      }}
    >
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <CenterContainer>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%", height: "52px" }}
            >
              {isSuccess &&
                CategoryLists.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      "&:hover": {
                        borderBottom: "3px solid",
                        borderColor: "secondary.main",
                      },
                    }}
                    className={classes.popover}
                    {...bindHover(popupState)}
                    onMouseEnter={(event) => handleOpen(event, item)}
                  >
                    <Link
                      component={RouterLink}
                      to={`/category/${item.slug}/`}
                      color="textPrimary"
                      underline="none"
                      sx={{
                        fontSize: "14px",
                        lineHeight: 1.43,
                      }}
                    >
                      {t(item.name)}
                    </Link>
                  </Box>
                ))}
            </Stack>
            <HoverPopover
              transitionDuration={100}
              marginThreshold={20}
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  borderRadius: 0,
                },
              }}
            >
              <Stack
                justifyContent="start"
                direction="row"
                sx={{
                  width: "600px",
                }}
              >
                <Paper
                  sx={{
                    maxHeight: "70vh",
                    minWidth: 250,
                    overflow: "auto",
                    py: 1.3,
                    mb: 1.5,
                    mr: 0,
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    borderRadius: "0px !important",
                    bgcolor: "background.paper",
                  }}
                >
                  {subCategoryData &&
                    subCategoryData.map((inner) => (
                      <MenuItem
                        key={inner.id}
                        to={`/category/${cat.category}/${cat.subCategory}`}
                        replace
                        component={RouterLink}
                        onClick={handleClose}
                        sx={{
                          fontSize: "14px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        onMouseEnter={() =>
                          handleHoverCategory(
                            inner.children,
                            inner.name,
                            inner.slug
                          )
                        }
                      >
                        {t(inner.name)}
                      </MenuItem>
                    ))}
                </Paper>

                {childrenData.length > 0 && (
                  <Paper
                    sx={{
                      backgroundColor: "#f6f7f8",
                      borderLeft: "1px solid",
                      borderColor: "divider",
                      bgcolor: "paper.light",
                      borderLeft: "1px solid",
                      maxHeight: "80vh",
                      height: "70vh",
                      overflow: "auto",
                      minWidth: 340,
                      borderBottomLeftRadius: 0,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderTopRightRadius: 0,
                      ml: 0,
                      mb: 1.5,
                      mr: 1.5,
                      borderRadius: "0px !important",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ py: 2, px: 1 }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        {t(selectedName)}
                      </Typography>
                      <Link
                        component={RouterLink}
                        to={`/category/${cat.category}/${cat.subCategory}`}
                        onClick={handleClose}
                        sx={{ textDecoration: "none" }}
                      >
                        <Stack
                          direction="row"
                          sx={{
                            color: (theme) => theme.palette.secondary.main,
                            fontSize: 16,
                            fontWeight: 600,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ fontSize: "15px" }}>{t("View All")}</Box>
                          <Box
                            component={Icon}
                            icon={arrowRight}
                            sx={{
                              "&:hover": {
                                transform: "translateX(10px)",
                              },
                            }}
                          />
                        </Stack>
                      </Link>
                    </Stack>
                    <Box mb={2}>
                      <Divider />
                    </Box>

                    {childrenData &&
                      childrenData.map((inner) => (
                        <MenuItem
                          key={inner.id}
                          to={`/category/${cat.category}/${inner.slug}`}
                          replace
                          component={RouterLink}
                          onClick={handleClose}
                          sx={{ fontSize: "14px" }}
                        >
                          {t(inner.name)}
                        </MenuItem>
                      ))}
                  </Paper>
                )}
              </Stack>
            </HoverPopover>
          </CenterContainer>
        )}
      </PopupState>
    </Box>
  );
}
