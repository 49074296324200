import { Icon as Iconify } from "@iconify/react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Avatar, Badge, Box, Grid, Link, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import salary from "../../config/salary.js";
import { fNumber } from "../../utils/formatNumber";
import { fDate } from "../../utils/formatTime";
import CustomTooltip from "../CustomTooltip.js";
import Label from "../Label.js";
import Favorite from "../products1/Favorite";

export const DescriptionStyle = styled(Box)(({ theme }) => ({
  fontFamily: '"Poppins", sans-serif',
  lineHeight: 1.25556,
  fontSize: "0.8rem",
  color: "#828282",
  marginRight: "20px",
  marginTop: "10px",
  textDecoration: "none",
  cursor: "pointer",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
}));

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    boxShadow: "rgba(4, 17, 29, 0.25) 0px 0px 8px 0px",
    borderRadius: 5,
    padding: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      borderRadius: 1,
    },
    "&:hover": { boxShadow: "rgb(145 158 171 / 24%) -24px 24px 72px -8px" },
  },
}));

function AdCardForJobs({ isLoading, isSuccess, data, filter_category }) {
  const Icon_INFO = [
    data.price > 0
      ? { value: `ETB ${fNumber(data.price)}`, icon: salary }
      : null,
    data.experience
      ? { value: `${data.experience} experience`, icon: "eva:bar-chart-2-fill" }
      : null,
    data.employment_type
      ? { value: data.employment_type, icon: "eva:person-outline" }
      : null,
    data.education
      ? { value: data.education, icon: "mdi:school-outline" }
      : null,
  ];
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.root}>
      <Link
        component={RouterLink}
        to={data ? `/detail/${data.id}` : ""}
        sx={{ textDecoration: "none", width: "100%" }}
      >
        <Grid container spacing={1}>
          <Grid item sm={2} md={2}>
            <CustomTooltip title={data.owner.name} placement="bottom">
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  data.owner.is_verified && (
                    <VerifiedIcon
                      sx={{
                        color: "blue",
                        backgroundColor: "#fff",
                        fontSize: "14px",
                        boxShadow: "0 0 0 1.5px #fff",
                        borderRadius: "50%",
                        padding: 0,
                      }}
                    />
                  )
                }
              >
                <Box
                  sx={{
                    p: 0.5,
                    border: 1,
                    borderColor: "grey.200",
                    borderRadius: "8px",
                  }}
                >
                  <Avatar
                    variant="rounded"
                    sx={{ width: 65, height: 60 }}
                    src={data.owner.logo}
                    alt={data.owner.name}
                  />
                </Box>
              </Badge>
            </CustomTooltip>
            <Box
              sx={{
                width: 34,
                height: 34,
                background: "#f7f7f7",
                ml: 2,
                mt: 2,
                borderRadius: "50%",
              }}
              aria-label="favorite"
            >
              <Favorite
                id={data.id}
                css={{
                  fontSize: "24px",
                  color: (theme) => theme.palette.primary.main,
                }}
              />
            </Box>
          </Grid>
          <Grid item sm={10} md={10}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Stack direction="row">
                <Typography
                  component="div"
                  variant="h5"
                  sx={{
                    width: "450px",
                    fontFamily: '"Poppins", sans-serif',
                    lineHeight: 1.3,
                    fontSize: "1.1rem",
                    textDecoration: "none",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {data.title}
                </Typography>
                {data.is_featured ? (
                  <Label variant="filled" color="secondary" sx={{}}>
                    {t("Featured")}
                  </Label>
                ) : (
                  <Label sx={{ bgcolor: "transparent" }} />
                )}
              </Stack>

              <Grid
                container
                mt="10px"
                sx={{ "& > :not(style)": { marginY: "5px" } }}
              >
                {Icon_INFO.map(
                  (info, index) =>
                    info && (
                      <Grid item xs={6} key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component={Iconify}
                            icon={info.icon}
                            sx={{
                              width: 20,
                              height: 20,
                              mr: 1,
                              color: "grey.500",
                            }}
                          />
                          <Box
                            sx={{
                              whiteSpace: "nowrap",
                              lineHeight: 1.757,
                              fontSize: "0.875rem",
                              fontFamily: '"Poppins",sans-serif',
                              fontWeight: 400,
                            }}
                          >
                            {t(info.value)}
                          </Box>
                        </Box>
                      </Grid>
                    )
                )}
              </Grid>
              <DescriptionStyle>{data.description}</DescriptionStyle>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  alignItems: "center",
                  "& > :not(style)": {
                    whiteSpace: "nowrap",
                    margin: "0px",
                    lineHeight: "1.96667",
                    fontSize: "0.85rem",
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 400,
                    color: "rgb(145, 158, 171)",
                  },
                  marginTop: "10px",
                }}
              >
                <Box
                  component={Typography}
                  variant="caption"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  <LocationOnIcon fontSize="inherit" sx={{ mr: 0.5 }} />
                  <Box component="span">
                    {data.location
                      .split(", ")
                      .map((x, i) => (i > 0 ? ", " : "") + t(x))}
                  </Box>
                </Box>
                <Box component="span">
                  <Box component="span" mr={0.2}>
                    {t("Date posted")}:
                  </Box>{" "}
                  {fDate(data.release_date)}
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Link>
    </Card>
  );
}

export default AdCardForJobs;
