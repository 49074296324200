import { Box, Button, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as errorIcon } from "../config/vectors/error.svg";

export default function ErrorFallbackPage() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: "90vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SvgIcon
          sx={{
            fontSize: "98px",
            height: "65px",
            color: "primary.main",
            mb: 1,
          }}
          component={errorIcon}
          inheritViewBox
        />

        <Typography sx={{ mb: 1 }} variant="h2" color="primary">
          {t("Oooopps!")}
        </Typography>
        <Typography className="number" sx={{ color: "grey.200", mb: 1 }}>
          {t("Something went wrong")}
        </Typography>
        <Button component={RouterLink} to="/">
          {t("Go to Home Page")}
        </Button>
      </Box>
    </Box>
  );
}
