import { useQuery, useInfiniteQuery } from "react-query";

import { search_ad, search_autocomplete, get_categories } from "../apis";

export const useSearchAd = (searchQuery) => {
  return useInfiniteQuery(["search_ad", searchQuery], search_ad, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next === null ? undefined : lastPage.next;
    },
    // enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useSearchAutoComplete = (payload) => {
  return useQuery(
    ["search_autocomplete", payload],
    () => search_autocomplete(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
    }
  );
};

export const useGetCatagories = () => {
  return useQuery("get_catagories", () => get_categories());
};
