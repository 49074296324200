import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../utils/validation";
import Alert from "../Alert/Alert";
import { CustomizedButton } from "../Button/Button";
import { message } from "./Constants";
import { checkEmpty, validPhone } from "./ValidateField";

export const urlGenerator = (id) => {
  let arr = [];
  ["", "alpha", "numeric"].forEach((type) => {
    const length = Math.floor(Math.random() * 8) + 1;
    let characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    if (type === "alpha") characters = characters.slice(0, 26);
    else if (type === "numeric") characters = characters.slice(26, 36);
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    arr.push(result);
  });
  arr = arr.sort();
  if (Boolean(Number(arr[1]))) return [arr[0], id, arr[1], arr[2]].join("-");
  else return [arr[0], arr[1], id, arr[2]].join("-");
};

const Activation = ({ mutate, id, email, resendMutate }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [notify, setNotify] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ user_id: id, key: "" });
  const [submitted, setSubmitted] = useState(false);

  const handleBlure = (e) => {
    if (e.target.value === "")
      setErrors((prev) => ({ ...prev, [e.target.name]: message.VALUE_ERROR }));
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setValues((prev) => ({ ...prev, [e.target.name]: value }));
      setErrors((prev) => ({ ...prev, [e.target.name]: null }));
    }
  };

  const resendActivation = async () => {
    setValues((prev) => ({ ...prev, key: null }));
    setErrors((prev) => ({ ...prev, key: null }));
    resendMutate.mutate({ email: email });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = checkEmpty(e);
    setErrors({ ...error, detail: null, success: null });
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted && isEmpty(errors)) mutate.mutate(values);
    setSubmitted(false);
  }, [submitted]);

  useEffect(() => {
    if (mutate.isSuccess) {
      navigate(`/marketing-info/${urlGenerator(id)}`);
    }
    if (mutate.isError) {
      console.debug(mutate.error);
      setErrors(mutate.error);
      if (mutate.error.detail) setNotify(true);
      resendMutate.reset();
    }
    if (resendMutate.error) {
      setErrors(resendMutate.error);
      if (resendMutate.error.detail) setNotify(true);
      mutate.reset();
    }
  }, [mutate.isError, mutate.isSuccess, resendMutate.isError]);

  useEffect(() => {
    if (resendMutate.isError || resendMutate.isSuccess) resendMutate.reset();
  }, [resendMutate]);

  return (
    <Box
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "480px",

        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <div id="recaptcha-container"></div>
        <Typography
          sx={{
            margin: "0px 0px 16px",
            lineHeight: "1.5",
            lineHeigh: 0,
            fontWeight: 400,
            fontSize: "2.2rem",
          }}
        >
          {email}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 400,
            color: "rgb(99, 115, 129)",
          }}
        >
          {t("We have sent activation code to your")}{" "}
          {validPhone(email) ? t("phone number.") : t("email address.")}
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            autoComplete="off"
            name="key"
            color="secondary"
            label={t("Activation code")}
            variant="outlined"
            placeholder={t("activation code")}
            value={values.key ? values.key : ""}
            onChange={handleChange}
            onBlur={handleBlure}
            error={Boolean(errors.key)}
            helperText={t(errors.key)}
            inputProps={{ maxLength: 6 }}
            sx={{ mt: 4, mb: 4 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit" color="secondary">
                    <ArrowForwardSharpIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CustomizedButton color="secondary" type="submit" variant="contained">
            {t("Activate")}
          </CustomizedButton>
        </form>

        <Typography
          variant="body2"
          sx={{ justifyContent: "center", textAlign: "center", mt: 2 }}
        >
          {t("Didn't recieve code?")} &nbsp;
          <Link
            underline="none"
            variant="subtitle2"
            sx={{ color: "secondary.main", cursor: "pointer" }}
            onClick={resendActivation}
          >
            {t("Resend code")}
          </Link>
        </Typography>

        {errors.detail && (
          <Alert
            open={notify}
            setOpen={() => setNotify(!notify)}
            message={errors.detail}
            severity="error"
          />
        )}
        {errors.success && (
          <Alert
            open={notify}
            setOpen={() => setNotify(!notify)}
            message={errors.success}
            severity="success"
          />
        )}
      </Box>
    </Box>
  );
};

export default Activation;
