import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import Appbar from "../components/Appbar/Appbar";
import { Footer } from "../components/Footer";
import ErrorFallbackPage from "../pages/ErrorFallbackPage";

export default function SearchLayout() {
  return (
    <>
      <Appbar search={true} />
      <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
        <Outlet />
      </ErrorBoundary>
      <Footer />
    </>
  );
}
