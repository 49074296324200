import { useQuery } from "react-query";
import { get_Favorities, set_Favorities } from "../apis";

export const useGetFavorite = (isAuth) => {
  return useQuery(["Favorites", isAuth], () => get_Favorities(isAuth));
};

export const useSetFavorite = (id) => {
  return useQuery("set_favorite", (id) => set_Favorities(id));
};
