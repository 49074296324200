import { api } from "./config";

export const get_categories = async (payload) => {
  const response = await api.get(`/api/category/list/`, {});
  return response.data;
};
export const get_Main_Categories = async (payload) => {
  const response = await api.get(`/api/category/main/`);
  return response.data;
};

export const get_location_list = async (payload) => {
  const response = await api.get(`/api/auth/region/`, {});
  return response.data;
};
export const get_region_list = async (payload) => {
  const response = await api.get(`/api/auth/all-regions/`, {});
  return response.data;
};
export const get_image_range = async (id) => {
  const { data } = await api.get(`/api/config/image_range/${id}/`);
  return data;
};

export const get_product_price = async (id) => {
  const { data } = await api.get(`/api/config/product_price/${id}/`);
  return data;
};

export const get_thirdlevel_catagories = async (payload) => {
  const { data } = await api.get(`/api/category/thirdlevel/list/`);
  return data;
};
