import { useInfiniteQuery, useQuery } from "react-query";
import { RelatedProduct, getSimilarProductInfinite } from "../apis";

export const useRelatedProduct = (productId) => {
  return useQuery(
    ["similarProducts", productId],
    () => RelatedProduct(productId),
    {
      keepPreviousData: true,
    }
  );
};

export const useSimilarProductInfinite = (id) => {
  return useInfiniteQuery(
    ["getSimilarProductInfinte", id],
    getSimilarProductInfinite,
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next === null ? undefined : lastPage.next;
      },
    }
  );
};
