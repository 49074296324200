import { Icon } from "@iconify/react";
import { Badge, IconButton } from "@mui/material";
import { memo } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { set_notifications } from "../../../redux/actions/auth";
import withLoginRequired from "../withLoginRequired";

const ChatWithLogin = withLoginRequired(IconButton);

function ChatNotif({ chats }) {
  const navigate = useNavigate();
  const handleChatClick = () => {
    navigate("/chat");
  };
  return (
    <ChatWithLogin
      ref={null}
      sx={{ bgcolor: "inherit", color: "primary.main", ml: 1.5 }}
      onClick={handleChatClick}
    >
      <Badge badgeContent={chats.count} color="warning">
        <Icon
          icon="akar-icons:chat-dots"
          width={20}
          height={20}
          color="inherit"
        />
      </Badge>
    </ChatWithLogin>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  notifications: state.auth.notifications,
  chats: state.auth.chats,
});

export default connect(mapStateToProps, {
  set_notifications,
})(memo(ChatNotif));
