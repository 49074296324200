export const message = {
  SEND_SUCCESS: "Veryfication code sent successffuly.",
  VALUE_ERROR: "Please fill out this field.",
  KEY_LENGTH_ERROR: "Ensure this value has 6-digit number.",
  PHONE_ERROR: "Enter a valid phone number.",
  EMAIL_ERROR: "Enter a valid email address.",
  OTP_PIN_ERROR: "Ensure this value has 4-digit number.",
};

export const FirebaseError = (msg) => {
  if (msg === "Firebase: Error (auth/code-expired).")
    return "Veryfication code-expired";
  if (msg === "Firebase: Error (auth/invalid-verification-code).")
    return "Invalid Veryfication code";
  if (msg === "Firebase: Error (auth/too-many-requests).")
    return "Too-many-requests";

  return msg;
};
