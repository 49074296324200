import { api } from "./config";

export const getProductReview = async (id) => {
  const { data } = await api
    .get(`api/main/product/review/${id}/`)
    .catch((err) => console.debug(err.response));
  return data;
};

export const getProductStatus = async (id) => {
  const { data } = await api
    .get(`api/main/product-status/?id=${id}`)
    .catch((err) => console.debug(err.response));
  return data;
};

export const getMyFSPointAndSubscription = async (body) => {
  const { data } = await api
    .post(`api/payment/product-payment/available-options/`, body)
    .catch((err) => console.debug(err.response));
  return data;
};

export const productPaymentWithFamilysooqpoint = async (body) => {
  const { data } = await api
    .post(`api/payment/product-payment/pay-with-familysooqpoint/`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};

export const productPaymentWithTelebirr = async (body) => {
  const { data } = await api
    .post(`api/payment/product-payment/pay-with-telebirr/`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};

export const productPaymentWithSubscription = async (body) => {
  const { data } = await api
    .post(`api/payment/product-payment/pay-with-subscription/`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};

export const getMyFSPoint = async () => {
  const { data } = await api.get(`api/payment/familysooq-point/`);
  return data;
};

export const FSPointPaymentWithTelebirr = async (body) => {
  const { data } = await api
    .post(`api/payment/familysooq-point/pay-with-telebirr/`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};

export const SubscriptionPaymentWithTelebirr = async (body) => {
  const { data } = await api
    .post(`api/payment/subscription/pay-with-telebirr/`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};

export const SubscriptionPaymentWithFamilysooqpoint = async (body) => {
  const { data } = await api
    .post(`api/payment/subscription/pay-with-familysooq-point/`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};

export const getSubscriptionAmount = async (id, type) => {
  if (type === "subscription") {
    const { data } = await api
      .get(`api/payment/subscription/plan/${id}/`)
      .catch((err) => console.debug(err.response));
    return data;
  }
};

export const getAmoleOTP = async (body) => {
  const { data } = await api
    .post(`api/payment/amole/otp-send/`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};

export const productPaymentWithAmole = async (body) => {
  const { data } = await api
    .post(`api/payment/product-payment/pay-with-amole/`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};

export const FSPointPaymentWithAmole = async (body) => {
  const { data } = await api
    .post(`api/payment/familysooq-point/pay-with-amole/`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};

export const SubscriptionPaymentWithAmole = async (body) => {
  const { data } = await api
    .post(`api/payment/subscription/pay-with-amole/`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};
