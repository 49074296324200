import { Box, Grid, Stack, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CenterContainer from "../../components/Container/CenterContainer";
import FilterSidebar from "../../components/FilterSidebar";
import Page from "../../components/Page";
import ProductsSort from "../../components/products1/ProductsSort";
import { SORT_OPTIONS } from "../../config/settings";
import { useSearchAd } from "../../hooks";
import ResultOverview from "./ResultOverview";
import SearchResults from "./SearchResults";

export default function Index() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const [fil, setFil] = useState({});
  const [sortBy, setSortBy] = useState("featured");
  const [productResults, setProductResults] = useState([]);
  const [noResult, setNoResult] = useState(false);
  const [filterUrl, setFilterUrl] = useState("");
  const [, setQuery] = useState("");
  const [overViewData, setOverViewData] = useState(null);
  const [cleanValuesFil, setCleanValuesFil] = useState({});
  const { isLoading, fetchNextPage, hasNextPage, data, isSuccess } =
    useSearchAd({
      values: values,
      fil: cleanValuesFil,
    });

  useEffect(() => {
    if (isLoading) setNoResult(false);
    if (data) {
      const temp = [];
      data.pages.forEach((element) => {
        element.results.forEach((item) => {
          temp.push(item);
        });
      });
      setProductResults(temp);
      setNoResult(temp.length === 0);
    } else if (!isLoading) setNoResult(true);
  }, [data, isLoading]);

  useEffect(() => {
    let parame = {};
    let item;

    for (item in fil) {
      if (fil[item] !== "All" && fil[item] !== "all" && fil[item]) {
        parame[item] = fil[item];
      }
    }

    setCleanValuesFil(parame);
  }, [fil]);

  useEffect(() => {
    if (data && data.pages) {
      if (data.pages[0].filter_category !== filterUrl) {
        setFilterUrl(data.pages[0].filter_category);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data && !Boolean(values.fil)) {
      setOverViewData(data.pages[0]);
      setQuery(data.pages[0].query);
    }
  }, [data]);

  const handleSort = (event) => {
    setSortBy(event);
    setFil({
      ...cleanValuesFil,
      order_by: event,
    });
  };

  return (
    <Page title="search">
      <ResultOverview isLoading={isLoading} data={overViewData} />

      <Box display="flex" justifyContent="center">
        <CenterContainer>
          <Grid container sx={{ mx: "auto" }}>
            <Grid item md={3}>
              <FilterSidebar url={filterUrl} setValues={setFil} />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Box pl={1.5}>
                <Box
                  sx={{
                    mb: 3,
                    mx: 1,
                    borderColor: "background.default",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {t("Sort By")}&nbsp;
                    </Typography>
                    <ProductsSort
                      options={SORT_OPTIONS}
                      defaultValue="recommendation"
                      value={sortBy}
                      onSort={(e) => handleSort(e)}
                    />
                  </Stack>
                </Box>
                <Box
                  sx={{
                    overflow: "visible",
                  }}
                >
                  <SearchResults
                    noResult={noResult}
                    data={data}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isLoading={isLoading}
                    filter_category={data && data.pages[0].filter_category}
                    isSuccess={isSuccess}
                    dataLength={productResults.length}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CenterContainer>
      </Box>
    </Page>
  );
}
