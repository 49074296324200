import { useQuery } from "react-query";
import { get_my_subscriptions, get_subscriptions_list } from "../apis";



export const useMySubscription = (page) => {
    return useQuery(
      ["my subscriptions", page],
      () => get_my_subscriptions(page),
      { refetchOnWindowFocus: false }
    );
  };

  export const useGetSubscriptionList = (slug) => {
    return useQuery(
      ["my subscriptions", slug],
      () => get_subscriptions_list(slug),
      { refetchOnWindowFocus: false }
    );
  };