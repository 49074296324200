import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const GREY_DARK = {
  0: "#00000",
  100: "#303134",
  200: "#3D3E41",
  300: "#4A4B4E",
  400: "#56575A",
  500: "#636467",
  600: "#707174",
  700: "#7D7E81",
  800: "#898A8D",
  900: "#96979A",
  500_8: alpha("#636467", 0.08),
  500_12: alpha("#636467", 0.12),
  500_16: alpha("#636467", 0.16),
  500_24: alpha("#636467", 0.24),
  500_32: alpha("#636467", 0.32),
  500_48: alpha("#636467", 0.48),
  500_56: alpha("#636467", 0.56),
  500_80: alpha("#636467", 0.8),
};

const PRIMARY = {
  lightest: "#D4D6FF",
  lighter: "#5959DB",
  light: "#020288",
  // main: "#020288",
  main: "rgb(33, 35, 83)",
  dark: "#010174",
  darker: "#010161",
  contrastText: "#fff",
  icon: "rgb(105, 103, 102)",
};

const SECONDARY = {
  lightest: "#FFFFA5",
  lighter: "#FFD24D",
  light: "#FFAB26",
  main: "#FB8500",
  dark: "#EE26C00",
  darker: "#BB4500",
  contrastText: "#fff",
  icon: "#fff",
};

const PRIMARY_DARK = {
  lightest: "#FFFFA5",
  lighter: "#FFD24D",
  light: "#FFFFFF",
  // main: "#FB8500",
  main: "#FFFFFF",
  dark: "#EE26C00",
  darker: "#BB4500",
  contrastText: "#fff",
  icon: "#fff",
};

const SECONDARY_DARK = {
  lightest: "#303134",
  lighter: "#FFD24D",
  light: "#FFAB26",
  main: "#FB8500",
  dark: "#EE26C00",
  darker: "#BB4500",
  contrastText: "#fff",
};

const PAPER = {
  dark: "#17181B",
  main: "#303134",
  // light: "#ffffff",
  light: "#f6f7f8",
};

const PAPER_DARK = {
  dark: "#f6f7f8",
  main: "#fff",
  light: "#4A4B4E",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};
const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};
const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};
const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const TEXT = {
  primary: "#000",
  // secondary: GREY[600],
  // primary: "rgb(54, 52, 51)",

  secondary: "rgb(105, 103, 102)",
  // ternary: "#333333",
  ternary: "#787c82",
  // grey: "#787c82",
  disabled: GREY[500],
};

const TEXT_DARK = {
  primary: "#fff",
  secondary: "rgba(255, 255, 255, 0.7)",
  ternary: "#ffffff",
  // grey: "rgba(255, 255, 255, 0.7)",
  // secondary: "#fff",
  disabled: "rgba(255, 255, 255, 0.5)",
};
const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const getDesignTokens = (mode) => ({
  mode,
  common: { black: "#000", white: "#fff" },
  primary: mode === "light" ? { ...PRIMARY } : { ...PRIMARY_DARK },
  secondary: mode === "light" ? { ...SECONDARY } : { ...SECONDARY_DARK },
  paper: mode === "light" ? { ...PAPER } : { ...PAPER_DARK },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: mode === "dark" ? { ...GREY } : { ...GREY_DARK },
  rev_grey: mode === "light" ? { ...GREY } : { ...GREY_DARK },
  gradients: GRADIENTS,
  divider: GREY[500_24],
  text: mode === "light" ? { ...TEXT } : { ...TEXT_DARK },
  background:
    mode === "light"
      ? {
          paper: "#fff",
          default: "#eef2f4",
          neutral: GREY[200],
        }
      : {
          paper: "#303134",
          default: "#1a1a1a",
          neutral: GREY[200],
        },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
});
export default getDesignTokens;
