import { useQuery, useInfiniteQuery, useMutation } from "react-query";

import {
  get_notification_list,
  get_unread_notification_count,
  mark_notification_as_read,
  remove_notification,
} from "../apis";

// export const useGetNotificationList = () => {
//   return useQuery("get_notification_list", () => get_notification_list(), {
//     refetchOnWindowFocus: false,
//   });
// };

export const useGetNotificationList = () => {
  return useInfiniteQuery(["get_notification_list"], get_notification_list, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next === null ? undefined : lastPage.next;
    },
    // enabled: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetUnReadNotificationCount = () => {
  return useQuery(
    "get_unread_notifications_count",
    () => get_unread_notification_count(),
    {
      refetchOnWindowFocus: true,
    }
  );
};

export const useMarkNotificationAsRead = () => {
  return useMutation((payload) => mark_notification_as_read(payload));
};

export const useRemoveNotification = () => {
  return useMutation((payload) => remove_notification(payload));
};
