import { useQuery } from "react-query";
import { ProductFilter } from "../apis";

export const useProductFilter = (payload, sort, page) => {
  return useQuery(
    ["ProductFilter", payload, sort, page],
    () => ProductFilter(payload, sort, page),
    {
      keepPreviousData: false,
    }
  );
};
