import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import NavSection from "./NavSection";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
  },
}));

FilterSidebar.propTypes = {};

export default function FilterSidebar({
  url,

  setValues,
}) {
  const renderContent = (
    <Box>
      <NavSection url={url} setValues={setValues} />
    </Box>
  );

  return <RootStyle>{renderContent}</RootStyle>;
}
