import * as yup from "yup";

export const validation = yup.object({
  fullname: yup
    .string("Enter your Full name")
    .min(5, "Full name should be of minimum 5 characters lenght")
    .required("Full name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export function isEmpty(obj) {
  for (let prop of Object.values(obj)) if (prop) return false;
  return true;
}
