// material
import { CssBaseline, useMediaQuery } from "@mui/material";
import {
  createTheme,
  StyledEngineProvider, ThemeProvider
} from "@mui/material/styles";
import PropTypes from "prop-types";
import { createContext, useMemo, useState } from "react";
import componentsOverride from "./overrides";
import palette from "./palette";
import { customShadows } from "./shadows";
//
import shape from "./shape";
import typography from "./typography";

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export const ColorModeContext = createContext({
  toggleColorMode: (prop) => {},
});

export default function ThemeConfig({ children }) {
  const [mode, setMode] = useState("light");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: (mode) => {
        setMode((prevMode) => mode);
      },
    }),
    []
  );
  const themeOptions = useMemo(
    () => ({
      palette: {
        ...palette(
          mode === "sys" ? (prefersDarkMode ? "dark" : "light") : mode
        ),
      },
      shape,
      typography,
      // shadows: { ...shadows("#fff") },
      customShadows: {
        ...customShadows("#fff"),
      },
    }),
    [mode, prefersDarkMode]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={{ mode: mode, setMode: colorMode }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
}
