import Button from "@mui/material/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import withLoginRequired from "../Feedback/withLoginRequired";

const PostButton = withLoginRequired(Button);
function PostAdButton({ isAuthenticated, navigate, sx, dispatch, ...other }) {
  const { t } = useTranslation();

  return (
    <PostButton
      sx={{ ml: 2.5, boxShadow: "none", ...sx }}
      color="secondary"
      variant="contained"
      {...other}
      onClick={() => navigate("/post-ad")}
    >
      {t("Post Your Ad")}
    </PostButton>
  );
}

export default PostAdButton;
