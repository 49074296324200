import { Box, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as noresult } from "../../config/vectors/filenotfound.svg";

export default function NoResultFound({ title, subTitle }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "40vh",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      <SvgIcon
        sx={{ fontSize: "98px", height: "85px" }}
        component={noresult}
        inheritViewBox
      />
      {title && (
        <Typography variant="h4" sx={{ mt: 2, color: "primary.main" }}>
          {t(title)}
        </Typography>
      )}
      {subTitle && (
        <Typography variant="caption" color="text.secondary">
          {t(subTitle)}
        </Typography>
      )}
    </Box>
  );
}
