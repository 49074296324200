import { useMutation, useQuery } from "react-query";
import {
  getAmoleOTP,
  getMyFSPoint,
  getProductReview,
  getProductStatus,
  productPaymentWithAmole,
  getMyFSPointAndSubscription,
  productPaymentWithFamilysooqpoint,
  productPaymentWithTelebirr,
  productPaymentWithSubscription,
  FSPointPaymentWithTelebirr,
  SubscriptionPaymentWithTelebirr,
  SubscriptionPaymentWithFamilysooqpoint,
  getSubscriptionAmount,
  FSPointPaymentWithAmole,
  SubscriptionPaymentWithAmole,
} from "../apis/Payment";

export const useGetProductReview = (id) => {
  return useQuery(["get_product_review", id], () => getProductReview(id));
};

export const useGetProductStatus = (id) => {
  return useQuery(["get_product_status", id], () => getProductStatus(id));
};

export const useGetMyFSPointAndSubscription = (body) => {
  return useQuery(["get_my_subscription"], () =>
    getMyFSPointAndSubscription(body)
  );
};

export const useProductPaymentWithFamilysooqpoint = () => {
  return useMutation((payload) => productPaymentWithFamilysooqpoint(payload));
};

export const useProductPaymentWithTelebirr = () => {
  return useMutation((payload) => productPaymentWithTelebirr(payload));
};

export const useProductPaymentWithSubscription = () => {
  return useMutation((payload) => productPaymentWithSubscription(payload));
};

export const useGetMyFSPoint = () => {
  return useQuery(["get_product_review"], () => getMyFSPoint());
};

export const useFSPointPaymentWithTelebirr = () => {
  return useMutation((payload) => FSPointPaymentWithTelebirr(payload));
};

export const useSubscriptionPaymentWithTelebirr = () => {
  return useMutation((payload) => SubscriptionPaymentWithTelebirr(payload));
};

export const useSubscriptionPaymentWithFamilysooqpoint = () => {
  return useMutation((payload) =>
    SubscriptionPaymentWithFamilysooqpoint(payload)
  );
};

export const useGetSubscriptionAmount = (id, type) => {
  return useQuery(["subscription_amount", id, type], () =>
    getSubscriptionAmount(id, type)
  );
};

export const useGetAmoleOTP = () => {
  return useMutation((payload) => getAmoleOTP(payload));
};

export const useProductPaymentWithAmole = () => {
  return useMutation((payload) => productPaymentWithAmole(payload));
};

export const useFSPointPaymentWithAmole = () => {
  return useMutation((payload) => FSPointPaymentWithAmole(payload));
};

export const useSubscriptionPaymentWithAmole = () => {
  return useMutation((payload) => SubscriptionPaymentWithAmole(payload));
};
