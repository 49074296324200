import React from "react";
import { Typography, Avatar, Chip, Grid } from "@mui/material";
import { fDateMonth } from "../../utils/formatTime";
import { useTranslation } from "react-i18next";

export default function ProductExtend({ data }) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} mb={1}>
      <Grid item xs={4}>
        {data.normal_status === "active" && (
          <>
            <Chip
              color="success"
              avatar={<Avatar>P</Avatar>}
              label={t("Active")}
            />
            <Typography variant="body2" color="text.secondary" m={`5px 0 0 0`}>
              {t("Expired on")} <b>{fDateMonth(data.normal_expired_date)}</b>
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={4}>
        {data.is_featured && (
          <>
            <Chip
              color="secondary"
              avatar={<Avatar>P</Avatar>}
              label={t("Featured")}
            />
            <Typography variant="body2" color="text.secondary" m={`5px 0 0 0`}>
              {t("Expired on")} <b>{fDateMonth(data.featured_expired_date)}</b>
            </Typography>
          </>
        )}
      </Grid>
      {data.is_promoted && (
        <Grid item xs={4}>
          <Chip
            color="primary"
            avatar={<Avatar>P</Avatar>}
            label={t("Promoted")}
          />
          <Typography variant="body2" color="text.secondary" m={`5px 0 0 0`}>
            {t("Expired on")} <b>{fDateMonth(data.promoted_expired_date)}</b>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
