import { Icon as Iconify } from "@iconify/react";
import {
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  Slide,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Catagory } from "../Catagory";
import CustomAutoComplete from "../Inputs/AutoComplete";
import useSearchBarHook from "./SearchBarHook";

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled("div")(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  height: APPBAR_MOBILE,
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha("#fff", 0.9)}`,
  [theme.breakpoints.up("md")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function SearchBarResponsiv(props) {
  const {
    adSearchQuery,
    adSearchCategory,
    handleCatagoryChange,
    categoryList,
    categoryIsLoading,
    onTextChange,
    onSearch,
    searchAutoCompleteIsLoading,
    searchAutoCompleteData,
    searchIsLoading,
  } = useSearchBarHook(props);
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" width={20} height={20} />
          </IconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Catagory
              category={adSearchCategory}
              categoryList={categoryList}
              loading={categoryIsLoading}
              handleCatagoryChange={handleCatagoryChange}
            />
            <Divider orientation="vertical" />

            <CustomAutoComplete
              placeholder="type something..."
              text={adSearchQuery}
              onTextChange={onTextChange}
              onSearch={onSearch}
              isLoading={searchAutoCompleteIsLoading}
              options={searchAutoCompleteData}
            />
            <Button variant="contained" onClick={onSearch}>
              {t("Search")}
            </Button>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
