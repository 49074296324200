import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Catagory } from "../Catagory";
import CustomAutoComplete from "../Inputs/AutoComplete";
import useSearchBarHook from "./SearchBarHook";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "45%",
    height: "44px",
    background: theme.palette.background.default,
  },
  innerBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    background: theme.palette.background.default,
    borderRadius: "25px",
    height: "100%",
  },
  innerRoot: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: theme.palette.background.default,
    borderRight: "none",
    borderRadius: "inherit",
  },
  input: { marginLeft: theme.spacing(1), flex: 1 },
  iconButton: {
    color: theme.palette.secondary.main,
    padding: "7px",
    "&.MuiIconButton-root": {
      borderRadius: "25px",
    },
    borderRadius: "25%",
  },
  divider: { height: 28, margin: 4 },
}));

export default function SearchBar2(props) {
  const classes = useStyles();

  const {
    adSearchQuery,
    adSearchCategory,
    handleCatagoryChange,
    categoryList,
    categoryIsLoading,
    onTextChange,
    onSearch,
    searchAutoCompleteIsLoading,
    searchAutoCompleteData,
    searchIsLoading,
  } = useSearchBarHook(props);

  return (
    <Paper className={classes.innerBox}>
      <Paper className={classes.innerRoot}>
        <Catagory
          category={adSearchCategory}
          categoryList={categoryList}
          loading={categoryIsLoading}
          handleCatagoryChange={handleCatagoryChange}
        />
        <Divider className={classes.divider} orientation="vertical" />

        <CustomAutoComplete
          placeholder="type something..."
          text={adSearchQuery}
          onTextChange={onTextChange}
          onSearch={onSearch}
          isLoading={searchAutoCompleteIsLoading}
          options={searchAutoCompleteData}
        />
      </Paper>
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={onSearch}
        disabled={searchIsLoading}
      >
        <SearchIcon color="secondary" />
      </IconButton>
    </Paper>
  );
}
