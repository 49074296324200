import queryString from "query-string";
import { api } from "./config";

export const productInfinite = async ({ queryKey, pageParam }) => {
  let page = 1;

  if (pageParam !== undefined) {
    // const val = queryString.parse(pageParam);
    const param = pageParam.split("?")[1];
    const val = queryString.parse(param);

    page = val["page"];

    // const values = pageParam.indexOf("page=");
    // page = pageParam.charAt(parseInt(values) + 5);
  }

  const response = await api.get(
    `api/main/product/home/list/?category=${queryKey[1]}&page=${page}&order_by=${queryKey[2]}`
  );
  return response.data;
};

export const showPhoneNumber = async (id) => {
  const response = await api.get(`api/main/view-phone/?id=${id}`, {
    id: id,
  });
  return response.data;
};

export const postAd = async ({ table, body }) => {
  const { data } = await api
    .post(`api/main/product/add/?table=${table}`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};

export const updateAd = async ({ id, body }) => {
  const { data } = await api
    .put(`api/main/product/edit/${id}/`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};

export const updateImage = async (body) => {
  const { data } = await api.post(`api/main/image/`, body).catch((e) => {
    if (e.response) throw e.response.data;
    throw Object.assign(new Error("Network Error"), {
      detail: "Network Error",
    });
  });
  return data;
};

export const getProduct = async (id) => {
  const { data } = await api.get(`api/main/product/edit/${id}/`);
  return data;
};

export const getUserProductList = async ({ queryKey, pageParam }) => {
  let page = 1;

  if (pageParam !== undefined) {
    const param = pageParam.split("?")[1];
    const val = queryString.parse(param);
    page = val["page"];
  }

  const fil = queryKey[1].fil !== undefined ? queryKey[1].fil : {};
  const params = {
    page: page,
    ...fil,
  };
  const response = await api.get(
    `api/main/product/${queryKey[1].type}/${queryKey[1].id}/list?order_by=${queryKey[1].order_by}`,
    { params: { ...params } }
  );
  return response.data;
};
export const countPendingProduct = async (id) => {
  const { data } = await api.get(`api/main/pending-product-count/`);
  return data;
};

export const listPendingProduct = async (id) => {
  const { data } = await api.get(`api/main/pending-product-list/`);
  return data;
};

export const retrievePendingProduct = async (id, slug) => {
  const { data } = await api.get(
    `api/main/product-retrieve-activate/${id}/?slug=${slug}`
  );
  return data;
};

export const activatePendingProduct = async ({ id, slug, body }) => {
  const { data } = await api
    .put(`api/main/product-retrieve-activate/${id}/?slug=${slug}`, body)
    .catch((e) => {
      if (e.response) throw e.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });
  return data;
};
