import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomizedTextField = styled((props) => <TextField {...props} />)(
  ({ theme }) => ({
    zIndex: 1,
    width: "100%",
    position: "relative",
    "& .MuiOutlinedInput-root": {
      background: "#ffffff9e",
      background: theme.palette.rev_grey_500,
      fontSize: "16px",
      lineHeight: "20px",
      borderRadius: "6px",
      "& fieldset": {},
      "&:hover fieldset": {},
      "&.Mui-focused fieldset": {},
    },
  })
);

export default CustomizedTextField;

export const SearchTextField = styled((props) => <TextField {...props} />)(
  ({ theme }) => ({
    zIndex: 1,
    width: "50%",
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      borderRadius: "6px",
    },
  })
);
