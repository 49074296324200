import { Fragment } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import Appbar from "../components/Appbar/Appbar";
import ErrorFallbackPage from "../pages/ErrorFallbackPage";

export default function Navbar() {
  return (
    <Fragment>
      <Appbar />
      <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
        <Outlet />
      </ErrorBoundary>
    </Fragment>
  );
}
