import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function Account() {
  const { t } = useTranslation();

  return (
    <div style={{ margin: "0px 15px" }}>
      <Box component="span" display="block">
        {t("Account and Listing")}
      </Box>
      <Box component="span" display="block">
        <Link to="/signin">
          <LoginButton>
            <Trans i18nKey="Login or Register">
              Login<span style={{ color: "#e68104f0" }}>or</span>Register
            </Trans>
          </LoginButton>
        </Link>
      </Box>
    </div>
  );
}

export const AdButton = styled(Button)({
  fontSize: "14px",
  color: "#fff",
  fontWeight: 400,
  textTransform: "uppercase",
  border: "1px solid #FB8500",
  borderRadius: "20px",
  lineHeight: "22px",
  transition: " all 0.3s ease",
  "&:hover": {
    color: "white",
    backgroundColor: "#FB8500",
    borderRadius: "20px",
  },
  "&:focus": {
    color: "white",
    borderRadius: "20px",
  },

  "&:active": {
    color: "white",
    borderRadius: "20px",
  },
});
export const LoginButton = styled(Button)({
  color: "white",

  borderRadius: "5px",
  textTransform: "none",
  padding: " 0px",
  fontSize: "14px",
});
export const LanguageButton = styled(Button)({
  color: "white",
  textTransform: "none",
  padding: "8px 10px",
  fontSize: "14px",
});
export const CatagoryButton = styled(Button)({
  color: "black",
  textTransform: "none",
});

export const CustomizedButton = styled((props) => <Button {...props} />)(
  () => ({
    fontSize: "0.9375rem",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    outline: "0px",
    border: "0px",
    margin: "0px",
    cursor: "pointer",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontWeight: 700,
    lineHeight: 1.71429,
    textTransform: "capitalize",
    minWidth: "64px",
    padding: "8px 22px",
    borderRadius: "8px",

    width: "100%",

    height: "48px",

    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  })
);
