import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useAuthenticateTG } from "../../hooks";
import { open_login_dialog } from "../../redux/actions/auth";

function TelegramAuth({ isAuthenticated, user, open_login_dialog }) {
  const { t } = useTranslation();
  const { tg_chat_id, tg_user_id } = useParams();
  const mutate = useAuthenticateTG();

  if (!isAuthenticated) {
    open_login_dialog(true);
  }
  if (!mutate.isLoading && mutate.isSuccess) {
    window.location.href = "https://telegram.me/familysooqbot";
  }

  const handleClick = () => {
    mutate.mutate({
      tg_chat_id: tg_chat_id,
      tg_user_id: tg_user_id,
      tg_name: "none",
      user: user.id,
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography mb={3} variant="h3">
        {t("Allow access to telegram")}
      </Typography>
      <Button color="success" variant="outlined" onClick={() => handleClick()}>
        {t("Confirm")}
      </Button>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  open_login_dialog,
})(TelegramAuth);
