import { Box, MenuItem, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTranslation } from "react-i18next";
import CustomizedTextField from "../TextField/TextField";

function RadioInput({ choice, handleChange, filterOption, selected }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: "1px solid lightgrey",
        pb: 1.9,
      }}
    >
      <FormControl component="fieldset">
        <RadioGroup
          aria-label={choice.name}
          name={choice.name}
          onChange={handleChange}
          value={
            selected[choice.name] === undefined ? "All" : selected[choice.name]
          }
        >
          <FormControlLabel
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 18,
                  },
                }}
              />
            }
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: 13,
              m: 0,
              height: "22px",
            }}
            value="All"
            label={
              <Typography color="text.secondary" fontSize="13px">
                {t("All")}
              </Typography>
            }
          />
          {filterOption[choice.key].children.map((item, ind) => (
            <FormControlLabel
              key={"rad-" + item.title + "-" + ind}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 18,
                    },
                  }}
                />
              }
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 13,
                m: 0,
                height: "22px",
              }}
              value={item.value}
              label={
                <Typography color="text.secondary" fontSize="13px">
                  {t(item.title)}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

function SelectInput({ choice, handleChange, filterOption, selected }) {
  const { t } = useTranslation();

  return (
    <Box px={1.2} sx={{ borderBottom: "1px dashed lightgrey" }}>
      <CustomizedTextField
        key={choice.key}
        required
        select
        name={choice.name}
        onChange={handleChange}
        size="small"
        value={selected[choice.name] ? selected[choice.name] : "All"}
        sx={{ mb: 1.7 }}
      >
        <MenuItem value="All"> {t("All")} </MenuItem>
        {filterOption[choice.key].children.map((item, ind) => (
          <MenuItem key={"sel-" + item.title + "-" + ind} value={item.value}>
            {t(item.title)}
          </MenuItem>
        ))}
      </CustomizedTextField>
    </Box>
  );
}

export default function FilterInput({
  choice,
  handleChange,
  filterOption,
  selected,
}) {
  const label = filterOption ? filterOption[choice.key].label : null;
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: "block",
          fontSize: "14px",
          mb: 0.1,
          mx: 1.2,
          mt: 1.2,
          color: "primary.light",
        }}
      >
        {t(label)}
      </Box>

      {filterOption[choice.key].children.length < 4 ? (
        <RadioInput
          choice={choice}
          handleChange={handleChange}
          filterOption={filterOption}
          selected={selected}
        />
      ) : (
        <SelectInput
          choice={choice}
          handleChange={handleChange}
          filterOption={filterOption}
          selected={selected}
        />
      )}
    </>
  );
}
