import {
  Box,
  Chip,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CenterContainer from "../../components/Container/CenterContainer";

export default function ResultOverview({ data, isLoading }) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [active, setActive] = useState([]);
  const navigate = useNavigate();
  const values = queryString.parse(search);

  useEffect(() => {
    if (data) {
      let temp = data.found_result.map((item, i) =>
        item.category_slug === values.category ? true : false
      );
      setActive(temp);
    }
  }, [search]);
  const handleClick = (slug, ind) => {
    navigate(`/search?query=${values.query}&category=${slug}&fil=true`);
  };
  return (
    <Paper sx={{ width: "100%", mb: 2, pt: 1 }}>
      <CenterContainer>
        <Grid container sx={{ width: "100%" }}>
          <Grid item md={10} sx={{ width: "100%" }}>
            {isLoading ? (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{ width: "100%", whiteSpace: "wrap" }}
                >
                  <Skeleton width={200} />
                </Typography>
                <Box sx={{ display: "flex", mb: 0.8 }}>
                  <Skeleton
                    sx={{ borderRadius: 20, mr: 1 }}
                    width={90}
                    height={40}
                  />
                  <Skeleton
                    sx={{ borderRadius: 20, mr: 1 }}
                    width={90}
                    height={40}
                  />
                  <Skeleton
                    sx={{ borderRadius: 20, mr: 1 }}
                    width={90}
                    height={40}
                  />
                  <Skeleton
                    sx={{ borderRadius: 20, mr: 1 }}
                    width={90}
                    height={40}
                  />
                </Box>
              </>
            ) : (
              <>
                <Typography
                  variant="subtitle2"
                  sx={{
                    width: "100%",
                    whiteSpace: "wrap",
                  }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    color="secondary"
                  >
                    {" "}
                    {data && "total_result" in data
                      ? data.total_result
                      : 0}{" "}
                  </Typography>
                  {t("matching results")}
                  {data && "total_result" in data ? " for" : ""}
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="secondary"
                  >
                    {" "}
                    {data && "query" in data ? `"${data.query}"` : ""}
                  </Typography>
                </Typography>

                <Box sx={{ overflowX: "auto" }}>
                  <Stack sx={{ py: 0.7 }} direction="row" spacing={1}>
                    {data &&
                      "found_result" in data &&
                      data.found_result.map((item, index) => (
                        <Chip
                          size="small"
                          key={index}
                          label={item.category + " " + item.count}
                          onClick={() => handleClick(item.category_slug, index)}
                          sx={{
                            mb: 0.8,
                            bgcolor: `${
                              active[index] ? "secondary.lighter" : "default"
                            }`,
                          }}
                        />
                      ))}
                  </Stack>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </CenterContainer>
    </Paper>
  );
}
