import ArrowDropDownIcon from "@mui/icons-material//ArrowDropDown";
import { Box, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuPopover from "../MenuPopover";
import NavButton from "./NavButton";

const lang_options = [
  { label: "English", placeHolder: "E", value: "en-US" },
  { label: "አማርኛ", placeHolder: "አ", value: "am" },
];

export function LanguageSelects() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (lang) => {
    i18n.changeLanguage(lang);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <NavButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {lang_options.find((lang) => lang.value === i18n.language).label}
        <ArrowDropDownIcon fontSize="14px" />
      </NavButton>

      <MenuPopover
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ width: "100px" }}
      >
        {lang_options.map((option) => (
          <MenuItem
            key={option.value}
            selected={i18n.language === option.value}
            onClick={() => handleMenuItemClick(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </Box>
  );
}
