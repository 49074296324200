import { api } from "../../apis/config";
import {
  FACEBOOK_AUTH_FAIL,
  FACEBOOK_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  GOOGLE_AUTH_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  OPEN_LOGIN_DIALOG,
  CLOSE_LOGIN_DIALOG,
  USER_LOADED_FAIL,
  USER_LOADED_SUCCESS,
  SET_DIALOG_CLOSING_ACTION,
  SET_NOTIFICATIONS,
  SET_CHAT_NOTIFICATIONS,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: localStorage.getItem("token") ? true : false,
  user: null,
  errorMsg: null,
  notifications: {
    count: 0,
    nf_list: [],
  },
  chats: {
    count: 0,
    chat_list: [],
  },
  openLoginDialog: false,
  dCA: null,
};

const authReducer = function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      };
    case SET_CHAT_NOTIFICATIONS:
      return {
        ...state,
        chats: payload,
      };
    case OPEN_LOGIN_DIALOG:
      return {
        ...state,
        openLoginDialog: true,
        dCA: payload,
      };
    case CLOSE_LOGIN_DIALOG:
      return {
        ...state,
        openLoginDialog: false,
      };
    case SET_DIALOG_CLOSING_ACTION:
      return {
        ...state,
        dCA: payload,
      };
    case GOOGLE_AUTH_SUCCESS:
    case LOGIN_SUCCESS:
    case FACEBOOK_AUTH_SUCCESS:
      localStorage.setItem("token", payload.token);
      api.defaults.headers["Authorization"] = "Token " + payload.token;
      return {
        ...state,
        isAuthenticated: true,
        token: payload.token,
        errorMsg: null,
      };
    case USER_LOADED_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case LOGIN_FAIL:
    case GOOGLE_AUTH_FAIL:
    case FACEBOOK_AUTH_FAIL:
    case USER_LOADED_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      api.defaults.headers["Authorization"] = null;
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        errorMsg: payload ? payload.data : null,
      };
    default:
      return state;
  }
};

export default authReducer;
