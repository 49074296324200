import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import {
  activatePendingProduct,
  countPendingProduct,
  getProduct,
  getUserProductList,
  listPendingProduct,
  postAd,
  productInfinite,
  retrievePendingProduct,
  showPhoneNumber,
  updateAd,
  updateImage,
} from "../apis/Product";

export const useProductInfinite = (slug, page, orderBy) => {
  return useInfiniteQuery(
    ["getProductInfinte", slug, orderBy],
    productInfinite,
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next === null ? undefined : lastPage.next;
      },
    }
  );
};

export const useShowPhoneNumber = (id) => {
  return useMutation(() => showPhoneNumber(id), {
    refetchOnWindowFocus: false,
  });
};

export const usePostAd = () => {
  return useMutation((payload) => postAd(payload));
};

export const useUpdateAd = () => {
  return useMutation((payload) => updateAd(payload));
};

export const useUpdateImage = () => {
  return useMutation((payload) => updateImage(payload));
};

export const useGetProduct = (id) => {
  return useQuery(["get_product", id], () => getProduct(id));
};

export const useGetUserProductList = (query) => {
  return useInfiniteQuery(["getUserProductList", query], getUserProductList, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next === null ? undefined : lastPage.next;
    },
  });
};

export const useCountPendingProduct = () => {
  return useQuery(["count_pending_product"], () => countPendingProduct());
};

export const useListPendingProduct = () => {
  return useQuery(["list_pending_product"], () => listPendingProduct());
};

export const useRetrievePendingProduct = (id, slug) => {
  return useQuery(["retrieve_pending_product", id, slug], () =>
    retrievePendingProduct(id, slug)
  );
};

export const useActivatePendingProduct = () => {
  return useMutation((payload) => activatePendingProduct(payload));
};
