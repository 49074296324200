import { message } from "../Account/Constants";

export const validateAmole = (values) => {
  let errors = {};
  if (!values.card_number) errors.card_number = message.VALUE_ERROR;
  if (!values.pin_or_otp) errors.pin_or_otp = message.VALUE_ERROR;
  else if (values.pin_or_otp < 1000) errors.pin_or_otp = message.OTP_PIN_ERROR;

  return errors;
};
