import ClockIcon from "@iconify/icons-eva/clock-outline";
import { Icon } from "@iconify/react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Avatar,
  Badge,
  Box,
  Grid,
  Hidden,
  Link,
  Skeleton,
  Stack,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link as RouterLink } from "react-router-dom";
import placeholder from "../../config/images/placeholder.png";
import { fNumber } from "../../utils/formatNumber";
import { fToNow } from "../../utils/formatTime";
import CustomTooltip from "../CustomTooltip";
import Label from "../Label";
import AttributeConfig from "../products1/AttributeConfigLongCards";
import Favorite from "../products1/Favorite";
import { DescriptionStyle } from "./AdCardForJobs";
import ShowPhoneNumber from "./ShowPhoneNumber";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: theme.spacing(1),
    marginTop: theme.spacing(1),
    boxShadow: "rgba(4, 17, 29, 0.25) 0px 0px 8px 0px",
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 1,
    },
  },
  cover: { width: "100%", height: "100%", position: "relative" },
  body: { minHeight: 50 },
  price: { lineHeight: "2px", float: "right", textAlign: "end" },
  content: { display: "flex", height: "100%" },
  contentTitle: {
    marginBottom: theme.spacing(0.4),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "left",
      flexDirection: "row",
    },
    [theme.breakpoints.up("sm")]: { float: "right", flexWrap: "noWrap" },
  },
}));

const CardContentNoPadding = styled(CardContent)(`
  padding: "10px";
  &:last-child {
    padding: 0;
  }
`);

const CoverImgStyle = styled(LazyLoadImage)({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
  cursor: "pointer",
});

export default function AdCard({ isLoading, data, filter_category }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card
      className={classes.root}
      sx={{
        "&:hover": {
          boxShadow: isLoading
            ? null
            : "rgb(145 158 171 / 24%) -24px 24px 72px -8px",
        },
      }}
    >
      <Link
        component={RouterLink}
        to={data ? `/detail/${data.id}` : ""}
        sx={{ textDecoration: "none", width: "100%" }}
      >
        <Grid container>
          <Grid item xs={5} sm={4} md={4} lg={4} sx={{ minHeight: 175 }}>
            {isLoading ? (
              <Skeleton
                variant="rect"
                animation="wave"
                className={classes.cover}
              />
            ) : (
              <div className={classes.cover}>
                <CoverImgStyle
                  style={{
                    objectFit: "cover",
                  }}
                  width="100%"
                  height="100%"
                  placeholderSrc={placeholder}
                  effect="black-and-white"
                  src={data.image}
                />

                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    bgcolor: "rgba(0, 0, 0, 0.54)",
                    color: "white",
                    fontSize: "12px",
                    borderTopRightRadius: "5px",
                    padding: 0.4,
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <PhotoCamera sx={{ fontSize: "12px", mr: 0.3 }} />
                  <Box component="span">{data.photo_count}</Box>
                </Box>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    right: (theme) => theme.spacing(1.5),
                    top: (theme) => theme.spacing(1.5),
                    position: "absolute",
                  }}
                  aria-label="recipe"
                >
                  <Favorite
                    id={data.id}
                    css={{
                      fontSize: "20px",
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                </Box>
                {data.is_featured ? (
                  <Label
                    variant="filled"
                    color="secondary"
                    sx={{
                      left: (theme) => theme.spacing(1.5),
                      top: (theme) => theme.spacing(1.5),
                      position: "absolute",
                    }}
                  >
                    {t("Featured")}
                  </Label>
                ) : (
                  <Label sx={{ bgcolor: "transparent" }} />
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={7} sm={8} md={8} lg={8} xl={8}>
            <CardContentNoPadding
              sx={{ height: "100%" }}
              className={classes.content}
            >
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{ p: 1, width: "100%" }}
              >
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                    {isLoading ? (
                      <Typography
                        variant="h4"
                        gutterBottom
                        style={{ paddingRight: "5px" }}
                      >
                        <Skeleton variant="rect" animation="wave" />
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          width: "250px",
                          fontFamily: '"Poppins", sans-serif',
                          lineHeight: 1.3,
                          fontSize: "1rem",
                          textDecoration: "none",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {data.title}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                    {isLoading ? (
                      <Typography gutterBottom variant="h4">
                        <Skeleton variant="rect" animation="wave" />
                      </Typography>
                    ) : (
                      <Box className={classes.contentTitle}>
                        <Typography
                          sx={{ color: "primary.main", fontWeight: 500 }}
                          variant="h5"
                        >
                          <Trans i18nKey="ad_price">
                            {" "}
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: "secondary.main", mr: 0.5 }}
                            >
                              ETB
                            </Typography>
                            {{ price: fNumber(data.price) }}
                          </Trans>
                          {data.price_per && (
                            <Typography component="span">
                              /{t(data.price_per)}
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {isLoading ? (
                      <Typography variant="h4">
                        <Skeleton variant="rect" animation="wave" height={95} />
                      </Typography>
                    ) : (
                      <Box sx={{ fontSize: "14px", pl: 1 }}>
                        <Box sx={{ display: "flex", my: 1 }}>
                          {filter_category && data && (
                            <Grid
                              container
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                              {AttributeConfig[filter_category].map(
                                (info, index) =>
                                  data &&
                                  data[info.value] !== null &&
                                  data[info.value] !== undefined && (
                                    <Grid
                                      key={index}
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        "& > :not(style)": {
                                          whiteSpace: "nowrap",
                                        },
                                      }}
                                    >
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.text.disabled,
                                          textTransform: "capitalize",
                                          mr: 0.5,
                                        }}
                                      >
                                        {t(info.name)}:
                                      </Typography>
                                      <Typography variant="body2">
                                        {t(data[info.value]) +
                                          " " +
                                          t(info.unit)}
                                      </Typography>
                                    </Grid>
                                  )
                              )}
                            </Grid>
                          )}
                        </Box>
                        {Boolean(data.description) && (
                          <DescriptionStyle>
                            {data.description}
                          </DescriptionStyle>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>

                <Hidden smDown>
                  {isLoading ? (
                    <Typography style={{ paddingTop: "4px" }}>
                      <Skeleton variant="rect" animation="wave" />
                    </Typography>
                  ) : (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ alignItems: "flex-end" }}
                    >
                      <Box
                        component={Typography}
                        variant="caption"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        <LocationOnIcon fontSize="inherit" sx={{ mr: 0.5 }} />
                        <Box
                          component="span"
                          sx={{
                            width: "230px",
                            fontFamily: '"Poppins", sans-serif',
                            textDecoration: "none",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {data.location
                            .split(", ")
                            .map((x, i) => (i > 0 ? ", " : "") + t(x))}
                        </Box>
                      </Box>
                      {data && data.release_date && (
                        <Box sx={{ display: "flex" }}>
                          <Box
                            component={Icon}
                            icon={ClockIcon}
                            sx={{ width: 16, height: 16, mr: 0.5 }}
                          />
                          <Typography color="textSecondary" variant="caption">
                            {fToNow(data.release_date)}
                          </Typography>
                        </Box>
                      )}
                      <Box sx={{ display: "flex" }}>
                        <CustomTooltip title={data.owner.name} placement="left">
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              data.owner.is_verified && (
                                <VerifiedIcon
                                  sx={{
                                    color: "blue",
                                    backgroundColor: "#fff",
                                    fontSize: "14px",
                                    boxShadow: "0 0 0 1.5px #fff",
                                    borderRadius: "50%",
                                    padding: 0,
                                  }}
                                />
                              )
                            }
                          >
                            <Box
                              sx={{
                                p: 0.5,
                                border: 1,
                                borderColor: "grey.200",

                                borderRadius: "8px",
                              }}
                            >
                              <Avatar
                                variant="rounded"
                                sx={{ width: 50, height: 40 }}
                                src={data.owner.logo}
                                alt={data.owner.name}
                              />
                            </Box>
                          </Badge>
                        </CustomTooltip>
                      </Box>
                    </Stack>
                  )}
                </Hidden>
              </Stack>
            </CardContentNoPadding>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Hidden smUp>
              {isLoading ? (
                <Typography sx={{ p: 0.5 }}>
                  <Skeleton variant="rect" animation="wave" height={18} />
                </Typography>
              ) : (
                <Stack
                  pl={1}
                  pr={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ diaplay: "flex", alignItems: "center" }}
                    variant="caption"
                    color="textSecondary"
                  >
                    <LocationOnIcon fontSize="inherit" />
                    {data.location
                      .split(", ")
                      .map((x, i) => (i > 0 ? ", " : "") + t(x))}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <ShowPhoneNumber
                      id={data.id}
                      phoneNumber={data.phone_number}
                    />
                  </Box>
                </Stack>
              )}
            </Hidden>
          </Grid>
        </Grid>
      </Link>
    </Card>
  );
}
