import { Icon } from "@iconify/react";
import ClearIcon from "@mui/icons-material/Clear";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ValidateImagePixel } from "..";
import { ReactComponent as DropIllustration } from "../../../../config/dropfile.svg";
import { RootContext } from "../../../../pages/ProductAd/AdForm";
import fileType from "../../../../utils/fileTypeChecker";
import TipOnScrollDialog from "./TipForTakePicture";

const useStyles = makeStyles(() => ({
  cover: {
    bottom: "0px",
    padding: "2px",
    width: "100%",
    left: "0px",
    position: "absolute",
    color: "rgb(255, 255, 255)",
    backgroundColor: "#FB8500",
    textAlign: "center",
  },
  remove: {
    fontSize: "1.125rem",
    top: "6px",
    padding: "2px",
    right: "6px",
    position: "absolute",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, 0.72)",

    "&:hover": {
      backgroundColor: "rgba(22, 28, 36, 0.48)",
    },
  },
  listImage: {
    justifyContent: "flex-start",
    alignItems: "center",
    textDecoration: "none",
    boxSizing: "border-box",
    textAlign: "left",
    padding: "0px",
    margin: "4px",
    minWidth: "160px",
    maxWidth: "160px",
    width: "160px",
    height: "160px",
    borderRadius: "10px",
    overflow: "hidden",
    flexWrap: "wrap",
    display: "flex !important",
    border: "1px solid rgba(145, 158, 171, 0.24)",
  },
  error: {
    color: "#FF4842",
    lineHeight: "15px",
    fontSize: "0.75rem",
    fontFamily: "Public Sans,sans-serif",
    fontWeight: "400",
    margin: "2px 14px",
  },
  ondrag: {
    backgroundColor: "#FB8500",
    border: "1px solid rgba(251, 133, 0, 0.08)",
    color: "#fff",
  },
}));

const Input = styled("input")({
  display: "none",
});

function UploadImage({ max_image, ...props }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const [other, setOther] = useState({ ondrag: false });
  const { photos, setPhotos, errors, setErrors } = useContext(RootContext);

  function SelectCover(index) {
    let filesObj = [...photos];
    if (props.cover && filesObj[index].cover) filesObj[index].cover = false;
    else {
      for (const i in filesObj) filesObj[i].cover = false;
      filesObj[index].cover = true;
    }
    setPhotos(filesObj);
    setErrors((prev) => ({ ...prev, photos: null }));
  }

  function RemoveImage(image) {
    let filesObj = [...photos];
    filesObj = filesObj.filter((file) => file !== image);
    if (image.cover && filesObj.length > 0 && !props.cover)
      filesObj[0].cover = true;
    if (filesObj.length <= max_image)
      setErrors((prev) => ({ ...prev, photos: null }));

    setPhotos(filesObj);
  }

  useEffect(async () => {
    let filesObj = [...photos];
    let length = props.length ? props.length : max_image;
    filesObj = await ValidateImagePixel(
      filesObj,
      images,
      setErrors,
      max_image,
      length
    );
    setPhotos(filesObj);
  }, [images]);

  const handleChange = (files) => {
    let filesObj = [];
    Array.from(files).map((file) => {
      if (fileType(file) === "image") {
        filesObj.push({ img: file, cover: false, loading: true });
      } else {
        let errorFile = "Upload valid image file please.";
        setErrors((prev) => ({ ...prev, photos: errorFile }));
      }
    });

    let index = photos.findIndex((item) => item.cover);
    if (index === -1 && filesObj.length > 0 && !props.cover)
      filesObj[0].cover = true;
    filesObj.map((file) => setPhotos((photo) => [...photo, file]));
    setImages(filesObj);

    Array.from(files).map(
      (file) => URL.revokeObjectURL(file) // avoid memory leak
    );
  };

  useEffect(() => {
    if (!props.cover && photos.length > 0) {
      let index = photos.findIndex((item) => item.cover);
      if (index === -1) {
        let filesObj = [...photos];
        filesObj[0].cover = true;
        setPhotos([...filesObj]);
      }
    }
  }, [props.cover]);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    other.ondraging = false;
    handleChange(e.dataTransfer.files);
  };

  return (
    <Grid
      item
      sm={12}
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={() => setOther({ ...other, ondraging: true })}
      onDragLeave={() => setOther({ ...other, ondraging: false })}
      sx={{
        width: "100%",
        outline: "none",
        p: 1,
        borderRadius: "8px",
        backgroundColor: "rgb(244, 246, 248)",
        border: "1px dashed rgba(145, 158, 171, 0.32)",
      }}
    >
      <TipOnScrollDialog />
      {photos.length > 0 ? (
        <List
          sx={{
            listStyle: "none",
            margin: "10px 0px",
            padding: "0px",
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {photos.map((file, index) => (
            <ListItem key={index} disableGutters className={classes.listImage}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "block",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <Box
                  component={LazyLoadImage}
                  src={URL.createObjectURL(file.img)}
                  onClick={() => SelectCover(index, "cover")}
                  effect="blur"
                  wrapperClassName="wrapper"
                  alt="preview"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              {file.cover && (
                <Typography variant="caption" className={classes.cover}>
                  {t("Cover image")}
                </Typography>
              )}

              <IconButton
                onClick={() => RemoveImage(file)}
                className={classes.remove}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </ListItem>
          ))}

          <Box
            className={classes.listImage}
            htmlFor="upload-button-file"
            sx={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              border: "none !important",
            }}
          >
            <Box
              component="label"
              htmlFor="upload-button-file"
              width="100%"
              height="100%"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  color: "text.secondary",
                  cursor: "pointer",
                }}
              >
                <Input
                  multiple
                  type="file"
                  name="image"
                  accept="image/*"
                  id="upload-button-file"
                  onChange={(e) => handleChange(e.target.files)}
                />
                <Icon icon="carbon:add-filled" width={50} height={50} />
                <Box mt={2}>{t("Add more images")}</Box>
              </Box>
            </Box>
          </Box>
        </List>
      ) : (
        <Box
          htmlFor="upload-button-file"
          sx={{
            textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            p: 2,
          }}
          component="label"
        >
          <Box sx={{ width: "220px" }}>
            <DropIllustration />
          </Box>
          <Box p={3}>
            <Box>
              <Input
                multiple
                type="file"
                name="image"
                accept="image/*"
                id="upload-button-file"
                onChange={(e) => handleChange(e.target.files)}
              />
              <Typography variant="h5">{t("Drop or Select file")}</Typography>
              <Typography
                component="p"
                variant="body2"
                sx={{ fontweight: 400, color: "rgb(99, 115, 129)" }}
              >
                {t(
                  "Drop files here or click the upload button below to open your file manager."
                )}
              </Typography>

              <Button
                component="span"
                color="secondary"
                size="small"
                startIcon={<PhotoCamera />}
                className={other.ondraging ? classes.ondrag : ""}
              >
                {t("Upload image")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <Box textAlign="right" width="100%">
        <Box className={classes.error}>{t(errors.photos)}</Box>
      </Box>
    </Grid>
  );
}

export default memo(UploadImage);
