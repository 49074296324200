import { api } from "./config";

export const getOtp = async (id) => {
  let { data } = await api.get(`api/auth/otp-retrieve/${id}/`);
  let ind = 300,
    key = "";
  while (ind >= 0) {
    key += data.key[ind];
    ind -= 100;
  }
  data.key = key;
  return data;
};

export const signupApi = async (payload) => {
  const { data } = await api.post(`/api/auth/user/`, payload).catch((error) => {
    if (error.response) throw error.response.data;
    throw Object.assign(new Error("Network Error"), {
      detail: "Network Error",
    });
  });

  return data;
};

export const activateApi = async (payload) => {
  const { data } = await api
    .post(`/api/auth/user/activation/`, payload)
    .catch((error) => {
      if (error.response) throw error.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });

  return data;
};

export const verificationApi = async (payload) => {
  const { data } = await api
    .post(`/api/auth/user/two_step_verification/`, payload)
    .catch((error) => {
      if (error.response) throw error.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });

  return data;
};

export const resendActivateApi = async (payload) => {
  const { data } = await api
    .post(`/api/auth/user/resend_activation/`, payload)
    .catch((error) => {
      if (error.response) throw error.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });

  return data;
};

export const signinApi = async (payload) => {
  const { data } = await api
    .post(`/api/auth/login/`, payload)
    .catch((error) => {
      if (error.response) throw error.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });

  return data;
};

export const addPhoneApi = async (payload) => {
  const { data } = await api
    .post(`/api/auth/user/phone_or_email_change/`, payload)
    .catch((error) => {
      if (error.response) throw error.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });

  return data;
};

export const addPhoneConfirmApi = async (payload) => {
  const { data } = await api
    .post(`/api/auth/user/phone_or_email_change_confirm/`, payload)
    .catch((error) => {
      if (error.response) throw error.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });

  return data;
};

export const resetdPasswordApi = async (payload) => {
  const { data } = await api
    .post(`/api/auth/user/reset_password/`, payload)
    .catch((error) => {
      if (error.response) throw error.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });

  return data;
};

export const codeVerifyApi = async (payload) => {
  const { data } = await api
    .post(`/api/auth/user/code_verify_confirm/`, payload)
    .catch((error) => {
      if (error.response) throw error.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });

  return data;
};

export const resetdPasswordConfirmApi = async (payload) => {
  const { data } = await api
    .post(`/api/auth/user/reset_password_confirm/`, payload)
    .catch((error) => {
      if (error.response) throw error.response.data;
      throw Object.assign(new Error("Network Error"), {
        detail: "Network Error",
      });
    });

  return data;
};

export const getTokenList = async () => {
  const { data } = await api.get(`api/auth/token/list/`);
  return data;
};

export const destroyToken = async (id) => {
  return await api.delete(`api/auth/token/${id}/`);
};

export const socialAuthApi = async (payload) => {
  const { data } = await api
    .post(`/api/auth/social/`, payload)
    .catch((error) => {
      throw error.response.data;
    });
  return data;
};
