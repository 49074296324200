import { useMutation, useQuery } from "react-query";
import {
  getOtp,
  signupApi,
  activateApi,
  signinApi,
  addPhoneApi,
  codeVerifyApi,
  resetdPasswordApi,
  addPhoneConfirmApi,
  resendActivateApi,
  resetdPasswordConfirmApi,
  getTokenList,
  verificationApi,
  socialAuthApi,
} from "../apis/Accounts";

export const useGetOtp = (id) => {
  return useQuery(["get_otp", id], () => getOtp(id));
};

export const useSignupHook = () => {
  return useMutation((payload) => signupApi(payload));
};

export const useActivateHook = () => {
  return useMutation((payload) => activateApi(payload));
};

export const useVerificationHook = () => {
  return useMutation((payload) => verificationApi(payload));
};

export const useResendActivateHook = () => {
  return useMutation((payload) => resendActivateApi(payload));
};

export const useSigninHook = () => {
  return useMutation((payload) => signinApi(payload));
};

export const useAddPhoneHook = () => {
  return useMutation((payload) => addPhoneApi(payload));
};

export const useAddPhoneConfirmHook = () => {
  return useMutation((payload) => addPhoneConfirmApi(payload));
};

export const usePasswordPasswordHook = () => {
  return useMutation((payload) => resetdPasswordApi(payload));
};

export const useCodeVerifyHook = () => {
  return useMutation((payload) => codeVerifyApi(payload));
};

export const usePasswordPasswordConfirmHook = () => {
  return useMutation((payload) => resetdPasswordConfirmApi(payload));
};

export const useGetTokent = (id) => {
  return useQuery(["get_token", id], () => getTokenList());
};

export const useSocialAuthHook = () => {
  return useMutation((payload) => socialAuthApi(payload));
};
