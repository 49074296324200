// Input field name for vehicle
const vehiclesell = [
  {
    label: "Mileage",
    name: "mileage",
    type: "number",
    helperText: "",
  },
  {
    label: "Horse power",
    name: "horse_power",
    type: "number",
    helperText: "",
    required: false,
  },
  { label: "Year", name: "year", type: "number", helperText: "" },
];
const carsell = [
  ...vehiclesell,
  {
    label: "VIN number",
    name: "VIN_number",
    type: "text",
    helperText: "17-digit code of letters and numbers",
    required: false,
  },
];
const vehiclerent = [
  { label: "Year", name: "year", type: "number", helperText: "" },
];

// Input field name for property
const property = [
  {
    label: "Area",
    name: "area",
    type: "number",
    helperText: "In square meter",
  },
];
const villa = [
  {
    label: "Compound area",
    name: "compound_area",
    type: "number",
    helperText: "In square meter",
  },
  ...property,
];
const realestate = [
  {
    label: "Compound area",
    name: "compound_area",
    type: "number",
    helperText: "In square meter",
    dis: { real_estate_type: ["Villa"] },
    required: false,
  },
  ...property,
];

const condominium = [
  ...property,
  {
    label: "Site & Block",
    name: "site_and_block",
    type: "text",
    helperText: "",
  },
];
const room = [
  ...property,
  {
    label: "Dimension",
    name: "dimension",
    type: "text",
    helperText: "width by length (eg. 4*4 m)",
  },
];
const commercial_building = [
  {
    label: "Compound area",
    name: "compound_area",
    type: "number",
    helperText: "In square meter",
  },
  ...property,
];
// Input field name for electronics
const refrigerator = [
  {
    label: "Capacity",
    name: "litre",
    type: "number",
    helperText: "in Litre",
    required: false,
  },
];

const washing_machine = [
  {
    label: "Washing Capacity",
    name: "washing_capacity",
    type: "number",
    helperText: "in KG",
    required: false,
  },
];

export const InputName = {
  common: [
    { label: "Title", name: "title", type: "text", helperText: "" },
    {
      label: "Phone Number",
      name: "phone_number",
      type: "text",
      helperText: "",
    },
  ],
  pricerequired: [
    { label: "Price", name: "price", type: "text", helperText: "" },
  ],
  priceoptional: [
    {
      label: "Price",
      name: "price",
      type: "text",
      helperText: "",
      required: false,
    },
  ],
  "car-for-sell": carsell,
  "motorcycle-for-sell": vehiclesell,
  "3-wheeler-motors-for-sell": vehiclesell,
  "heavy-vehicles-for-sell": carsell,
  "car-for-rent": vehiclerent,
  "wedding-car-for-rent": vehiclerent,
  "tourist-car-for-rent": vehiclerent,
  "heavy-vehicles-for-rent": vehiclerent,
  "apartment-for-sell": property,
  "apartment-for-rent": property,
  "villa-for-sell": villa,
  "villa-for-rent": villa,
  "real-estate-for-sell": realestate,
  "commercial-for-sell": property,
  "commercial-for-rent": property,
  "commercial-building-for-sell": commercial_building,
  "commercial-building-for-rent": commercial_building,
  "condominium-for-rent": condominium,
  "condominium-for-sell": condominium,
  "service-room-for-rent": room,
  "flatmates-room-for-rent": room,
  refrigerator: refrigerator,
  "washing-machine": washing_machine,
  employee: [
    {
      label: "Language",
      name: "language",
      type: "text",
      helperText: "Enter languages by a comma-separated",
    },
  ],
};
