import { NOTIFICATION_API } from "../config";

class WebSocketService {
  static instance = null;
  callbacks = {};

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  constructor() {
    this.socketRef = null;
    this.token = null;
  }

  connect(token) {
    // console.info("tyring", token, this.socketRef);
    if (this.socketRef && this.socketRef.CONNECTING) return;
    if (token === undefined || token === null) return;
    if (this.socketRef && this.socketRef.readyState === 1) return;
    if (this.token === token) return;

    this.token = token;
    this.socketRef = new WebSocket(NOTIFICATION_API + "/?token=" + token);
    this.socketRef.onopen = () => {
      // console.info("Notification Web socket open");
    };
    // this.socketNewMessage(JSON.stringify({ command: "fetch_messages" }));
    this.socketRef.onmessage = (e) => {
      // sending message
      this.socketNewMessage(e.data);
    };
    this.socketRef.onerror = (e) => {
      console.info("error ws", e);
      this.token = null;
    };
    this.socketRef.onclose = () => {
      console.info("web socket closed");
      this.token = null;
    };
    this.socketRef.onclose = () => {
      console.info("web socket closed");
      this.token = null;
      this.connect();
    };
  }

  socketNewMessage(data) {
    const parsedData = JSON.parse(data);
    const command = parsedData.command;
    if (Object.keys(this.callbacks).length === 0) {
      return;
    }
    if (command === "notifications") {
      this.callbacks[command](parsedData.notifications);
    }
    if (command === "new_notification") {
      this.callbacks[command](parsedData.notification);
    }
  }

  fetchNotifications() {
    console.info("fetching notifications");
    this.sendMessage({
      command: "fetch_notifications",
    });
  }

  addCallbacks(notificationsCallback, newNotificationCallback) {
    this.callbacks["notifications"] = notificationsCallback;
    this.callbacks["new_notification"] = newNotificationCallback;
  }

  // to the backend
  sendMessage(data) {
    try {
      console.info("sending message via sendMessage");
      this.socketRef.send(JSON.stringify({ ...data }));
    } catch (err) {
      console.info(err.message);
    }
  }

  state() {
    return this.socketRef.readyState;
  }

  waitForSocketConnection(callback) {
    const socket = this.socketRef;
    // const self = this;
    // const recursion = this.waitForSocketConnection;
    setTimeout(function () {
      if (socket.readyState === 1) {
        console.info("connection is secure");
        if (callback != null) callback();
        return;
      } else {
        console.info("waiting for connection ...");
        // self.waitForSocketConnection(callback);
        // recursion(callback);
      }
    }, 1);
  }
}

const WebSocketInstance = WebSocketService.getInstance();

export default WebSocketInstance;
