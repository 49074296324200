import { Icon } from "@iconify/react";
import { IconButton, Stack } from "@mui/material";
import debounce from "lodash.debounce";
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetChatList } from "../../hooks";
import { useGetUnReadNotificationCount } from "../../hooks/notifications_hook";
import { set_chats, set_notifications } from "../../redux/actions/auth";
import WebSocketInstance from "../../services/notificationWebSocket";
import ChatNotif from "../Feedback/Notification/ChatNotif";
import NotificationsPopover from "../Feedback/Notification/NotificationsPopover";
import withLoginRequired from "../Feedback/withLoginRequired";
import AccountPopover from "./AccountPopover";
import StaffNotify from "./StaffNotify";

const FavsWithLogin = withLoginRequired(IconButton);

function UserMenus({
  token,
  notifications,
  chats,
  set_notifications,
  set_chats,
}) {
  const { data: unread_noti } = useGetUnReadNotificationCount();
  const navigate = useNavigate();
  const { refetch } = useGetChatList("");

  useEffect(() => {}, [notifications]);
  const wfcd = useMemo(
    () => debounce(() => WebSocketInstance.connect(token), 500),
    [token]
  );

  wfcd();
  WebSocketInstance.addCallbacks(
    () => console.info(),
    (data) => onNewNotification(data)
  );

  useEffect(() => {
    if (!Boolean(unread_noti)) return;
    set_notifications({ ...notifications, count: unread_noti.ns_count });
    set_chats({
      ...chats,
      count: unread_noti.chat_count,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unread_noti]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const onNewNotification = (data) => {
    // if the sent notification was the type of chat
    // increament number of unread chat and TODO: use push notification
    let temp = false;
    if (data && data.target && data.target.target_type === "Chat") {
      // return;
      const newChat = chats.chat_list.map((item, ind) => {
        if (String(data.target.target_object_id) === String(item.id)) {
          temp = true;
        } else return true;
      });
      // if the chat arealy exists
      if (!temp) refetch();
    } else {
      // other notifications than chat should be pushed here
      set_notifications({
        count: notifications.count + 1,
        nf_list: [data, ...notifications.nf_list],
      });
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <StaffNotify />
        <FavsWithLogin
          ref={null}
          sx={{ bgcolor: "inherit", color: "primary.main", ml: 1.5 }}
          onClick={() => navigate("/profile/favourites")}
          variant=""
        >
          <Icon
            icon="bi:bookmark-heart"
            width={20}
            height={20}
            color="inherit"
          />
        </FavsWithLogin>
        <ChatNotif />
        <NotificationsPopover />
      </Stack>
      <AccountPopover />
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  notifications: state.auth.notifications,
  chats: state.auth.chats,
});

export default connect(mapStateToProps, {
  set_notifications,
  set_chats,
})(UserMenus);
