import queryString from "query-string";

import { api } from "./config";

// api for getting chat list
export const get_chat_list = async ({ pageParam = 0, queryKey }) => {
  let page;
  // check if it is the first time fetching
  if (pageParam !== 0) {
    const param = pageParam.split("?")[1];
    const values = queryString.parse(param);
    page = values.page;
  }

  const params = {
    page: page,
    query: queryKey[1],
  };

  const response = await api.get(`/api/chat/list/`, {
    params: params,
  });

  return response.data;
};

// api for getting messages list
export const get_chat_messages = async ({ pageParam = 0, queryKey }) => {
  let page;
  // check if it is the first time fetching
  if (pageParam !== 0) {
    const param = pageParam.split("?")[1];
    const values = queryString.parse(param);
    page = values.page;
  }
  // alert(JSON.stringify(queryKey));
  const params = {
    page: page,
  };
  // alert(`/api/chat/${queryKey[1]}messages/`);
  const response = await api.get(`/api/chat/${queryKey[1]}/messages/`, {
    params: params,
  });
  return response.data;
};

// get unread message list
export const get_unread_message_list = async () => {
  const response = await api.get(`/api/chat/unread-message/list/`);
  return response.data;
};

// uplaoding chat files
export const uploadChatFile = async (data, config) => {
  try {
    const res = await api.post(`/api/chat/upload-file/`, data, config);
    return res;
  } catch (error) {
    // const errorMessage = error.response.data.message;
    // throw new createHttpError(errorMessage);
  }
};

// mark as message as seen
export const mark_message_as_seen = async (payload) => {
  const response = await api.put(`/api/chat/${payload}/mark-as-seen/`);
  return response.data;
};

// create chat
export const create_chat = async (payload) => {
  const response = await api.post(`/api/chat/create/`, payload);
  return response.data;
};
