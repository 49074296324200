// import { alertTitleClasses } from "@mui/material";
import queryString from "query-string";

import { api } from "./config";

// // get all notfications
// export const get_notification_list = async (payload) => {
//   const response = await api.get(`/api/notifications/list/`);
//   return response.data;
// };

// api for getting notfications list
export const get_notification_list = async ({ pageParam = 0, queryKey }) => {
  let page;
  // check if it is the first time fetching
  if (pageParam !== 0) {
    const param = pageParam.split("?")[1];
    const values = queryString.parse(param);
    page = values.page;
  }
  // alert(JSON.stringify(queryKey[1].values));
  const params = {
    page: page,
  };
  const response = await api.get(`/api/notifications/list/`, {
    params: params,
  });
  return response.data;
};

// get all notfications
export const get_unread_notification_count = async (payload) => {
  const response = await api.get(
    `/api/notifications/unread-notifications/count/`
  );
  return response.data;
};

// mark as read
export const mark_notification_as_read = async (payload) => {
  const response = await api.put(
    `/api/notifications/${payload}/mark-as-read/`,
    { unread: false }
  );
  return response.data;
};

// delete notification
export const remove_notification = async (payload) => {
  const response = await api.put(`/api/notifications/${payload}/remove/`, {
    unread: false,
  });
  return response.data;
};

// // mark all as read
// export const mark_notification_as_read = async (payload) => {
//   alert(`/api/notifications/${payload}/mark-as-read/`);
//   const response = await api.post(
//     `/api/notifications/${payload}/mark-as-read/`
//   );
//   return response.data;
// };
