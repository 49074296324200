import arrowtop from "@iconify/icons-eva/chevron-up-outline";
import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import React, { useEffect } from "react";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <IconButton
          color="primary"
          aria-label="Scroll to Top"
          onClick={handleClick}
          sx={{
            cursor: "pointer",
            position: "fixed",
            bottom: "29px",
            right: "20px",
            height: "40px",
            width: "40px",
            backgroundColor: "secondary.main",
            color: "#fff",
            "&:hover": {
              backgroundColor: "secondary.main",
              color: "#fff",
            },
            zIndex: 11111,
            transition: "all 0.5s ease",
          }}
        >
          <Icon icon={arrowtop} />
        </IconButton>
      )}
    </>
  );
}
