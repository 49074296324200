import axios from "axios";
import { BACKEND_API } from "../config";
import store from "../redux/store";

const api = axios.create({
  baseURL: BACKEND_API,
  headers: {
    Authorization: localStorage.getItem("token")
      ? "Token " + localStorage.getItem("token")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

api.interceptors.request.use((config) => {
  const str = store.getState();
  config.params = config.params || {};
  if (str.location.location.id > 0) {
    config.params["region"] = str.location.location.id;
  }
  return config;
});

export { api };
