import { Box, Link, Stack, SvgIcon, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { ReactComponent as logo } from "../config/logo/fs1.svg";
import ErrorFallbackPage from "../pages/ErrorFallbackPage";

function Copyright() {
  const { t } = useTranslation();

  return (
    <Typography variant="body2" align="center">
      {t("Copyright")}
      {" © "}
      <Link
        color="inherit"
        component={RouterLink}
        to="/"
        underline="none"
        sx={{
          "&:hover": {
            color: (theme) => theme.palette.secondary.main,
          },
        }}
      >
        {t("Familysooq")}
      </Link>{" "}
      {new Date().getFullYear()}. {t("All Right Reserved")}
    </Typography>
  );
}

export default function LogoOnlyLayout() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          pt: 3,
          mb: 4,
          length: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <RouterLink to="/">
          <SvgIcon
            sx={{
              fontSize: "98px",
              height: "35px",
              width: "160px",
              color: "primary.light",
            }}
            component={logo}
            inheritViewBox
          />
        </RouterLink>
      </Container>

      <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
        <Outlet />
      </ErrorBoundary>

      <Box
        component="footer"
        sx={{
          py: 1.5,
          px: 2,
          flex: "0 0 50px" /*or just height:50px;*/,
          marginTop: "auto",
        }}
      >
        <Container maxWidth="sm">
          <Stack
            justifyContent="center"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ my: 1 }}
          >
            <Link
              target="_blank"
              rel="noopener"
              href="/terms"
              color="primary"
              sx={{
                fontSize: 14,
                "&:hover": {
                  color: (theme) => theme.palette.secondary.main,
                },
              }}
              underline="none"
            >
              {t("Terms and Conditions")}
            </Link>
            <Box
              component="span"
              sx={{
                height: "4px",
                width: "4px",
                bgcolor: "gray",
                borderRadius: "100%",
              }}
            />
            <Link
              target="_blank"
              rel="noopener"
              href="/privacy_policy"
              sx={{
                fontSize: 14,
                fontWeight: 600,
                "&:hover": {
                  color: (theme) => theme.palette.secondary.main,
                },
              }}
              underline="none"
            >
              {t("Privacy Policy")}
            </Link>
          </Stack>
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}
