import { Suspense, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { useLocation } from "react-router-dom";
import "./App.css";
import LoginDialog from "./components/Account/LoginDialog";
import ScrollToTop from "./components/ScrollToTop";
import Layout from "./layout/Layout";
import store from "./redux/store";
import Router from "./routes";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  const routePath = useLocation();

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
  }, [routePath]);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeConfig>
          <GlobalStyles />
          <LoginDialog message="Login is Required" />
          <Layout>
            <Router />
          </Layout>
          <ScrollToTop />
        </ThemeConfig>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
