import { Icon } from "@iconify/react";
import { Badge, IconButton } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { useGetNotificationList } from "../../../hooks";
import {
  useMarkNotificationAsRead,
  useRemoveNotification,
} from "../../../hooks/notifications_hook";
import { set_chats, set_notifications } from "../../../redux/actions/auth";
import MenuPopover from "../../MenuPopover";
import withLoginRequired from "../withLoginRequired";
import NotificationsList from "./NotificationsList";

const NotifiWithLogin = withLoginRequired(IconButton);

function Notification({
  token,
  notifications,
  set_notifications,
  chats,
  set_chats,
  unread_noti,
}) {
  const [anchorRef, setAnchorRef] = useState(null);
  const { mutate } = useMarkNotificationAsRead();
  const { mutate: removeNotificationMutate } = useRemoveNotification();
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    setAnchorRef(event.currentTarget);
  };

  const handleClose = (event) => {
    event && event.preventDefault();
    setOpen(false);
    setAnchorRef(null);
  };

  const handleMarkAllAsRead = () => {
    mutate(-1);
    const new_nf_list = notifications.nf_list.map((notification) => ({
      ...notification,
      unread: false,
    }));
    set_notifications({ count: 0, nf_list: new_nf_list });
  };

  const handleRemoveNotification = (id) => {
    removeNotificationMutate(id);
    const new_nf_list = notifications.nf_list.filter(
      (notification) => notification.notification_id !== id
    );
    set_notifications({ ...notifications, nf_list: new_nf_list });
  };

  const handleMarkAsRead = (id, close = false) => {
    mutate(id);
    const new_nf_list = notifications.nf_list.map((notification) => ({
      ...notification,
      unread: notification.notification_id === id ? false : notification.unread,
    }));

    set_notifications({
      count: notifications.count === 0 ? 0 : notifications.count - 1,
      nf_list: new_nf_list,
    });
    if (close) handleClose();
  };

  return (
    <>
      <NotifiWithLogin
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onPreClick={handleOpen}
        onClick={() => setOpen(true)}
        sx={{ bgcolor: "inherit", color: "primary.main", ml: 1.5 }}
      >
        <Badge badgeContent={notifications.count} color="warning">
          <Icon icon="bytesize:bell" width={20} height={20} color="inherit" />
        </Badge>
      </NotifiWithLogin>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef && anchorRef}
        sx={{ width: 360 }}
      >
        <NotificationsList
          notifications={notifications}
          set_notifications={set_notifications}
          getList={useGetNotificationList}
          onMarkAsRead={handleMarkAsRead}
          onMarkAllAsRead={handleMarkAllAsRead}
          onRemoveNotification={handleRemoveNotification}
        />
      </MenuPopover>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  notifications: state.auth.notifications,
  chats: state.auth.chats,
});

export default connect(mapStateToProps, {
  set_notifications,
  set_chats,
})(Notification);
