import facebookFill from "@iconify/icons-eva/facebook-fill";
import googleFill from "@iconify/icons-eva/google-fill";
import { Icon } from "@iconify/react";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { useSocialAuthHook } from "../../hooks/Accounts";
import { load_user, LOGIN } from "../../redux/actions/auth";
import Alert from "../Alert/Alert";
import { urlGenerator } from "./Activation";

const googleProvider = new GoogleAuthProvider();

function AuthSocial({ load_user, LOGIN }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();
  const [notify, setNotify] = useState(false);
  const [errors, setErrors] = useState({});
  const { isLoading, ...mutate } = useSocialAuthHook();

  const continueWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user.providerData[0];

      const payload = {
        ...user,
        first_name: user.displayName.split(" ")[0],
        last_name: user.displayName.split(" ")[1],
      };
      let formData = new FormData();
      Object.keys(payload).forEach((key) => {
        if (Boolean(payload[key])) {
          if (Array.isArray(payload[key]))
            payload[key].forEach((item) => formData.append(key, item));
          else formData.append(key, payload[key]);
        } else formData.append(key, "");
      });

      mutate.mutate(formData);
    } catch (err) {
      if (err.message) {
        setErrors({ detail: err.message });
        setNotify(true);
      }
    }
  };

  const continueWithFacebook = (response) => {
    if (Boolean(response.email)) {
      const { picture, ...user } = response;
      const payload = {
        ...user,
        uid: user.id,
        photo: picture.data.url,
        providerId: "facebook.com",
      };
      let formData = new FormData();
      Object.keys(payload).forEach((key) => {
        if (Boolean(payload[key])) {
          if (Array.isArray(payload[key]))
            payload[key].forEach((item) => formData.append(key, item));
          else formData.append(key, payload[key]);
        } else formData.append(key, "");
      });

      mutate.mutate(formData);
    } else {
      setErrors({ detail: "Your Facebook account has no email." });
      setNotify(true);
      setTimeout(() => {
        navigate("/signup");
      }, 2000);
    }
  };

  useEffect(() => {
    const sub = async () => {
      if (mutate.isSuccess) {
        const url = search ? search.split("=")[1] : "/";
        if (mutate.data.user) {
          navigate(`/marketing-info/${urlGenerator(mutate.data.user)}`, {
            state: mutate.data,
          });
        } else {
          await LOGIN(mutate.data);
          await load_user();
          navigate(url);
        }
      }
    };
    sub();
  }, [mutate.isSuccess]);

  useEffect(() => {
    if (mutate.isError) {
      setErrors(mutate.error);
      if (mutate.error.detail) setNotify(true);
    }
  }, [mutate.isError]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={continueWithGoogle}
        >
          <Icon icon={googleFill} color="#DF3E30" height={24} />
        </Button>

        <FacebookLogin
          appId="788342492641509"
          autoLoad={false}
          fields="first_name,last_name,email,picture"
          callback={continueWithFacebook}
          render={(renderProps) => (
            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              onClick={renderProps.onClick}
              disabled
            >
              <Icon icon={facebookFill} height={24} />
            </Button>
          )}
        />
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("OR")}
        </Typography>
      </Divider>

      <Alert
        open={notify}
        setOpen={() => setNotify(!notify)}
        message={errors.detail}
        severity="error"
      />
    </>
  );
}

export default connect(null, { load_user, LOGIN })(AuthSocial);
