import { useMutation, useQuery } from "react-query";
import {
  addCompany,
  get_my_company,
  editCompany,
  get_single_company,
} from "../apis/Company";

export const useAddCompany = () => {
  return useMutation((payload) => addCompany(payload));
};
export const useEditCompany = (id) => {
  return useMutation((payload) => editCompany(id, payload));
};

export const useGetMyCompany = () => {
  return useQuery("my_company", () => get_my_company());
};
export const useGetSingleMyCompany = (payload) => {
  return useQuery(
    ["get_single_company", payload],
    () => get_single_company(payload),
    { refetchOnWindowFocus: false }
  );
};
