import { merge } from "lodash";
import Button from "./Button";
import Card from "./Card";
import IconButton from "./IconButton";
import Input from "./Input";
import Lists from "./Lists";
import Paper from "./Paper";
import Stepper from "./stepper";
import Typography from "./Typography";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return merge(
    Card(theme),
    Lists(theme),
    Paper(theme),
    Input(theme),
    Button(theme),
    Typography(theme),
    IconButton(theme),
    Stepper(theme)
  );
}
