import { Box } from "@mui/material";
import React from "react";
import LongCardConfig from "./LongCardConfig";

const getProductCard = (category) => {
  return LongCardConfig[category];
};
export default function ProductLongCardList({
  products,
  isLoading,
  isSuccess,
  filter_category,
  ...other
}) {
  return (
    <React.Fragment>
      {products.results &&
        products.results.map((product, index) => (
          <div key={index} style={{ width: "100%" }}>
            <Box
              component={getProductCard(
                filter_category
                  ? filter_category
                  : products.filter_category
                  ? products.filter_category
                  : product.filter_category
              )}
              data={product}
              filter_category={
                filter_category
                  ? filter_category
                  : products.filter_category
                  ? products.filter_category
                  : product.filter_category
              }
            />
          </div>
        ))}
    </React.Fragment>
  );
}
