import { api } from "../../apis/config";
import { SET_LOCATION, GET_LOCATION } from "./types";

export const set_location = (value) => async (dispatch) => {
  await api
    .post(`/api/profile/prefer-region/`, { region: value.id })
    .then(() => dispatch({ type: SET_LOCATION, payload: value }))
    .catch((err) => console.debug(err.response));
};

export const get_location = () => async (dispatch) => {
  await api
    .get(`/api/profile/prefer-region/`)
    .then((res) => {
      dispatch({ type: GET_LOCATION, payload: res.data });
    })
    .catch((err) => console.debug(err.response));
};
